import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Proposal} from '../../../../shared/models/proposal';
import {ProposalsService} from '../../../../shared/services/proposals.service';
import {OrganizationsService} from '../../../../shared/services/organizations.service';
import {CommonDictService} from '../../../../shared/services/common-dict.service';
import {TruckTypesService} from '../../../../shared/services/truck-types.service';
import {CargoTypesService} from '../../../../shared/services/cargo-types.service';
import {LoadTypesService} from '../../../../shared/services/load-types.service';
import {MainFormService} from '../../services/main-form.service';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {ConfirmDialogData} from '../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../shared/components/confirm-popup/confirm-popup.component';
import {DocumentActionModes} from '../../../../shared/enums/document-action-modes';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {ClientModes} from '../../../../shared/enums/client-modes';
import {ProposalStatus} from '../../../../shared/enums/proposal-status';
import {ProposalStatusExecution} from '../../../../shared/enums/proposal-status-execution';
import {ProposalConfirmationsRoutesDatesAdapterService} from '../../../../shared/adapters/proposal-confirmations-routes-dates-adapter.service';
import {ErrDialogComponent} from '../../../../shared/components/err-dialog/err-dialog.component';
import {ProposalCommonDictService} from '../../../../shared/services/proposal-common-dict.service';
import {User} from '../../../../shared/models/user';
import {LocalStorageService} from 'ngx-webstorage';
import {SignatureExampleDialogComponent} from '../../../../shared/components/signature-example-dialog/signature-example-dialog.component'
import { Overlay } from '@angular/cdk/overlay';
import {BidsService} from '../../../../shared/services/bids.service';

@Component({
  selector: 'app-proposal-main',
  templateUrl: './proposal-main.component.html',
  styleUrls: ['./proposal-main.component.scss']
})
export class ProposalMainComponent implements OnInit {
  entityId: string;
  entityItem: Proposal;
  documentActionModes = DocumentActionModes;
  documentTypes = DocumentTypes;
  clientModes = ClientModes;
  statusesExecution = ProposalStatusExecution;
  statusExecution: string;
  pending = false;
  user: User;
  senderId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private proposalsService: ProposalsService,
              private organizationsService: OrganizationsService,
              private commonDictService: CommonDictService,
              private proposalCommonDictService: ProposalCommonDictService,
              private truckTypesService: TruckTypesService,
              private cargoTypesService: CargoTypesService,
              private loadTypesService: LoadTypesService,
              private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<ProposalMainComponent>,
              private proposalConfirmationsRoutesDatesAdapterService: ProposalConfirmationsRoutesDatesAdapterService,
              @Inject(MAT_DIALOG_DATA) public data,
              protected storage: LocalStorageService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private overlay: Overlay,
              protected bidsService: BidsService) {
    dateAdapter.setLocale('ru-RU');
  }

  ngOnInit() {
    this.mainFormService.clientMode = this.data.clientMode;
    this.mainFormService.documentActionMode = this.data.documentActionMode;
    this.mainFormService.documentType = DocumentTypes.proposal;
    this.mainFormService.proposalId = this.data.entityId;

    this.entityId = this.data.entityId;

    this.bidsService.clientMode = this.mainFormService.clientMode;
    this.bidsService.documentType = this.mainFormService.documentType;

    this.user = this.storage.retrieve('currentUser');

    this.pending = true;

    this.initForm();

    const proposalObservable = this.entityId ? this.proposalsService.getOneClientMode(this.entityId, this.mainFormService.clientMode) : this.proposalsService.add();

    forkJoin(
      proposalObservable,
      this.organizationsService.getShortList(this.mainFormService.clientMode),
      this.proposalCommonDictService.getDocumentTypesList(),
      this.proposalCommonDictService.getAuctionStepsList(),
      this.proposalCommonDictService.getStatusList(),
      this.truckTypesService.getListCarrier(),
      this.cargoTypesService.getList(),
      this.loadTypesService.getList(),
      this.proposalCommonDictService.getTaxVatList(),
      this.proposalCommonDictService.getPaymentDeferTypeList(),
      this.commonDictService.getSettingsList(),
      this.proposalsService.getOperatorDriversList(this.entityId, DocumentTypes.proposal),
      this.proposalsService.getOperatorTrucksList(this.entityId, DocumentTypes.proposal),
      this.proposalsService.getCarrierDriversList(),
      this.proposalsService.getCarrierTrucksList(),
      this.bidsService.getList(this.entityId),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([entityData, organizationsData, documentTypesData, auctionStepsData, statusData,
                   truckTypesData, cargoTypesData, loadTypesData,
                   taxVatData, paymentDeferTypeData, settingsData, operatorDriversData,
                            operatorTrucksData, carrierDriversData, carrierTrucksData, bidsData]) => {
      this.mainFormService.setEntityItem(entityData.list[0]);
      this.entityItem = entityData.list[0];

      this.mainFormService.status = entityData.list[0].status as ProposalStatus;
      this.mainFormService.statusExecution = entityData.list[0].statusExecution as ProposalStatusExecution;

      this.mainFormService.winBidSum = this.entityItem.winBidSum;
      this.mainFormService.equipmentSum = this.entityItem.equipmentSum;
      this.mainFormService.deferSum = this.entityItem.deferSum;
      this.mainFormService.totalSumInsuranceAdd = this.entityItem.totalSumInsuranceAdd;

      if (this.entityItem.cargoTypeOther) {
        this.entityItem.cargoTypeId = '0';
      }

      if (this.mainFormService.documentActionMode === DocumentActionModes.add) {
        this.entityItem.status = statusData.list[0].id;
        const deferTypeData = paymentDeferTypeData.list.find(item => item.id === 'DEFER_100_3');
        if (deferTypeData) {
          this.entityItem.paymentDeferType = deferTypeData.id;
        } else {
          this.entityItem.paymentDeferType = paymentDeferTypeData.list[0].id;
        }
      }

      this.mainFormService.organizationsList = organizationsData.list;
      this.mainFormService.documentTypesList = documentTypesData.list;
      this.mainFormService.auctionStepsList = auctionStepsData.list;

      switch (this.mainFormService.documentActionMode) {
        case DocumentActionModes.add:
          this.mainFormService.statusList = statusData.list.filter(item => [ProposalStatus.draft, ProposalStatus.bidding].includes(item.id));
          break;

        case DocumentActionModes.edit:
          this.mainFormService.statusList = statusData.list.filter(item => [ProposalStatus.draft, ProposalStatus.bidding].includes(item.id));
          break;

        default:
          this.mainFormService.statusList = statusData.list;
          break;
      }

      this.mainFormService.truckTypesList = truckTypesData.list;
      this.mainFormService.cargoTypesList = cargoTypesData.list;
      this.mainFormService.loadTypesList = loadTypesData.list;
      this.mainFormService.taxVatList = taxVatData.list;
      this.mainFormService.paymentDeferTypeList = paymentDeferTypeData.list;
      this.mainFormService.settings = settingsData.list[0];
      this.mainFormService.operatorDriversList = operatorDriversData.list;
      this.mainFormService.operatorTrucksList = operatorTrucksData.list;
      this.mainFormService.carrierDriversList = carrierDriversData.list;
      this.mainFormService.carrierTrucksList = carrierTrucksData.list;

      this.mainFormService.tabGeneralForm.patchValue(this.entityItem);
      this.mainFormService.tabParametersForm.patchValue(this.entityItem);

      if (this.mainFormService.tabCalcForm) {
        this.mainFormService.tabCalcForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabCheckForm) {
        this.mainFormService.tabCheckForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabInsuranceForm) {
        this.mainFormService.tabInsuranceForm.patchValue(this.entityItem);
      }

      this.mainFormService.tabRequiredDocumentsForm.patchValue(this.entityItem);

      if (this.mainFormService.tabConfirmationForm.controls.headerForm) {
        this.mainFormService.tabConfirmationForm.controls.headerForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabDocumentsConfirmationForm) {
        this.mainFormService.tabDocumentsConfirmationForm.patchValue(this.entityItem);
      }

      if (bidsData && bidsData.list) {
        let winBids = bidsData.list.filter(el => el.isWin);
        if (winBids.length > 0) {
          this.senderId = winBids[0].organizationId;
        }
      }
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg(error);
      });
    });
  }

  initForm() {
    this.mainFormService.mainForm = this.formBuilder.group({
      tabGeneralForm: this.formBuilder.group({}),
      tabParametersForm: this.formBuilder.group({}),
      tabCalcForm: this.formBuilder.group({}),
      tabBidsForm: this.formBuilder.group({}),
      tabConfirmationForm: this.formBuilder.group({})
    });
  }

  async closeForm() {
    this.dialogRef.close();
    /*
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Закрыть окно предложения ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();

            this.dialogRef.close();
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
     */
  }

  save() {
    if ([DocumentActionModes.add, DocumentActionModes.edit].includes(this.mainFormService.documentActionMode)) {
      if (!this.mainFormService.tabGeneralForm.valid ||
        !this.mainFormService.tabParametersForm.valid ||
        !this.mainFormService.tabCalcForm.valid) {
        Object.keys(this.mainFormService.tabGeneralForm.controls).forEach(field => {
          const control = this.mainFormService.tabGeneralForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        Object.keys(this.mainFormService.tabParametersForm.controls).forEach(field => {
          const control = this.mainFormService.tabParametersForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        Object.keys(this.mainFormService.tabCalcForm.controls).forEach(field => {
          const control = this.mainFormService.tabCalcForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        this.snackService.showWarningMsg('Обнаружены ошибки при заполнении данных. Проверьте правильность заполнения данного предложения на всех вкладках');

        return;
      }

      const errMsg = [];

      if (!this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType4.value &&
        !this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType5.value) {
        errMsg.push('В требованиях к документам должен быть отмечен хотя бы один вид доверенности');
      }

      if (errMsg.length) {
        const dialogRef = this.dialog.open(ErrDialogComponent, {
          data: errMsg
        });

        return;
      }

      this.pending = true;

      this.entityItem.docDate = this.mainFormService.tabGeneralForm.controls.docDate.value;
      this.entityItem.docNumber = this.mainFormService.tabGeneralForm.controls.docNumber.value;
      this.entityItem.type = this.mainFormService.tabGeneralForm.controls.type.value;
      this.entityItem.status = this.mainFormService.tabGeneralForm.controls.status.value;
      this.entityItem.price = this.mainFormService.tabCalcForm.controls.price.value;
      this.entityItem.estimatedPrice = this.mainFormService.tabCalcForm.controls.estimatedPrice.value;
      this.entityItem.totalPrice = this.mainFormService.tabCalcForm.controls.totalPrice.value;
      this.entityItem.insurancePrice = this.mainFormService.tabCalcForm.controls.insurancePrice.value;
      this.entityItem.siteComissionRate = this.mainFormService.tabCalcForm.controls.siteComissionRate.value;
      this.entityItem.deferPrice = this.mainFormService.tabCalcForm.controls.deferPrice.value;
      this.entityItem.step = this.mainFormService.tabGeneralForm.controls.step.value;
      this.entityItem.docDateStart = this.mainFormService.tabGeneralForm.controls.docDateStart.value;
      this.entityItem.docDateEnd = this.mainFormService.tabGeneralForm.controls.docDateEnd.value;
      this.entityItem.comment = this.mainFormService.tabGeneralForm.controls.comment.value;
      this.entityItem.truckTypeIds = this.mainFormService.tabParametersForm.controls.truckTypeIds.value;
      this.entityItem.truckTypeOther = '';
      this.entityItem.cargoNote = this.mainFormService.tabParametersForm.controls.cargoNote.value;
      this.entityItem.weight = this.mainFormService.tabParametersForm.controls.weight.value;
      this.entityItem.addCargo = this.mainFormService.tabParametersForm.controls.addCargo.value;
      this.entityItem.sanitaryPassport = this.mainFormService.tabParametersForm.controls.sanitaryPassport.value;
      this.entityItem.taxVat = this.mainFormService.tabCalcForm.controls.taxVat.value;
      this.entityItem.capacity = this.mainFormService.tabParametersForm.controls.capacity.value;
      this.entityItem.paymentDeferType = this.mainFormService.tabCalcForm.controls.paymentDeferType.value;
      this.entityItem.paymentDeferDays = this.mainFormService.tabCalcForm.controls.paymentDeferDays.value;
      this.entityItem.loadTypeIds = this.mainFormService.tabParametersForm.controls.loadTypeIds.value;
      this.entityItem.isRequiredDocumentType1 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType1.value;
      this.entityItem.isRequiredDocumentType2 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType2.value;
      this.entityItem.requiredDocumentTypeOther = this.mainFormService.tabRequiredDocumentsForm.controls.requiredDocumentTypeOther.value;
      this.entityItem.isProvidedDocumentType1 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType1.value;
      this.entityItem.isProvidedDocumentType3 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType3.value;
      this.entityItem.isProvidedDocumentType4 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType4.value;
      this.entityItem.isProvidedDocumentType5 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType5.value;
      this.entityItem.providedDocumentTypeOther = this.mainFormService.tabRequiredDocumentsForm.controls.providedDocumentTypeOther.value;

      this.proposalsService.update(this.entityItem).subscribe(success => {
          this.pending = false;

          let $navigateUrl = '/cab/carrier/proposal/drafts';
          if (this.mainFormService.tabGeneralForm.controls.type.value == 'AUCTION'){
            if (this.mainFormService.tabGeneralForm.controls.status.value == ProposalStatus.bidding){
              $navigateUrl =  new Date(this.mainFormService.tabGeneralForm.controls.docDateStart.value) > new Date() ? '/cab/carrier/proposal/biddings_deffered' : '/cab/carrier/proposal/biddings';
              $navigateUrl = '/cab/carrier/proposal/biddings';
            }
          }

          this.router.navigateByUrl($navigateUrl);

          this.dialogRef.close({ action: true, navigateUrl: $navigateUrl });

          const toastMsg = this.mainFormService.documentActionMode === DocumentActionModes.add ? 'Объект добавлен' : 'Объект изменен';

          this.toastService.success(toastMsg, '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    } else if ([DocumentActionModes.view_completed_bids].includes(this.mainFormService.documentActionMode)) {
      if (this.mainFormService.clientMode === ClientModes.operator) {
        if (!this.mainFormService.tabInsuranceForm.valid) {
          Object.keys(this.mainFormService.tabInsuranceForm.controls).forEach(field => {
            const control = this.mainFormService.tabGeneralForm.get(field);
            control.markAsTouched({ onlySelf: true });
          });

          this.snackService.showWarningMsg('Обнаружены ошибки при заполнении данных. Проверьте правильность заполнения данной заявки на всех вкладках');

          return;
        }

        this.pending = true;

        this.entityItem.insuranceCreationDate = this.mainFormService.tabInsuranceForm.controls.insuranceCreationDate.value;
        this.entityItem.insuranceStartDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartDate.value;
        this.entityItem.insuranceEndDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndDate.value;
        this.entityItem.insurancePayDate = this.mainFormService.tabInsuranceForm.controls.insurancePayDate.value;
        this.entityItem.insuranceStartTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartTransportationDate.value;
        this.entityItem.insuranceEndTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndTransportationDate.value;
        this.entityItem.insuranceCargoName = this.mainFormService.tabInsuranceForm.controls.insuranceCargoName.value;
        this.entityItem.insuranceCargoDoc = this.mainFormService.tabInsuranceForm.controls.insuranceCargoDoc.value;
        this.entityItem.insuranceCargoTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceCargoTypeId.value;
        this.entityItem.insuranceCurrency = this.mainFormService.tabInsuranceForm.controls.insuranceCurrency.value;
        this.entityItem.insuranceLimitSum = this.mainFormService.tabInsuranceForm.controls.insuranceLimitSum.value;
        this.entityItem.insuranceBeneficiary = this.mainFormService.tabInsuranceForm.controls.insuranceBeneficiary.value;
        this.entityItem.insuranceTransportTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceTransportTypeId.value;
        this.entityItem.insurancePackageTypeId = this.mainFormService.tabInsuranceForm.controls.insurancePackageTypeId.value;
        this.entityItem.insuranceCarrierName = this.mainFormService.tabInsuranceForm.controls.insuranceCarrierName.value;
        this.entityItem.insuranceSeats = this.mainFormService.tabInsuranceForm.controls.insuranceSeats.value;
        this.entityItem.insuranceWeight = this.mainFormService.tabInsuranceForm.controls.insuranceWeight.value;
        this.entityItem.insurancePointFrom = this.mainFormService.tabInsuranceForm.controls.insurancePointFrom.value;
        this.entityItem.insurancePointTo = this.mainFormService.tabInsuranceForm.controls.insurancePointTo.value;
        this.entityItem.insuranceBeginningResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceBeginningResponsibilityId.value;
        this.entityItem.insuranceEndResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceEndResponsibilityId.value;
        this.entityItem.insuranceNumber = this.mainFormService.tabInsuranceForm.controls.insuranceNumber.value;
        this.entityItem.isInOperationProgress = this.mainFormService.tabGeneralForm.controls.isInOperationProgress.value;

        this.proposalsService.updateInsurance(this.entityItem).subscribe(success => {
            this.pending = false;

            this.dialogRef.close({ action: true });

            this.toastService.success('Объект изменен', '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      }
    } else if ([DocumentActionModes.view_confirmed].includes(this.mainFormService.documentActionMode)) {
      if (this.mainFormService.clientMode === ClientModes.carrier) {
        const errMsg = [];

        if (!this.mainFormService.tabDocumentsConfirmationForm.controls.driverId.value &&
          !this.mainFormService.tabDocumentsConfirmationForm.controls.driver1Id.value) {
          errMsg.push('Должен быть указан хотя бы один водитель');
          this.mainFormService.tabDocumentsConfirmationForm.controls.driverId.markAsTouched();
        }

        if (!this.mainFormService.tabDocumentsConfirmationForm.controls.truckId.value) {
          errMsg.push('Не указан автотранспорт');
          this.mainFormService.tabDocumentsConfirmationForm.controls.truckId.markAsTouched();
        }

        if (errMsg.length) {
          const dialogRef = this.dialog.open(ErrDialogComponent, {
            data: errMsg
          });

          return;
        }

        this.entityItem.driverId = this.mainFormService.tabDocumentsConfirmationForm.controls.driverId.value;
        this.entityItem.driver1Id = this.mainFormService.tabDocumentsConfirmationForm.controls.driver1Id.value;
        this.entityItem.truckId = this.mainFormService.tabDocumentsConfirmationForm.controls.truckId.value;

        this.pending = true;

        this.proposalsService.updateCarrierConfirmedData(this.entityItem).subscribe(success => {
            this.pending = false;

            this.dialogRef.close({ action: true });

            this.toastService.success('Объект изменен', '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      }
    }
  }

  openOwnerConfirmDialog() {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Согласовать данные ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();
            this.ownerConfirm();
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  ownerConfirm() {
    const tabConfirmationForm = this.mainFormService.tabConfirmationForm;

    const cargoTypeIdValue = tabConfirmationForm.controls.headerForm.get('cargoTypeId').value;

    let body = {
      common: tabConfirmationForm.controls.commonForm.get('items').value,
      routesContacts: tabConfirmationForm.controls.routesContactsForm.get('items').value,
      equipment: tabConfirmationForm.controls.equipmentForm.get('items').value,
      routesPoints: tabConfirmationForm.controls.routesPointsForm.get('items').value,
      routesDates: tabConfirmationForm.controls.routesDatesForm.get('items').value.map(item => this.proposalConfirmationsRoutesDatesAdapterService.adaptOut(item)),
      counterparties: tabConfirmationForm.controls.counterpartiesForm.get('items').value,
      headerData: {
        insurancePackageTypeId: tabConfirmationForm.controls.headerForm.get('insurancePackageTypeId').value,
        ownerSeats: tabConfirmationForm.controls.headerForm.get('ownerSeats').value,
        ownerConfirmationCargoDoc: tabConfirmationForm.controls.headerForm.get('ownerConfirmationCargoDoc').value,
        insuranceLimitSum: tabConfirmationForm.controls.headerForm.get('insuranceLimitSum').value,
        cargoTypeId: cargoTypeIdValue,
        cargoTypeOther: cargoTypeIdValue === '0' ? tabConfirmationForm.controls.headerForm.get('cargoTypeOther').value : '',
      },
    };

    if (tabConfirmationForm.controls.headerForm.get('isPaymentDefer').value) {
      body.headerData['paymentDeferType'] = "DEFER_100_4";
      body.headerData['paymentDeferDays'] = tabConfirmationForm.controls.headerForm.get('paymentDeferDays').value;
      body.headerData['deferPrice'] = this.mainFormService.deferSum;
      body.headerData['totalPrice'] = this.mainFormService.totalSum;
    } else {
      body.headerData['paymentDeferType'] = "DEFER_100_3";
      body.headerData['paymentDeferDays'] = 0;
      body.headerData['deferPrice'] = 0;
      body.headerData['totalPrice'] = this.mainFormService.totalSum;
    }

    const errMsg = [];
    let isOwnerRequiredDocuments = false;

    if (!body.headerData.ownerSeats) {
      errMsg.push('Количество мест');
    }
    if (!body.headerData.ownerConfirmationCargoDoc) {
      errMsg.push('Номер накладной');
    }
    if (!body.headerData.insuranceLimitSum) {
      errMsg.push('Страховая сумма');
    }
    if (!body.headerData.cargoTypeId) {
      errMsg.push('Тип груза');
    }
    if (body.headerData.cargoTypeId === '0' && !body.headerData.cargoTypeOther) {
      errMsg.push('Тип груза (другое)');
    }

    body.counterparties.map(item => {
      let who = item.type === 'sender' ? 'отправителя' : item.type === 'payer' ? 'плательщика' : 'получателя';
      if (!item.address) {
        errMsg.push('Адрес контрагента ' + who);
      }
      if (!item.inn && item.type !== 'recipient') {
        errMsg.push('ИНН контрагента ' + who);
      }
      if (!item.name) {
        errMsg.push('Наименование контрагента ' + who);
      }
    })

    body.common.map(item => {
      if (item.code === 'ownerWeight' && !item.ownerValue) {
        errMsg.push('Масса');
      }
      if (item.code === 'ownerCapacity' && !item.ownerValue) {
        errMsg.push('Объем');
      }
      if (item.code === 'ownerRequiredDocumentType1') {
        isOwnerRequiredDocuments = isOwnerRequiredDocuments || item.ownerValue;
      }
      if (item.code === 'ownerRequiredDocumentType2') {
        isOwnerRequiredDocuments = isOwnerRequiredDocuments || item.ownerValue;
      }
    });

    if (!isOwnerRequiredDocuments) {
      //errMsg.push('Хотя бы один документ из списка Необходимых');
    }

    body.routesContacts.map(item => {
      if (item.code === 'contactsIn' && !item.ownerContactsPhone1 && !item.ownerContactsPhone2) {
        errMsg.push('Хотя бы один телефон в контактах при погрузке');
      }
      if (item.code === 'contactsIn' && !item.ownerContactsPerson) {
        errMsg.push('Не заполнено Контактное лицо при погрузке');
      }
      if (item.code === 'contactsOut' && !item.ownerContactsPhone1 && !item.ownerContactsPhone2) {
        errMsg.push('Хотя бы один телефон в контактах при выгрузке');
      }
      if (item.code === 'contactsOut' && !item.ownerContactsPerson) {
        errMsg.push('Не заполнено Контактное лицо при выгрузке');
      }
    })

    body.routesPoints.map(item => {
      if (item.code === 'routeFrom' && !item.ownerRoute) {
        errMsg.push('Пункт отправления');
      }
      if (item.code === 'routeTo' && !item.ownerRoute) {
        errMsg.push('Пункт назначения');
      }
    })

    body.routesDates.map(item => {
      if (item.code === 'routeDateIn' && !item.ownerDateStart && !item.ownerDateEnd) {
        errMsg.push('Хотя бы одна дата погрузки');
      }
      if (item.code === 'routeDateOut' && !item.ownerDateStart && !item.ownerDateEnd) {
        errMsg.push('Хотя бы одна дата выгрузки');
      }
    })

    if (tabConfirmationForm.controls.headerForm.get('insuranceLimitSum').value < this.mainFormService.settings['minInsuranceSum']) {
      errMsg.push('Страховая сумма не может быть меньше ' + this.mainFormService.settings['minInsuranceSum'] + ' руб.');
    }

    if (!tabConfirmationForm.controls.headerForm.get('insurancePackageTypeId').value) {
      errMsg.push('Не указан Тип упаковки');
    }

    if (errMsg.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errMsg
      });
    } else {
      this.pending = true;

      this.proposalsService.ownerConfirm(this.entityId, body).subscribe(data => {
          this.pending = false;

          this.mainFormService.statusExecution = data.list[0].statusExecution as ProposalStatusExecution;

          this.toastService.success('Данные согласованы', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });

          this.router.navigateByUrl('/cab/owner/proposal/confirmed');
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    }
  }

  openCarrierConfirmDialog() {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Согласовать данные ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();
            this.carrierConfirm();
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  carrierConfirm() {
    this.proposalsService.carrierConfirm(this.entityId).subscribe(data => {
        this.pending = false;

        this.mainFormService.statusExecution = data.list[0].statusExecution as ProposalStatusExecution;

        this.toastService.success('Данные согласованы', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  confirmationTabVisible() {
    if (![DocumentActionModes.view_completed_bids, DocumentActionModes.view_completed, DocumentActionModes.view_confirmed].includes(this.mainFormService.documentActionMode)) {
      return false;
    }

    if (this.mainFormService.clientMode === ClientModes.owner) {
      //([statusesExecution.WAITING_CONFIRMATION_BY_OWNER, statusesExecution.CONFIRMED_BY_OWNER].includes(mainFormService.statusExecution))
    }

    if (this.mainFormService.clientMode === ClientModes.carrier) {
      if ([ProposalStatusExecution.WAITING_CONFIRMATION_BY_OWNER].includes(this.mainFormService.statusExecution)) {
        return false;
      }
    }

    return true;
  }

  isConfirmButtonOwnerAvailable() {
    if (![this.documentActionModes.view_completed_bids].includes(this.mainFormService.documentActionMode)) {
      return false;
    }

    if (![this.clientModes.owner].includes(this.mainFormService.clientMode)) {
      return false;
    }

    return true;
  }

  isConfirmButtonOwnerDisabled() {
    if (this.pending) {
      return true;
    }

    if (!this.entityItem) {
      return true;
    }

    if (this.entityItem.userId !== this.user.id) {
      if (this.entityItem.winBidUserId !== this.user.id) {
        //return true;
      }
    }

    if (this.mainFormService.statusExecution !== this.statusesExecution.WAITING_CONFIRMATION_BY_OWNER) {
      return true;
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.permissions.hasOwnProperty('all') && !this.entityItem.permissions.all) {
        return true;
      }
      if (this.entityItem.permissions.hasOwnProperty('confirmation') && !this.entityItem.permissions.confirmation) {
        return true;
      }
    }

    // if (this.mainFormService.entityItem.organizationId !== this.mainFormService.currentUser.organizationId) {
    //   return true;
    // }

    return false;
  }

  isConfirmButtonCarrierAvailable() {
    if (!this.entityItem) {
      return false;
    }

    if (+this.entityItem.userId !== +this.user.id) {
      //return false;
    }

    if (![this.documentActionModes.view_completed_bids].includes(this.mainFormService.documentActionMode)) {
      return false;
    }

    if (![this.clientModes.carrier].includes(this.mainFormService.clientMode)) {
      return false;
    }

    if (this.mainFormService.statusExecution !== this.statusesExecution.CONFIRMED_BY_OWNER) {
      return false;
    }

    return true;
  }

  isConfirmButtonCarrierDisabled() {
    if (this.pending) {
      return true;
    }

    if (this.mainFormService.statusExecution !== this.statusesExecution.CONFIRMED_BY_OWNER) {
      return true;
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.permissions.hasOwnProperty('all') && !this.entityItem.permissions.all) {
        return true;
      }
      if (this.entityItem.permissions.hasOwnProperty('confirmation') && !this.entityItem.permissions.confirmation) {
        return true;
      }
    }

    return false;
  }

  async openExample(orgId) {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
      const dialogRef = this.dialog.open(SignatureExampleDialogComponent, {
        data: { id: orgId },
        scrollStrategy,
        minWidth:"50vh",
      });
  }
}
