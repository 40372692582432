export const colsConfig = [
  {
    name: 'Наименование',
    show: true,
    class: 'name_',
  },
  {
    name: 'Предложение Перевозчика',
    show: true,
    class: 'carrierRoute',
  },
  {
    name: 'Радиус, км',
    show: true,
    class: 'radius',
  },
  {
    name: 'Потребность отправителя',
    show: true,
    class: 'ownerRoute',
  },
];
