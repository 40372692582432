import {Validators} from '@angular/forms';

export const metaData = [
  {
    entityField: 'name',
    formField: 'name',
    validators: [Validators.required]
  },
  {
    entityField: 'fullName',
    formField: 'fullName',
    validators: [Validators.required]
  },
  {
    entityField: 'jurAddress',
    formField: 'jurAddress',
    validators: [Validators.required]
  },
  {
    entityField: 'factAddress',
    formField: 'factAddress',
    validators: [Validators.required]
  },
  {
    entityField: 'phones',
    formField: 'phones',
    validators: [Validators.required]
  },
  {
    entityField: 'email',
    formField: 'email',
    validators: [Validators.required]
  },
  {
    entityField: 'inn',
    formField: 'inn',
    validators: [Validators.required]
  },
  {
    entityField: 'kpp',
    formField: 'kpp',
    validators: [Validators.required]
  },
  {
    entityField: 'ogrn',
    formField: 'ogrn',
    validators: [Validators.required]
  },
  {
    entityField: 'bankAccount',
    formField: 'bankAccount',
    validators: [Validators.required]
  },
  {
    entityField: 'corrAccount',
    formField: 'corrAccount',
    validators: [Validators.required]
  },
  {
    entityField: 'bankName',
    formField: 'bankName',
    validators: [Validators.required]
  },
  {
    entityField: 'bankBik',
    formField: 'bankBik',
    validators: [Validators.required]
  },
  {
    entityField: 'ownerShipType',
    formField: 'ownerShipType',
    validators: [Validators.required]
  },
];
