import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {GroupBy} from '../../modules/proposal-form/forms/tab-confirmation/grid-common/grid-common.component';
import {ProposalConfirmationEquipment} from '../models/proposal-confirmation-equipment';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsEquipmentAdapterService implements EntityAdapter<ProposalConfirmationEquipment | GroupBy> {
  adaptIn(item: any): ProposalConfirmationEquipment | GroupBy {
    if (item.initial) {
      return item;
    } else {
      return new ProposalConfirmationEquipment(
        item.id,
        item.name,
        Math.round(+item.carrierPrice * 100) / 100,
        Math.round(+item.carrierQuantity * 100) / 100,
        Math.round(+item.carrierTotal * 100) / 100,
        Math.round(+item.ownerPrice * 100) / 100,
        Math.round(+item.ownerQuantity * 100) / 100,
        Math.round(+item.ownerTotal * 100) / 100,
      );
    }
  }

  adaptOut(item: ProposalConfirmationEquipment): any {
    return {
      ...item
    };
  }
}
