import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {DocumentEquipment} from '../models/document-equipment';

@Injectable({
  providedIn: 'root'
})
export class DocumentEquipmentAdapterService implements EntityAdapter<DocumentEquipment> {
  adaptIn(item: any): DocumentEquipment {
    return new DocumentEquipment(
      item.id,
      item.name,
      item.masterEntityId,
      item.equipmentId,
      Math.round(+item.price * 100) / 100,
      Math.round(+item.quantity * 100) / 100,
      Math.round(+item.ownerConfirmationQuantity * 100) / 100,
      Math.round(+item.total * 100) / 100,
      Math.round(+item.totalOwnerConfirmation * 100) / 100,
      item.description,
    );
  }

  adaptOut(item: DocumentEquipment): any {
    return {
      ...item
    };
  }
}
