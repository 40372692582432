import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {EntityData} from '../interfaces/entity-data';
import {ConfigService} from './config.service';
import {map} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {Model} from '../models/model';
import {EntityCrudService} from '../classes/entity-crud-service';
import {UsersAdapterService} from '../adapters/users-adapter.service';
import {FilterAdminUsersModel} from '../interfaces/filter-admin-users.model';
import {UsersFilterAdapterService} from '../adapters/users-filter-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends EntityCrudService<User> {
  baseApiPath = 'users';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: UsersAdapterService,
              protected filterAdapter: UsersFilterAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getStatusList(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/status/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  getRolesList(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/roles/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  getListAdmin(filter: FilterAdminUsersModel, page = 1, limit = 100): Observable<EntityData<User>> {
    const body = {
      filter: this.filterAdapter.adaptOut(filter),
      page,
      limit,
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<User>))
      );
  }
}
