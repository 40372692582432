import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {LoadType} from '../models/load-type';

@Injectable({
  providedIn: 'root'
})
export class LoadTypesAdapterService implements EntityAdapter<LoadType> {
  adaptIn(item: any): LoadType {
    return new LoadType(
      item.id,
      item.name,
      Math.round(+item.price),
    );
  }

  adaptOut(item: LoadType): any {
    return {
      ...item
    };
  }
}
