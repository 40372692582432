import {Model} from './model';

export class ProposalConfirmationRoutesDates extends Model {
  constructor(
    public id?: string,
    public code?: string,
    public name?: string,
    public carrierDateStart?: Date,
    public carrierDateEnd?: Date,
    public ownerDateStart?: Date,
    public ownerDateEnd?: Date,
  ) {
    super('', name);
  }
}
