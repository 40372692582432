import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../classes/table-component';
import {DocumentOrganization} from '../../../models/document-organization';
import {RestrictedOrganizationsDatasource} from './restricted-organizations.datasource';
import {ColConfig} from '../../../interfaces/col-config';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../services/config.service';
import {MainFormService as OrderMainFormService} from '../../../../modules/order-form/services/main-form.service';
import {ErrorService} from '../../../services/error.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {ConfirmDialogData} from '../../../interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../confirm-popup/confirm-popup.component';
import {MainFormService as ProposalMainFormService} from '../../../../modules/proposal-form/services/main-form.service';
import {DocumentTypes} from '../../../enums/document-types';
import {RestrictedOrganizationsService} from '../../../services/restricted-organizations.service';
import {DocumentOrganizationRestrictions} from '../../../enums/document-organization-restrictions';
import {RestrictedOrganizationsAddFormComponent} from '../restricted-organizations-add-form/restricted-organizations-add-form.component';
import {RestrictedOrganizationsEditFormComponent} from '../restricted-organizations-edit-form/restricted-organizations-edit-form.component';
import {colsConfig} from './config/cols.config';
import {ClientModes} from '../../../enums/client-modes';
import {interval} from 'rxjs';

@Component({
  selector: 'app-restricted-organizations',
  templateUrl: './restricted-organizations.component.html',
  styleUrls: ['./restricted-organizations.component.scss']
})
export class RestrictedOrganizationsComponent extends TableComponent<DocumentOrganization, RestrictedOrganizationsDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name', 'organization', 'options'];
  storageKey = 'app-restricted-organizations' + '-' + this.orderMainFormService.clientMode + '-' + this.orderMainFormService.documentType;
  dataSource: RestrictedOrganizationsDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;
  clientMode: ClientModes;

  @Input() title: string;
  @Input() documentType: DocumentTypes;
  @Input() restrictionType: DocumentOrganizationRestrictions;

  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: RestrictedOrganizationsService,
              private orderMainFormService: OrderMainFormService,
              private proposalMainFormService: ProposalMainFormService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    if (this.documentType === DocumentTypes.proposal) {
      this.proposalMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );

      this.entityService.documentType = this.proposalMainFormService.documentType;
      this.clientMode = this.proposalMainFormService.clientMode;
    } else {
      this.orderMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );

      this.entityService.documentType = this.orderMainFormService.documentType;
      this.clientMode = this.orderMainFormService.clientMode;      
    }

    interval(30000)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => this.reload());
  }

  get readOnly() {
    if (this.documentType === DocumentTypes.order) {
      return this.orderMainFormService.readOnly;
    } else {
      return this.proposalMainFormService.readOnly;
    }
  }

  ngOnDestroy() {}

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

  initDataSource() {
    this.dataSource = new RestrictedOrganizationsDatasource(this.entityService, this.errorService, this.snackService, this.restrictionType);
  }

  openDeleteDialog(entityItem: DocumentOrganization) {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Удалить выбранный элемент ?',
      btnsArray: [
        {
          message: 'Удалить',
          action: () => {
            dialog.close();
            this.delete(entityItem.id);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  delete(id: string): void {
    this.entityService.delete(id, this.restrictionType, this.clientMode).subscribe(success => {
        this.update();
        this.toastService.success('Объект удален', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(RestrictedOrganizationsAddFormComponent, {
      disableClose: true,
      width: '500px',
      data: {
        masterEntityId: this.masterEntityId,
        restrictionType: this.restrictionType,
        clientMode: this.clientMode,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  openEditDialog(entityItem: DocumentOrganization) {
    const dialogRef = this.dialog.open(RestrictedOrganizationsEditFormComponent, {
      disableClose: true,
      width: '500px',
      data: {
        id: entityItem.id,
        restrictionType: this.restrictionType,
        clientMode: this.clientMode,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  reload() {
    this.update();
  }
}
