import {finalize} from 'rxjs/operators';
import {ProposalConfirmationCommon} from '../../../../../shared/models/proposal-confirmation-common';
import {TableDataSource} from '../../../../../shared/classes/table-data-source';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../shared/interfaces/entity-data';
import {GroupBy} from './grid-common.component';
import {FormArray, FormGroup} from '@angular/forms';

export class GridCommonDatasource extends TableDataSource<ProposalConfirmationCommon | GroupBy> {
  constructor(protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(entityId: string, componentForm: FormGroup) {
    this.setLoading(true);
    this.entityService.getListCommon(entityId)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<ProposalConfirmationCommon | GroupBy>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);

          data.list.forEach((item, index) => {
            if (item instanceof ProposalConfirmationCommon) {
              (componentForm.get('items') as FormArray).controls[index].get('code').setValue(item.code);
              (componentForm.get('items') as FormArray).controls[index].get('carrierValue').setValue(item.carrierValue);
              (componentForm.get('items') as FormArray).controls[index].get('ownerValue').setValue(item.ownerValue);
            }
          });
        }, err => this.snackService.showErrorMsg(err));
  }
}
