import {Injectable} from '@angular/core';
import {FilterAdminUsersModel} from '../interfaces/filter-admin-users.model';

@Injectable({
  providedIn: 'root'
})
export class UsersFilterAdapterService {

  adaptOut(item: FilterAdminUsersModel): any {
    if (!item) {
      return {};
    }

    return {
      ...item,
    };
  }
}
