import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService as ProposalMainFormService, MainFormService} from '../../services/main-form.service';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';
import {ProposalStatusExecution} from '../../../../shared/enums/proposal-status-execution';
import {ProposalStatus} from '../../../../shared/enums/proposal-status';
import {Proposal} from '../../../../shared/models/proposal';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Component({
  selector: 'app-proposal-form-tab-parameters',
  templateUrl: './tab-parameters.component.html',
  styleUrls: ['./tab-parameters.component.scss']
})
export class TabParametersComponent extends EntityEditForm implements OnInit, OnDestroy {
  documentTypes = DocumentTypes;
  proposal: Observable<Proposal>;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              private validatorsService: CommonValidatorsService,
              private proposalMainFormService: ProposalMainFormService,) {
    super();
  }

  ngOnInit() {
    this.proposal = this.proposalMainFormService.getEntityItemObservable().pipe(share());

    this.initForm();
  }

  ngOnDestroy() {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabParametersForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabParametersForm = form;
  }

  get truckTypeIds() {
    return this.componentForm.get('truckTypeIds');
  }

  get weight() {
    return this.componentForm.get('weight');
  }

  get capacity() {
    return this.componentForm.get('capacity');
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      truckTypeIds: ['', Validators.required],
      weight: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
      capacity: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
      cargoNote: [''],
      addCargo: [''],
      sanitaryPassport: [''],
      loadTypeIds: [''],
    });
  }

  showProposalEquipmentConfirmed() {
    if (this.mainFormService.clientMode !== this.mainFormService.clientModes.carrier) {
      return false;
    }

    if (![ProposalStatus.bidding_completed, ProposalStatus.confirmed, ProposalStatus.completed].includes(this.proposalMainFormService.status)) {
      return false;
    }

    if (![ProposalStatusExecution.CONFIRMED_BY_OWNER, ProposalStatusExecution.SHIPPING_PREPARE].includes(this.proposalMainFormService.statusExecution)) {
      return false;
    }

    return true;
  }
}
