import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalConfirmationCounterparties} from '../models/proposal-confirmation-counterparties';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsCounterpartiesAdapterService implements EntityAdapter<ProposalConfirmationCounterparties> {
  adaptIn(item: any): ProposalConfirmationCounterparties {
    if (item.initial) {
      return item
    } else {
      return new ProposalConfirmationCounterparties(
        item.type,
        item.description,
        item.name,
        item.inn,
        item.address,
      );
    }
  }

  adaptOut(item: ProposalConfirmationCounterparties): any {
    return {
      ...item
    };
  }
}
