import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EntityEditForm } from '../../../../shared/classes/entity-edit-form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainFormService } from '../../services/main-form.service';
import { OrderFile } from '../../../../shared/models/order-file';
import { ConfigService } from '../../../../shared/services/config.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ClientModes } from '../../../../shared/enums/client-modes';
import { OrderDocumentsService } from '../../../../shared/services/order-documents.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { ConfirmationFileTypes } from '../../../../shared/enums/confirmation-file-types';
import { v4 as uuidv4 } from 'uuid';
import { OrderFilesService } from '../../../../shared/services/order-files.service';


@Component({
  selector: 'app-order-documents-other',
  templateUrl: './tab-documents-other.component.html',
  styleUrls: ['./tab-documents-other.component.scss']
})
export class TabDocumentsOtherComponent extends EntityEditForm implements OnInit {
  orderFilesList: OrderFile[] = [];
  fileUploadApi = '';
  pending = false;
  //componentForm: FormGroup;
  orderId: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private storage: LocalStorageService,
    private mainFormService: MainFormService,
    protected configService: ConfigService,
    protected entityService: OrderDocumentsService,
    private toastService: ToastrService,
    private snackService: SnackBarService,
    protected orderFilesService: OrderFilesService) {
    super();

    this.fileUploadApi = `${this.configService.apiUrl}/order/upload_file`;
    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data) {
          this.orderId = data.id;
          if (!this.orderId) {
            return;
          }
          this.orderFilesService.getListClientMode(this.orderId, mainFormService.clientMode).subscribe(success => {
            this.orderFilesList = success.list.map(i => new OrderFile(null, i.uuid, i.fileName));
          }, errors => {
            errors.map(error => {
              this.snackService.showErrorMsg(error);
            })
          });
        }
      }
      );
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() { }

  get componentForm(): FormGroup {
    return this.mainFormService.tabOrderFilesForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabOrderFilesForm = form;
  }

  initForm() {
    // this.componentForm = this.formBuilder.group({
    //   //files: this.formBuilder.array([])      
    // });
  }

  getFormDataParams(uuid, clientMode) {
    return {
      masterEntityId: this.orderId,
      uuid,
      clientMode,
      token: this.storage.retrieve('token'),
      confirmationFileType: ConfirmationFileTypes.otherFiles,
    };
  }

  onFileComplete(event, uuid) {
    const index = this.orderFilesList.findIndex(el => el.uuid === uuid);
    if (index !== -1) {
      this.orderFilesList[index].fileName = event.data[0].fileName;
      this.orderFilesList[index].uuid = event.data[0].uuid;
    }
  }

  downloadDocumentFile(uuid, fileName, clientMode) {
    const link = document.createElement('a');
    const params = {
      token: this.storage.retrieve('token'),
      masterEntityId: this.orderId,
      uuid,
      fileName,
      clientMode,
      confirmationFileType: ConfirmationFileTypes.otherFiles,
    }

    link.download = 'test';
    link.target = 'blank';
    link.href = `${this.configService.apiUrl}/order/download_file/?${this.encodeQueryData(params)}`;
    link.click();
  }

  encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }

  deleteDocumentFile(uuid, clientMode: ClientModes) {
    this.pending = true;

    this.entityService.deleteFile(this.orderId, uuid, clientMode, ConfirmationFileTypes.otherFiles)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
        const index = this.orderFilesList.findIndex(el => el.uuid === uuid);
        if (index !== -1) {
          this.orderFilesList.splice(index, 1);
        }
        this.toastService.success('Документ удален', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
      );
  }

  addFileUploadControl() {
    this.orderFilesList.push({
      uuid: uuidv4(),
    })
  }
}
