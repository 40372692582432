import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ConfirmDialogData} from '../../interfaces/confirm-dialog.model';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

}
