import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {Driver} from '../models/driver';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {DriversAdapterService} from '../adapters/drivers-adapter.service';
import {ModelAdapterService} from '../adapters/model-adapter.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {Model} from '../models/model';
import {Settings} from '../models/settings';

@Injectable({
  providedIn: 'root'
})
export class CommonDictService {
  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected modelAdapter: ModelAdapterService,
              protected errorService: ErrorService) {}

  getSettingsList(): Observable<EntityData<Settings>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/common/settings/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data
        } as EntityData<Settings>))
      );
  }
}
