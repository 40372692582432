export enum ProposalStatus {
  new = 'NEW',
  draft = 'DRAFT',
  template = 'TEMPLATE',
  bidding_deffered = 'BIDDING_DEFFERED',
  bidding = 'BIDDING',
  bidding_completed = 'BIDDING_COMPLETED',
  confirmed = 'CONFIRMED',
  completed = 'COMPLETED',
  failed = 'FAILED'

}
