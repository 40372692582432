import { Injectable } from '@angular/core';
import { EntityCrudService } from '../classes/entity-crud-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { ErrorService } from './error.service';
import { OrderFile } from '../models/order-file';
import { OrderFilesAdapterService } from '../adapters/order-files-adapter.service';
import { Observable } from 'rxjs';
import { EntityData } from '../interfaces/entity-data';
import { map } from 'rxjs/operators';
import { ClientModes } from '../enums/client-modes';

@Injectable({
  providedIn: 'root'
})
export class OrderFilesService extends EntityCrudService<OrderFile> {
  baseApiPath = 'order';

  constructor(protected http: HttpClient,
    protected configService: ConfigService,
    protected entityAdapter: OrderFilesAdapterService,
    protected errorService: ErrorService,) {
    super(http, configService, entityAdapter, errorService);
  }

  getListClientMode(masterEntityId, clientMode: ClientModes, page = 1, limit = 100): Observable<EntityData<OrderFile>> {
    const body = {
      masterEntityId: masterEntityId,
      clientMode,
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/files`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderFile>))
      );
  }
}
