import {Model} from './model';

export class OrderRoute extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public orderId?: string,
    public sourceName?: string,
    public sourceNameShort?: string,
    public sourceLongitude?: number,
    public sourceLatitude?: number,
    public destinationName?: string,
    public destinationNameShort?: string,
    public destinationLongitude?: number,
    public destinationLatitude?: number,
    public dateIn?: Date,
    public timeIn?: string,
    public timeInHours?: string,
    public timeInMinutes?: string,
    public dateOut?: Date,
    public timeOut?: string,
    public timeOutHours?: string,
    public timeOutMinutes?: string,
    public distance?: number,
    public contactsInPhone1?: string,
    public contactsInPhone2?: string,
    public contactsInPerson?: string,
    public contactsOutPhone1?: string,
    public contactsOutPhone2?: string,
    public contactsOutPerson?: string,
    public normativeTime?: string,
  ) {
    super(id, name);
  }
}
