import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {EntityEditForm} from '../../../../../../shared/classes/entity-edit-form';
import {ProposalRoute} from '../../../../../../shared/models/proposal-route';
import {MetaDataCrud} from '../../../../../../shared/interfaces/meta-data-crud';
import {ProposalRoutesService} from '../../../../../../shared/services/proposal-routes.service';
import {CommonValidatorsService} from '../../../../../../shared/services/common-validators.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {RoutesUtilitiesService} from '../../../../../../shared/services/routes-utilities.service';
import ymaps from 'ymaps';
import {FormModes} from '../../../../../../shared/enums/form-modes';
import {ErrDialogComponent} from '../../../../../../shared/components/err-dialog/err-dialog.component';

(window as any).global = window;

@Component({
  selector: 'app-proposal-form-routes-form',
  templateUrl: './routes-form.component.html',
  styleUrls: ['./routes-form.component.scss']
})
export class RoutesFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: ProposalRoute;
  entityForm: FormGroup;
  pending: boolean;
  protected metaData: MetaDataCrud[] = this.getMetaData();
  formModes = FormModes;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              protected entityService: ProposalRoutesService,
              private validatorsService: CommonValidatorsService,
              private routesUtilitiesService: RoutesUtilitiesService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<RoutesFormComponent>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get sourceName() {
    return this.entityForm.get('sourceName');
  }

  get destinationName() {
    return this.entityForm.get('destinationName');
  }

  get distance() {
    return this.entityForm.get('distance');
  }

  get dateOutStart() {
    return this.entityForm.get('dateOutStart');
  }

  get dateInStart() {
    return this.entityForm.get('dateInStart');
  }

  getMetaData() {
    return [
      {
        entityField: 'sourceName',
        formField: 'sourceName',
        validators: [Validators.required]
      },
      {
        entityField: 'sourceCoordinates',
        formField: 'sourceCoordinates',
      },
      {
        entityField: 'sourceLongitude',
        formField: 'sourceLongitude',
      },
      {
        entityField: 'sourceLatitude',
        formField: 'sourceLatitude',
      },
      {
        entityField: 'destinationName',
        formField: 'destinationName',
        validators: [Validators.required]
      },
      {
        entityField: 'destinationCoordinates',
        formField: 'destinationCoordinates',
      },
      {
        entityField: 'destinationLongitude',
        formField: 'destinationLongitude',
      },
      {
        entityField: 'destinationLatitude',
        formField: 'destinationLatitude',
      },
      {
        entityField: 'distance',
        formField: 'distance',
        validators: [Validators.required]
      },
      {
        entityField: 'sourceRadius',
        formField: 'sourceRadius',
      },
      {
        entityField: 'destinationRadius',
        formField: 'destinationRadius',
      },
      {
        entityField: 'dateOutType',
        formField: 'dateOutType',
      },
      {
        entityField: 'dateInType',
        formField: 'dateInType',
      },
      {
        entityField: 'dateOutStart',
        formField: 'dateOutStart',
        validators: [Validators.required]
      },
      {
        entityField: 'dateOutEnd',
        formField: 'dateOutEnd',
      },
      {
        entityField: 'dateInStart',
        formField: 'dateInStart',
        validators: [Validators.required]
      },
      {
        entityField: 'dateInEnd',
        formField: 'dateInEnd',
      },
    ];
  }

  ngOnInit() {
    this.initForm();

    if (this.data.formMode === FormModes.edit) {
      this.pending = true;

      forkJoin(
        this.entityService.getOne(this.data.id),
      ).pipe(
        finalize(() => this.pending = false)
      ).subscribe(([entityData]) => {
        this.entityItem = entityData.list[0];

        this.entityForm.patchValue(this.getFormPatchObject());

        this.loadYmaps();
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      });
    } else if (this.data.formMode === FormModes.add) {
      this.loadYmaps();

      this.entityItem = new ProposalRoute();

      this.entityForm.controls.dateOutType.setValue('VALUE');
      this.entityForm.controls.dateInType.setValue('VALUE');
    }
  }

  loadYmaps() {
    const me = this;

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90').then(maps => {
      const sourceNameSV = new maps.SuggestView('sourceName');
      const destinationNameSV = new maps.SuggestView('destinationName');

      const myMap = new maps.Map('map', {
          center: [60.906882, 30.067233],
          zoom: 9,
          controls: []
        }),
        // Создадим панель маршрутизации.
        routePanelControl = new maps.control.RoutePanel({
          options: {
            // Добавим заголовок панели.
            showHeader: true,
            visible: false,
            title: 'Расчёт расстояния'
          }
        }),
        zoomControl = new maps.control.ZoomControl({
          options: {
            size: 'small',
            float: 'none',
            position: {
              bottom: 145,
              right: 10
            }
          }
        });

      // Пользователь сможет построить только автомобильный маршрут.
      routePanelControl.routePanel.options.set({
        types: {auto: true}
      });

      if (me.entityForm.controls.sourceName.value) {
        routePanelControl.routePanel.state.set({
          fromEnabled: true,
          from: me.entityForm.controls.sourceName.value
        });
      }

      if (me.entityForm.controls.destinationName.value) {
        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: me.entityForm.controls.destinationName.value
        });
      }

      sourceNameSV.events.add('select', (event) => {
        me.entityForm.controls.sourceName.setValue(event.originalEvent.item.value);
        me.entityForm.controls.sourceLongitude.setValue('');
        me.entityForm.controls.sourceLatitude.setValue('');

        routePanelControl.routePanel.state.set({
          fromEnabled: true,
          from: event.originalEvent.item.value
        });

        // Ищем координаты указанного адреса
        // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
        const geocoder = maps.geocode(event.originalEvent.item.value);

        // После того, как поиск вернул результат, вызывается callback-функция
        geocoder.then(function (res) {
            // координаты объекта
            // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
            const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

            me.entityForm.controls.sourceLatitude.setValue(coordinates[0]);
            me.entityForm.controls.sourceLongitude.setValue(coordinates[1]);
          }
        );
      });

      destinationNameSV.events.add('select', (event) => {
        me.entityForm.controls.destinationName.setValue(event.originalEvent.item.value);
        me.entityForm.controls.destinationLongitude.setValue('');
        me.entityForm.controls.destinationLatitude.setValue('');

        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: event.originalEvent.item.value
        });

        // Ищем координаты указанного адреса
        // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
        const geocoder = maps.geocode(event.originalEvent.item.value);

        // После того, как поиск вернул результат, вызывается callback-функция
        geocoder.then(function (res) {
            // координаты объекта
            // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
            const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

            me.entityForm.controls.destinationLatitude.setValue(coordinates[0]);
            me.entityForm.controls.destinationLongitude.setValue(coordinates[1]);
          }
        );
      });

      myMap.controls.add(routePanelControl).add(zoomControl);

      // Получим ссылку на маршрут.
      routePanelControl.routePanel.getRouteAsync().then(function (route) {
        // Зададим максимально допустимое число маршрутов, возвращаемых мультимаршрутизатором.
        route.model.setParams({results: 1}, true);

        // Повесим обработчик на событие построения маршрута.
        route.model.events.add('requestsuccess', function (event) {
          const wayPoints = route.model.properties.get('waypoints');

          if (wayPoints.length) {
            me.entityForm.controls.sourceName.setValue(wayPoints[0].address);
            me.entityForm.controls.sourceCoordinates.setValue(wayPoints[0].coordinates.join());
          } else {
            me.entityForm.controls.sourceName.setValue('');
            me.entityForm.controls.sourceCoordinates.setValue('');
          }

          if (wayPoints.length > 1) {
            me.entityForm.controls.destinationName.setValue(wayPoints[wayPoints.length - 1].address);
            me.entityForm.controls.destinationCoordinates.setValue(wayPoints[wayPoints.length - 1].coordinates.join());
          } else {
            me.entityForm.controls.destinationName.setValue('');
            me.entityForm.controls.destinationCoordinates.setValue('');
          }

          me.entityForm.controls.distance.setValue(0);

          const activeRoute = route.getActiveRoute();
          if (activeRoute) {
            // Получим протяженность маршрута.
            const distanceProperty = route.getActiveRoute().properties.get('distance'),
              distance = (Math.round(distanceProperty.value / 1000 * 100) / 100).toFixed(0);

            me.entityForm.controls.distance.setValue(distance);

            // Создадим макет содержимого балуна маршрута.
            const balloonContentLayout = maps.templateLayoutFactory.createClass(
              '<span>Расстояние: ' + distance + ' км.</span><br/>');
            // Зададим этот макет для содержимого балуна.
            route.options.set('routeBalloonContentLayout', balloonContentLayout);
            // Откроем балун.
            activeRoute.balloon.open();
          }
        });
      });
    }).catch(error => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Ошибка загрузки Яндекс карт'
        }
      });

      console.dir(error);
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  async save() {
    if (!this.entityForm.valid) {
      Object.keys(this.entityForm.controls).forEach(field => {
        const control = this.entityForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      return;
    }

    this.pending = true;

    let geoErrors = [];

    if (!this.entityForm.controls.sourceLatitude.value || !this.entityForm.controls.sourceLongitude.value ||
      !this.entityForm.controls.destinationLatitude.value || !this.entityForm.controls.destinationLongitude.value) {
      const maps = await ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90');

      if (!this.entityForm.controls.sourceLatitude.value || !this.entityForm.controls.sourceLongitude.value) {
        let res = await maps.geocode(this.entityForm.controls.sourceName.value);
        // координаты объекта
        // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
        if (res.geoObjects.length) {
          let coordinates = res.geoObjects.get(0).geometry.getCoordinates();

          this.entityForm.controls.sourceLatitude.setValue(coordinates[0]);
          this.entityForm.controls.sourceLongitude.setValue(coordinates[1]);
        } else {
          geoErrors.push('Не удалось определить координаты пункта отправления');
        }
      }

      if (!this.entityForm.controls.destinationLatitude.value || !this.entityForm.controls.destinationLongitude.value) {
        let res = await maps.geocode(this.entityForm.controls.destinationName.value);
        // координаты объекта
        // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
        if (res.geoObjects.length) {
          let coordinates = res.geoObjects.get(0).geometry.getCoordinates();

          this.entityForm.controls.destinationLatitude.setValue(coordinates[0]);
          this.entityForm.controls.destinationLongitude.setValue(coordinates[1]);
        } else {
          geoErrors.push('Не удалось определить координаты пункта назначенния');
        }
      }
    }

    if (geoErrors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: geoErrors
      });

      this.pending = false;
      return;
    }

    this.metaData.forEach(item => {
      if (item.skip) {
        return;
      }
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    this.entityItem.proposalId = this.data.masterEntityId;

    if (this.data.formMode === FormModes.edit) {
      this.entityService.updateCheck(this.entityItem, this.data.docDateEnd).subscribe(success => {
          this.pending = false;
          this.dialogRef.close({reload: true});
          this.toastService.success('Объект изменен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    } else if (this.data.formMode === FormModes.add) {
      this.entityService.addCheck(this.entityItem, this.data.docDateEnd).subscribe(success => {
          this.pending = false;
          this.dialogRef.close({reload: true});
          this.toastService.success('Объект добавлен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    }
  }

  onSourceNameChange() {
    this.entityForm.controls.sourceLatitude.setValue('');
    this.entityForm.controls.sourceLongitude.setValue('');
  }

  onDestinationNameChange() {
    this.entityForm.controls.destinationLatitude.setValue('');
    this.entityForm.controls.destinationLongitude.setValue('');
  }
}
