import {Model} from './model';
import {Permissions} from '../interfaces/permissions';

export class OrderDocument extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public docDate?: Date,
    public docNumber?: string,
    public type?: string,
    public status?: string,
    public statusExecution?: string,
    public price?: number,
    public estimatedPrice?: number,
    public resultPrice?: number,
    public totalPrice?: number,
    public declaredPrice?: number,
    public insurancePrice?: number,
    public siteComissionRate?: number,
    public deferPrice?: number,
    public step?: string,
    public organizationId?: string,
    public userId?: string,
    public docDateStart?: Date,
    public docDateEnd?: Date,
    public comment?: string,
    public truckTypeIds?: string[],
    public truckTypeOther?: string,
    public cargoTypeId?: string,
    public cargoTypeOther?: string,
    public weight?: number,
    public addCargo?: boolean,
    public sanitaryPassport?: boolean,
    public isDriverVerified?: boolean,
    public isDriver1Verified?: boolean,
    public isTransportDataConfirmed?: boolean,
    public taxVat?: string,
    public capacity?: number,
    public paymentDeferType?: string,
    public paymentDeferDays?: number,
    public loadTypeIds?: string[],
    public driverId?: string,
    public driver1Id?: string,
    public truckId?: string,
    public insuranceNumber?: string,
    public insuranceId?: string,
    public insuranceCreationDate?: Date,
    public insuranceStartDate?: Date,
    public insuranceStartTransportationDate?: Date,
    public insuranceEndTransportationDate?: Date,
    public insuranceEndDate?: Date,
    public insurancePayDate?: Date,
    public insuranceCargoName?: string,
    public insuranceCargoDoc?: string,
    public insuranceCargoTypeId?: string,
    public insuranceCurrency?: string,
    public insuranceLimitSum?: number,
    public insuranceTransportTypeId?: string,
    public insuranceBeneficiary?: string,
    public insurancePackageTypeId?: string,
    public packageTypeId?: string,
    public insuranceCarrierName?: string,
    public insuranceSeats?: number,
    public seats?: number,
    public insuranceWeight?: number,
    public insurancePointFrom?: string,
    public insurancePointTo?: string,
    public insuranceBeginningResponsibilityId?: string,
    public insuranceEndResponsibilityId?: string,
    public isLoadingLift?: boolean,
    public isRequiredDocumentType1?: boolean,
    public isRequiredDocumentType3?: boolean,
    public isRequiredDocumentType4?: boolean,
    public isRequiredDocumentType5?: boolean,
    public isRequiredDocumentType6?: boolean,
    public isRequiredDocumentType7?: boolean,
    public isRequiredDocumentType8?: boolean,
    public requiredDocumentTypeOther?: string,
    public isProvidedDocumentType1?: boolean,
    public isProvidedDocumentType2?: boolean,
    public providedDocumentTypeOther?: string,
    public ownerStep1FileName?: string,
    public ownerStep1FileUid?: string,
    public isOwnerStep1FileConfirmed?: boolean,
    public carrierStep1FileName?: string,
    public carrierStep1FileUid?: string,
    public isCarrierStep1FileConfirmed?: boolean,
    public carrierStep2FileName1?: string,
    public carrierStep2FileUid1?: string,
    public carrierStep2FileName2?: string,
    public carrierStep2FileUid2?: string,
    public carrierStep2FileName3?: string,
    public carrierStep2FileUid3?: string,
    public isCarrierStep2FileConfirmed?: boolean,
    public senderName?: string,
    public senderInn?: string,
    public senderAddress?: string,
    public recipientName?: string,
    public recipientInn?: string,
    public recipientAddress?: string,
    public payerName?: string,
    public payerInn?: string,
    public payerAddress?: string,
    public ownerConfirmationCargoDoc?: string,
    public totalPriceCarrier?: number,
    public permissions?: Permissions,
    public containerNumber?: string,
    public containerType?: string,
    public isTrunk?: boolean,
    public isInOperationProgress?: boolean,
    public isInOperationProgressBefore?: boolean,
    public isInOperationProgressAvailable?: boolean,
  ) {
    super(id, name);
  }
}
