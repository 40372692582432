import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ProposalConfirmationRoutesPoints} from '../../../../../shared/models/proposal-confirmation-routes-points';
import {GridRoutesPointsDatasource} from './grid-routes-points.datasource';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {MainFormService} from '../../../services/main-form.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, MatDialog, NativeDateAdapter} from '@angular/material';
import {colsConfig} from './config/cols.config';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {OwnerRoutePointEditFormComponent} from './components/owner-route-point-edit-form/owner-route-point-edit-form.component';
import {ConfirmDialogData} from '../../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../../shared/components/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-proposal-confirmation-grid-routes-points',
  templateUrl: './grid-routes-points.component.html',
  styleUrls: ['./grid-routes-points.component.scss']
})
export class GridRoutesPointsComponent extends TableComponent<(ProposalConfirmationRoutesPoints), GridRoutesPointsDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name_', 'carrierRoute', 'radius', 'ownerRoute'];
  storageKey = 'app-proposal-confirmation-grid-routes-points';
  dataSource: GridRoutesPointsDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  entityId: string;

  constructor(private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              private dateAdapter: DateAdapter<NativeDateAdapter>) {
      super();
      dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.routesPointsForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.routesPointsForm = form;
  }

  ownerRouteId(index: number) {
    return 'ownerRoute' + index;
  }

  getOwnerRoute(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('ownerRoute').value;
  }

  ngOnInit() {
    this.initForm();

    super.ngOnInit();

    this.initDataSource();
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          id: [''],
          code: [''],
          carrierRoute: [''],
          carrierCoordinates: [''],
          radius: [''],
          ownerRoute: [''],
        }),
        this.formBuilder.group({
          id: [''],
          code: [''],
          carrierRoute: [''],
          carrierCoordinates: [''],
          radius: [''],
          ownerRoute: [''],
        }),
      ])
    });
  }

  ngOnDestroy() {}

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  initDataSource() {
    this.dataSource = new GridRoutesPointsDatasource(this.entityService, this.errorService, this.snackService);

    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  ownerValueChange($event, entityItem: ProposalConfirmationRoutesPoints, index: number) {
    const ownerRoute = $event.target.value;

    if (ownerRoute.trim() !== entityItem.carrierRoute.trim()) {
      this.snackService.showErrorMsg('Потребность отправителя должна быть равна предложению перевозчика');
      $event.target.value = '';
      (this.componentForm.get('items') as FormArray).controls[index].get('ownerRoute').setValue(null);
    }
  }

  openEditDialog(row: any) {
    if (this.mainFormService.confirmationReadOnly) {
      return;
    }

    if (row.radius === 0) {
      const dialogData: ConfirmDialogData = {
        contentMsg: 'Перевозчик указал радиус = 0, населенный пункт не может быть изменен',
        btnsArray: [
          {
            message: 'Ок',
            action: () => {
              dialog.close();
            }
          },
        ]
      };
      const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });

      return;
    }

    const dialogRef = this.dialog.open(OwnerRoutePointEditFormComponent, {
      data: {
        entityId: this.mainFormService.proposalId,
        id: row.id,
        code: row.code,
        carrierRoute: row.carrierRoute,
        carrierCoordinates: row.carrierCoordinates,
        radius: row.radius,
        ownerRoute: row.ownerRoute,
      },
      width: '750px',
      height: '560px',
    });

    dialogRef.afterClosed().subscribe(data => {
      this.update();
    });
  }
}
