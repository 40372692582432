import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {Model} from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class ModelAdapterService implements EntityAdapter<Model> {
  adaptIn(item: any): Model {
    return new Model(
      item.id,
      item.name,
    );
  }

  adaptOut(item: Model): any {
    return {
      ...item,
    };
  }
}
