import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {CargoType} from '../models/cargo-type';

@Injectable({
  providedIn: 'root'
})
export class CargoTypesAdapterService implements EntityAdapter<CargoType> {
  adaptIn(item: any): CargoType {
    return new CargoType(
      item.id,
      item.name,
    );
  }

  adaptOut(item: CargoType): any {
    return {
      ...item
    };
  }
}
