export enum UserRoles {
  ROLE_USER = 'ROLE_USER',

  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',

  ROLE_OWNER_SENIOR_MANAGER = 'ROLE_OWNER_SENIOR_MANAGER',
  ROLE_OWNER_MANAGER = 'ROLE_OWNER_MANAGER',
  ROLE_OWNER_TRAINEE = 'ROLE_OWNER_TRAINEE',

  ROLE_CARRIER_SENIOR_MANAGER = 'ROLE_CARRIER_SENIOR_MANAGER',
  ROLE_CARRIER_MANAGER = 'ROLE_CARRIER_MANAGER',
  ROLE_CARRIER_TRAINEE = 'ROLE_CARRIER_TRAINEE',

  ROLE_USER_CONFIG = 'ROLE_USER_CONFIG',

  ROLE_OPERATOR = 'ROLE_OPERATOR',
}
