export const colsConfig = [
  {
    name: 'Наименование',
    show: true,
    class: 'name_',
  },
  {
    name: 'Тел 1',
    show: true,
    class: 'ownerContactsPhone1',
  },
  {
    name: 'Тел 2',
    show: true,
    class: 'ownerContactsPhone2',
  },
  {
    name: 'Контактное лицо',
    show: true,
    class: 'ownerContactsPerson',
  },
];
