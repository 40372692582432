// This file can be replaced during build by using the `fileReplacements` array.
// first run `export NODE_OPTIONS=--openssl-legacy-provider` then
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // url: 'http://127.0.0.1:80',
  url: "https://apllogistic.ru",
  apiUrl: "/api",
  dadataInnUrl:
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
  dadataToken: "b1e3d02311fe06288f92bafd7e718572c1eca5d2",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
