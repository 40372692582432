import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {TruckEquipment} from '../models/truck-equipment';
import {TruckEquipmentAdapterService} from '../adapters/truck-equipment-adapter.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {ClientModes} from '../enums/client-modes';
import {DocumentTypes} from '../enums/document-types';

@Injectable({
  providedIn: 'root'
})
export class TruckEquipmentService extends EntityCrudService<TruckEquipment> {
  baseApiPath = 'truck_equipment';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: TruckEquipmentAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getOneClientMode(masterEntityId: string, documentType: DocumentTypes, clientMode: ClientModes, id: string): Observable<EntityData<TruckEquipment>> {
    const body = {
      masterEntityId,
      documentType,
      clientMode,
      id
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/one_client_mode`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<TruckEquipment>))
      );
  }
}
