import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {Organization} from '../models/organization';
import {Model} from '../models/model';
import {EntityCrudService} from '../classes/entity-crud-service';
import {OrganizationsAdapterService} from '../adapters/organizations-adapter.service';
import {ClientModes} from '../enums/client-modes';
import {OrganizationRegFile} from '../models/organizationRegFile';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService extends EntityCrudService<Organization> {
  baseApiPath = 'organizations';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: OrganizationsAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getShortList(clientMode: ClientModes): Observable<EntityData<Organization>> {
    const body = {
      clientMode
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/short_list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  getOwnerShipTypesList(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/ownershiptypes/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  registrationCheck(entityItem: Organization): Observable<EntityData<Organization>> {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/registration/check`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Organization>))
      );
  }

  getRegFilesList(masterEntityId: number): Observable<EntityData<OrganizationRegFile>> {
    const body = {
      masterEntityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/regFiles/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<OrganizationRegFile>))
      );
  }

  deleteRegFile(uuid: string): Observable<EntityData<OrganizationRegFile>> {
    const body = {
      uuid,
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/regFiles/delete_file`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrganizationRegFile>))
      );
  }
  
  settlementsUpdate(entityItem: Organization){
    const body = {
      id: entityItem.id,
      balanceLimit: entityItem.balanceLimit,
      isBalanceNotify: entityItem.isBalanceNotify
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/settlements/update`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
