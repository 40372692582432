import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {Organization} from '../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsAdapterService implements EntityAdapter<Organization> {
  adaptIn(item: any): Organization {
    return new Organization(
      item.id,
      item.name,
      item.fullName,
      +item.deferedPayment,
      item.isDeferedPaymentSet,
      item.factAddress,
      item.jurAddress,
      item.phones,
      item.email,
      item.regCode,
      item.inn,
      item.kpp,
      item.ogrn,
      item.taxSystem,
      item.ownerRating,
      item.carrierRating,
      item.bankAccount,
      item.bankBik,
      item.corrAccount,
      item.bankName,
      item.ownerShipType,
      +item.siteComissionRate,
      item.permissions,
      item.balance,
      item.balanceLimit,
      item.isBalanceNotify
    );
  }

  adaptOut(item: Organization): any {
    return {
      ...item
    };
  }
}
