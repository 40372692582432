import { AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { TableDataSource } from './table-data-source';
import { MatPaginator, MatProgressBar, MatSort, Sort } from '@angular/material';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { CompareFunction } from '../interfaces/compare-function';
import { LocalStorageService } from 'ngx-webstorage';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, tap } from 'rxjs/operators';
import {TableColFilterChange} from '../components/table-col-filter/table-col-filter.component';
import {ColConfig} from '../interfaces/col-config';

export interface PaginationEvent {
  pageIndex: number;
  pageSize: number;
  length: number;
}

export abstract class TableComponent<T, D extends TableDataSource<T>> implements AfterViewInit, OnInit {

  @ViewChild(MatPaginator, {read: MatPaginator, static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {read: MatSort, static: true}) sort: MatSort;
  @ViewChild(MatProgressBar, {read: MatProgressBar, static: true}) progressBar: MatProgressBar;

  private tableLoadingSubject: Subject<boolean> = new Subject();

  tableLoading$: Observable<boolean>;

  abstract dataSource: D;
  abstract storageKey: string;
  abstract displayedColumns: string[];

  readonly COLS_STORAGE_KEY = 'colsConfRun';
  readonly ITEMS_PER_PAGE_STORAGE_KEY = 'itemsPerPage';
  readonly SORT_STORAGE_KEY = 'sortConf';
  readonly limitsList: number[] = [5, 10, 20, 50, 100];

  selection: SelectionModel<T>;

  itemsPerPage = 5;

  colsConfRun: ColConfig[];
  sortConf: {[key: string]: CompareFunction<T> } = {};
  colsShowConfig = {};

  protected colsConf: ColConfig[] = [];
  protected storage: LocalStorageService;

  defaultSortEvent: Sort;

  abstract update(): void;
  abstract initDataSource(): void;

  ngOnInit() {
    this.initCols();
    this.itemsPerPage = this.storage.retrieve(this.makeLocalStorageName(this.ITEMS_PER_PAGE_STORAGE_KEY)) || this.itemsPerPage;
    const sortConf = this.storage.retrieve(this.makeLocalStorageName(this.SORT_STORAGE_KEY));
    this.defaultSortEvent = sortConf ? sortConf : null;
  }

  onColsChange($event: TableColFilterChange): void {
    this.colsConfRun = $event.cols;
    const hashCols = {};
    this.colsConfRun.forEach(col => hashCols[col.class] = col.show);
    this.storage.store(this.makeLocalStorageName(this.COLS_STORAGE_KEY), hashCols);
    this.generateColsClassesForHiding();
  }

  onPageChange($event: PaginationEvent): void {
    this.itemsPerPage = $event.pageSize;
    this.storage.store(this.makeLocalStorageName(this.ITEMS_PER_PAGE_STORAGE_KEY), this.itemsPerPage);
  }

  protected makeLocalStorageName(key): string {
    return `${this.storageKey}_${key}`;
  }

  protected initCols(): void {
    this.colsConfRun = JSON.parse(JSON.stringify(this.colsConf));
    const hashCols = this.storage.retrieve(this.makeLocalStorageName(this.COLS_STORAGE_KEY));
    if (hashCols) {
      this.colsConfRun = this.colsConfRun.map(col => {
        if (hashCols[col.class] !== undefined) {
          col.show = hashCols[col.class] || false;
        } else {
          col.show = true;
        }
        if (col.disabled) {
          col.show = col.disabled;
        }
        return col;
      });
    }

    this.generateColsClassesForHiding();
  }

  private generateColsClassesForHiding(): void {
    if (this.displayedColumns.length < 1) {
      console.warn('wrong column definition');
      return;
    }

    if (this.displayedColumns[this.displayedColumns.length - 1] === 'options') {
      if (this.displayedColumns.length < 2) {
        console.warn('wrong column definition for table with options');
        return;
      }
    }

    if (!this.colsConfRun.length || this.colsConfRun.length === 0) {
      return;
    }

    const colsBegin = [];
    const colsEnd = [];

    for (const colName of this.displayedColumns) {
      const colPosition = this.colsShowConfig[colName];

      if (colPosition && colPosition === 'begin') {
        colsBegin.push(colName);
      }

      if (colPosition && colPosition === 'end') {
        colsEnd.push(colName);
      }
    }

    const colsToShow = Object
      .keys(this.colsConfRun)
      .filter(key => this.colsConfRun[key].show)
      .map(key => this.colsConfRun[key].class);

    this.displayedColumns = [
      ...colsBegin,
      ...colsToShow,
      ...colsEnd,
    ];
  }

  ngAfterViewInit() {
    this.initPaginator();
    this.initLoading();
  }

  initPaginator(): void {
    if (this.paginator) {
      this.initPageUpdate();
      this.initCheckEmptyPage();
    }
  }

  initLoading(): void {
    const tableLoading$ = !!this.progressBar ? this.tableLoadingSubject.asObservable() : of(false);
    const datasourceLoading$ = this.dataSource.loading$;
    this.tableLoading$ = merge(tableLoading$, datasourceLoading$)
      .pipe(
        untilDestroyed(this),
      );
  }

  tableLoading(value: boolean): void {
    this.tableLoadingSubject.next(value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getSnapshot().length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.getSnapshot().forEach(row => this.selection.select(row));
  }

  initPageUpdate(): void {
    this.paginator.page
      .pipe(
        untilDestroyed(this),
        tap(() => this.update()),
      )
      .subscribe();
  }

  initCheckEmptyPage(): void {
    const total$ = this.dataSource.total$;
    const loading$ = this.dataSource.loading$;

    combineLatest(total$, loading$).pipe(
      untilDestroyed(this),
      filter(([total, loading]) => !loading && this.currentPageEmpty(total)),
      tap(() => {
        this.paginator.previousPage();
      }),
    ).subscribe();
  }

  currentPageEmpty(total: number): boolean {
    if (!this.paginator.hasPreviousPage()) {
      return false;
    }
    const snapshot = this.dataSource.getSnapshot();
    return snapshot && snapshot.length === 0 && total > 0;
  }

  sortData(event: Sort): void {
    if (event.direction === '') {
      this.dataSource.resetSort();
    } else {
      this.dataSource.sort(this.sortConf[event.active], event.direction === 'asc');
    }
    this.storage.store(this.makeLocalStorageName(this.SORT_STORAGE_KEY), event);
  }

  onFilterFnChanged(filter: (...args) => boolean): void {
    this.dataSource.filter = filter || null;
  }
}
