import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {ClientModes} from '../../../../shared/enums/client-modes';
import {Proposal} from '../../../../shared/models/proposal';
import {ConfirmDialogData} from '../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../shared/components/confirm-popup/confirm-popup.component';
import {MatDialog} from '@angular/material';
import {finalize} from 'rxjs/operators';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {ProposalsService} from '../../../../shared/services/proposals.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfirmationFileTypes} from '../../../../shared/enums/confirmation-file-types';
import {ErrDialogComponent} from '../../../../shared/components/err-dialog/err-dialog.component';

@Component({
  selector: 'app-proposal-form-tab-documents-confirmation',
  templateUrl: './tab-documents-confirmation.component.html',
  styleUrls: ['./tab-documents-confirmation.component.scss']
})
export class TabDocumentsConfirmationComponent implements OnInit, OnDestroy {
  pending = false;
  panelOpenState = false;

  fileUploadApi = '';

  entityItem: Proposal = new Proposal();
  ownerStep1FormDataParams: any;
  carrierStep1FormDataParams: any;
  carrierStep2FormDataParams1: any;
  carrierStep2FormDataParams2: any;
  carrierStep2FormDataParams3: any;

  constructor(private formBuilder: FormBuilder,
              protected entityService: ProposalsService,
              private mainFormService: MainFormService,
              protected configService: ConfigService,
              private storage: LocalStorageService,
              private dialog: MatDialog,
              private toastService: ToastrService,
              private snackService: SnackBarService,) {
    this.fileUploadApi = `${this.configService.apiUrl}/proposal/upload_file`;
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabDocumentsConfirmationForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabDocumentsConfirmationForm = form;
  }

  get driverId() {
    return this.componentForm.get('driverId');
  }

  get truckId() {
    return this.componentForm.get('truckId');
  }

  ngOnInit() {
    this.initForm();

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.entityItem = data;

            this.ownerStep1FormDataParams = {
              masterEntityId: data.id,
              clientMode: ClientModes.owner,
              confirmationFileType: ConfirmationFileTypes.step1,
            };

            this.carrierStep1FormDataParams = {
              masterEntityId: data.id,
              clientMode: ClientModes.carrier,
              confirmationFileType: ConfirmationFileTypes.step1,
            };

            this.carrierStep2FormDataParams1 = {
              masterEntityId: data.id,
              clientMode: ClientModes.carrier,
              confirmationFileType: ConfirmationFileTypes.carrierStep2File1,
            };

            this.carrierStep2FormDataParams2 = {
              masterEntityId: data.id,
              clientMode: ClientModes.carrier,
              confirmationFileType: ConfirmationFileTypes.carrierStep2File2,
            };

            this.carrierStep2FormDataParams3 = {
              masterEntityId: data.id,
              clientMode: ClientModes.carrier,
              confirmationFileType: ConfirmationFileTypes.carrierStep2File3,
            };
          }
        }
      );
  }

  ngOnDestroy() {}

  initForm() {
    this.componentForm = this.formBuilder.group({
      driverId: ['', Validators.required],
      driver1Id: [''],
      truckId: ['', Validators.required],
    });
  }

  downloadDocumentFile(clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes) {
    const link = document.createElement('a');
    const params = {
      token: this.storage.retrieve('token'),
      masterEntityId: this.entityItem.id,
      clientMode,
      confirmationFileType,
    }

    link.download = 'test';
    link.target = 'blank';
    link.href = `${this.configService.apiUrl}/proposal/download_file/?${this.encodeQueryData(params)}`;
    link.click();
  }

  deleteDocumentFile(clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes, field: string) {
    this.pending = true;

    this.entityService.deleteConfirmationFile(this.entityItem.id, clientMode, confirmationFileType)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          this.entityItem[field] = null;

          this.toastService.success('Документ удален', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  openDeleteDocumentFileDialog(clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes, field: string) {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Удалить файл ?',
      btnsArray: [
        {
          message: 'Удалить',
          action: () => {
            dialog.close();
            this.deleteDocumentFile(clientMode, confirmationFileType, field);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  onFileComplete(field: string, data: any) {
    this.entityItem[field] = '';

    if (data.data[0].fileName) {
      this.entityItem[field] = data.data[0].fileName;
    }
  }

  fileDisabled(field1: string, field2: string) {
    if (![ClientModes.owner, ClientModes.carrier].includes(this.mainFormService.clientMode) || this.pending) {
      return true;
    }

    if (this.entityItem[field1] || this.entityItem[field2]) {
      return true
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.permissions.all) {
        return false
      }

      if (field1 === 'carrierStep1FileName') {
        return !this.entityItem.permissions.documentsPrepare.upload
      } else if (['carrierStep2FileName1', 'carrierStep2FileName2', 'carrierStep2FileName3'].includes(field1)) {
        return !this.entityItem.permissions.documentsComplete.upload
      }
    }

    return false;
  }

  step1CarrierDeleteButtonDisabled() {
    if (!this.entityItem.carrierStep1FileName || this.entityItem.isCarrierStep1FileConfirmed) {
      return true;
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.permissions.hasOwnProperty('all')) {
        return !this.entityItem.permissions.all
      }
      if (this.entityItem.permissions.hasOwnProperty('documentsPrepare')) {
        return !this.entityItem.permissions.documentsPrepare.delete
      }
    }

    return false;
  }

  step2DeleteButtonDisabled(field1: string, field2: string) {
    if (!this.entityItem[field1] || this.entityItem[field2]) {
      return true;
    }

    if (this.entityItem.permissions) {
      return !(this.entityItem.permissions.all || this.entityItem.permissions.documentsComplete.delete)
    }

    return false;
  }

  driversDisabled() {
    if (this.entityItem.isTransportDataConfirmed) {
      return true;
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.hasOwnProperty('all') && !this.entityItem.permissions.all) {
        return true
      }
      if (this.entityItem.hasOwnProperty('drivers') && !this.entityItem.permissions.drivers.edit) {
        return true
      }
    }

    return false;
  }

  openConfirmDocumentDialog(clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes, field: string) {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'После подтверждения документов отправителя и перевозчика Предложение будет переведено на статус "Исполнено". Продолжить ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();
            this.confirmDocument(this.entityItem.id, clientMode, confirmationFileType, field);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  confirmDocument(masterEntityId: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes, field: string) {
    this.pending = true;

    this.entityService.confirmDocument(masterEntityId, clientMode, confirmationFileType)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          this.entityItem[field] = true;

          this.toastService.success('Документ подтвержден', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  openConfirmTransportDataDialog() {
    const errMsg = [];

    if (!this.componentForm.controls.driverId.value && !this.componentForm.controls.driver1Id.value) {
      errMsg.push('Хотя бы один из водителей должен быть указан');
    }

    if (!this.componentForm.controls.truckId.value) {
      errMsg.push('Автотранспорт должен быть указан');
    }

    if (errMsg.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errMsg
      });

      return;
    }

    const dialogData: ConfirmDialogData = {
      contentMsg: 'Подтвердить данные о транспорте и водителях ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();
            this.confirmTransportData(this.entityItem.id,
              this.componentForm.controls.driverId.value,
              this.componentForm.controls.driver1Id.value,
              this.componentForm.controls.truckId.value);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  confirmTransportData(masterEntityId: string, driverId: string, driver1Id: string, truckId: string) {
    this.pending = true;

    this.entityService.confirmTransportData(masterEntityId, driverId, driver1Id, truckId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          this.entityItem.isTransportDataConfirmed = true;

          this.toastService.success('Данные подтверждены', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
  }
}
