import {Model} from './model';

export class TruckType extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public parent?: string,
    public parentId?: string,
    public level?: number,
  ) {
    super(id, name);
  }
}
