export const colsConfig = [
  {
    name: 'Контрагент',
    show: true,
    class: 'description',
  },
  {
    name: 'Наименование',
    show: true,
    class: 'name_',
  },
  {
    name: 'ИНН',
    show: true,
    class: 'inn',
  },
  {
    name: 'Адресс',
    show: true,
    class: 'address',
  },
];
