import {Model} from './model';
import {Permissions} from '../interfaces/permissions';

export class Proposal extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public isArchive?: boolean,
    public docDate?: Date,
    public docNumber?: string,
    public type?: string,
    public status?: string,
    public statusExecution?: string,
    public price?: number,
    public estimatedPrice?: number,
    public resultPrice?: number,
    public totalPrice?: number,
    public declaredPrice?: number,
    public insurancePrice?: number,
    public siteComissionRate?: number,
    public orgComissionRate?: number,
    public deferPrice?: number,
    public totalSumInsuranceAdd?: number,
    public insuranceRate?: number,
    public deferSum?: number,
    public step?: string,
    public organizationId?: string,
    public userId?: string,
    public winBidUserId?: string,
    public docDateStart?: Date,
    public docDateEnd?: Date,
    public comment?: string,
    public truckTypeIds?: string[],
    public truckTypeOther?: string,
    public cargoTypeId?: string,
    public cargoTypeOther?: string,
    public weight?: number,
    public addCargo?: boolean,
    public sanitaryPassport?: boolean,
    public isDriverVerified?: boolean,
    public isDriver1Verified?: boolean,
    public isTransportDataConfirmed?: boolean,
    public taxVat?: string,
    public capacity?: number,
    public paymentDeferType?: string,
    public paymentDeferDays?: number,
    public loadTypeIds?: string[],
    public driverId?: string,
    public driver1Id?: string,
    public truckId?: string,
    public insuranceNumber?: string,
    public insuranceId?: string,
    public insuranceCreationDate?: Date,
    public insuranceStartDate?: Date,
    public insuranceStartTransportationDate?: Date,
    public insuranceEndTransportationDate?: Date,
    public insuranceEndDate?: Date,
    public insurancePayDate?: Date,
    public insuranceCargoName?: string,
    public insuranceCargoDoc?: string,
    public insuranceCargoTypeId?: string,
    public insuranceCurrency?: string,
    public insuranceLimitSum?: number,
    public insuranceTransportTypeId?: string,
    public insuranceBeneficiary?: string,
    public insurancePackageTypeId?: string,
    public insuranceCarrierName?: string,
    public insuranceSeats?: number,
    public seats?: number,
    public ownerSeats?: number,
    public insuranceWeight?: number,
    public insurancePointFrom?: string,
    public insurancePointTo?: string,
    public insuranceBeginningResponsibilityId?: string,
    public insuranceEndResponsibilityId?: string,
    public isLoadingLift?: boolean,
    public isRequiredDocumentType1?: boolean,
    public isRequiredDocumentType2?: boolean,
    public requiredDocumentTypeOther?: string,
    public isProvidedDocumentType1?: boolean,
    public isProvidedDocumentType3?: boolean,
    public isProvidedDocumentType4?: boolean,
    public isProvidedDocumentType5?: boolean,
    public providedDocumentTypeOther?: string,
    public confirmationSignDate?: Date,
    public confirmationStartDate?: Date,
    public confirmationEndDate?: Date,
    public confirmationPayDate?: Date,
    public confirmationStartTransportationDate?: Date,
    public confirmationEndTransportationDate?: Date,
    public confirmationCargoName?: string,
    public confirmationCargoDoc?: string,
    public confirmationCurrency?: string,
    public confirmationLimitSum?: number,
    public confirmationBeneficiary?: string,
    public confirmationTransportTypeId?: string,
    public confirmationPackageTypeId?: string,
    public confirmationCarrierName?: string,
    public confirmationSeats?: number,
    public confirmationWeight?: number,
    public confirmationPointFrom?: string,
    public confirmationPointTo?: string,
    public confirmationBeginningResponsibilityId?: string,
    public confirmationEndResponsibilityId?: string,
    public winBidSum?: number,
    public equipmentSum?: number,
    public ownerStep1FileName?: string,
    public ownerStep1FileUid?: string,
    public isOwnerStep1FileConfirmed?: boolean,
    public carrierStep1FileName?: string,
    public carrierStep1FileUid?: string,
    public isCarrierStep1FileConfirmed?: boolean,
    public carrierStep2FileName1?: string,
    public carrierStep2FileUid1?: string,
    public carrierStep2FileName2?: string,
    public carrierStep2FileUid2?: string,
    public carrierStep2FileName3?: string,
    public carrierStep2FileUid3?: string,
    public isCarrierStep2FileConfirmed?: boolean,
    public senderName?: string,
    public senderInn?: string,
    public senderAddress?: string,
    public recipientName?: string,
    public recipientInn?: string,
    public recipientAddress?: string,
    public payerName?: string,
    public payerInn?: string,
    public payerAddress?: string,
    public ownerConfirmationCargoDoc?: string,
    public permissions?: Permissions,
    public cargoNote?: string,
    public isInOperationProgress?: boolean,
    public isInOperationProgressBefore?: boolean,
    public isInOperationProgressAvailable?: boolean,
  ) {
    super(id, name);
  }
}
