import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalConfirmationCommon} from '../models/proposal-confirmation-common';
import {GroupBy} from '../../modules/proposal-form/forms/tab-confirmation/grid-common/grid-common.component';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsCommonAdapterService implements EntityAdapter<ProposalConfirmationCommon | GroupBy> {
  adaptIn(item: any): ProposalConfirmationCommon | GroupBy {
    if (item.initial) {
      return item;
    } else {
      return new ProposalConfirmationCommon(
        item.code,
        item.type,
        item.name,
        item.carrierValue,
        item.type === 'boolean' ? JSON.parse(item.ownerValue) : item.ownerValue,
      );
    }
  }

  adaptOut(item: ProposalConfirmationCommon): any {
    return {
      ...item
    };
  }
}
