import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {ProposalConfirmationCommon} from '../models/proposal-confirmation-common';
import {ProposalConfirmationsCommonAdapterService} from '../adapters/proposal-confirmations-common-adapter.service';
import {GroupBy} from '../../modules/proposal-form/forms/tab-confirmation/grid-common/grid-common.component';
import {ProposalConfirmationEquipment} from '../models/proposal-confirmation-equipment';
import {ProposalConfirmationsEquipmentAdapterService} from '../adapters/proposal-confirmations-equipment-adapter.service';
import {ProposalConfirmationRoutesPoints} from '../models/proposal-confirmation-routes-points';
import {ProposalConfirmationsRoutesPointsAdapterService} from '../adapters/proposal-confirmations-routes-points-adapter.service';
import {ProposalConfirmationsRoutesDatesAdapterService} from '../adapters/proposal-confirmations-routes-dates-adapter.service';
import {ProposalConfirmationRoutesDates} from '../models/proposal-confirmation-routes-dates';
import {ProposalConfirmationsRoutesContactsAdapterService} from '../adapters/proposal-confirmations-routes-contacts-adapter.service';
import {ProposalConfirmationRoutesContacts} from '../models/proposal-confirmation-routes-contacts';
import {ClientModes} from '../enums/client-modes';
import {ProposalConfirmationCounterparties} from '../models/proposal-confirmation-counterparties';
import {ProposalConfirmationsCounterpartiesAdapterService} from '../adapters/proposal-confirmations-counterparties-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsService {
  baseApiPath = 'owner/proposal/confirmation';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapterCommon: ProposalConfirmationsCommonAdapterService,
              protected entityAdapterEquipment: ProposalConfirmationsEquipmentAdapterService,
              protected entityAdapterRoutesPoints: ProposalConfirmationsRoutesPointsAdapterService,
              protected entityAdapterRoutesDates: ProposalConfirmationsRoutesDatesAdapterService,
              protected entityAdapterRoutesContacts: ProposalConfirmationsRoutesContactsAdapterService,
              protected entityAdapterCounterparties: ProposalConfirmationsCounterpartiesAdapterService,
              protected errorService: ErrorService) {}

  getListCommon(entityId: string): Observable<EntityData<ProposalConfirmationCommon | GroupBy>> {
    const body = {
      entityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/common/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterCommon.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationCommon | GroupBy>))
      );
  }

  getListEquipment(entityId: string, clientMode: ClientModes): Observable<EntityData<ProposalConfirmationEquipment>> {
    const body = {
      entityId,
      clientMode
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/equipment/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterEquipment.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationEquipment>))
      );
  }

  getListRoutesPoints(entityId: string): Observable<EntityData<ProposalConfirmationRoutesPoints>> {
    const body = {
      entityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/routes_points/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterRoutesPoints.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationRoutesPoints>))
      );
  }

  getListRoutesDates(entityId: string): Observable<EntityData<ProposalConfirmationRoutesDates>> {
    const body = {
      entityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/routes_dates/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterRoutesDates.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationRoutesDates>))
      );
  }

  getListRoutesContacts(entityId: string): Observable<EntityData<ProposalConfirmationRoutesContacts>> {
    const body = {
      entityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/routes_contacts/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterRoutesContacts.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationRoutesContacts>))
      );
  }

  getListCounterparties(entityId: string): Observable<EntityData<ProposalConfirmationCounterparties>> {
    const body = {
      entityId
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/counterparties/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterCounterparties.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationCounterparties>))
      );
  }

  // tslint:disable-next-line:max-line-length
  updateOwnerRoutePoint(entityId: string, entityItem: ProposalConfirmationRoutesPoints): Observable<EntityData<ProposalConfirmationRoutesPoints>> {
    const body = {
      entityId,
      id: entityItem.id,
      code: entityItem.code,
      ownerRoute: entityItem.ownerRoute
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/routes_points/update`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapterRoutesPoints.adaptIn(item))
            : []
        } as EntityData<ProposalConfirmationRoutesPoints>))
      );
  }
}
