import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {DocumentOperator} from '../models/document-operator';

@Injectable({
  providedIn: 'root'
})
export class DocumentOperatorAdapterService implements EntityAdapter<DocumentOperator> {
  adaptIn(item: any): DocumentOperator {
    return new DocumentOperator(
      item.id,
      item.userName,
      item.masterEntityId,
      item.actionDateTime,
      item.changes,
    );
  }

  adaptOut(item: DocumentOperator): any {
    return {
      ...item
    };
  }
}
