import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../shared/classes/table-data-source';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../shared/interfaces/entity-data';
import {ProposalConfirmationRoutesDates} from '../../../../../shared/models/proposal-confirmation-routes-dates';
import {FormArray, FormGroup} from '@angular/forms';

export class GridRoutesDatesDatasource extends TableDataSource<ProposalConfirmationRoutesDates> {
  constructor(protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(entityId: string, componentForm: FormGroup) {
    this.setLoading(true);
    this.entityService.getListRoutesDates(entityId)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<ProposalConfirmationRoutesDates>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);

          data.list.forEach((item, index) => {
            (componentForm.get('items') as FormArray).controls[index].get('id').setValue(item.id);
            (componentForm.get('items') as FormArray).controls[index].get('code').setValue(item.code);
            (componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').setValue(item.ownerDateStart);
            (componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').setValue(item.ownerDateEnd);
          });
        }, err => this.snackService.showErrorMsg(err));
  }
}
