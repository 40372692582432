import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {DocumentOrganization} from '../models/document-organization';
import {DocumentOrganizationsAdapterService} from '../adapters/document-organizations-adapter.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {DocumentTypes} from '../enums/document-types';
import {DocumentOrganizationRestrictions} from '../enums/document-organization-restrictions';
import {DocumentEquipment} from '../models/document-equipment';
import {ClientModes} from '../enums/client-modes';

@Injectable({
  providedIn: 'root'
})
export class RestrictedOrganizationsService {
  baseApiPath = 'documents/restricted-organizations';
  documentType: DocumentTypes;

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: DocumentOrganizationsAdapterService,
              protected errorService: ErrorService) {}

  getList(masterEntityId, restrictionType: DocumentOrganizationRestrictions, page = 1, limit = 100): Observable<EntityData<DocumentOrganization>> {
    const body = {
      documentType: this.documentType,
      restrictionType: restrictionType,
      masterEntityId: masterEntityId ? masterEntityId : 0,
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<DocumentOrganization>))
      );
  }

  getOne(id: string, restrictionType: DocumentOrganizationRestrictions): Observable<EntityData<DocumentEquipment>> {
    const body = {
      documentType: this.documentType,
      restrictionType: restrictionType,
      id
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/one`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<DocumentEquipment>))
      );
  }

  add(entityItem: DocumentEquipment, restrictionType: DocumentOrganizationRestrictions, clientMode: ClientModes) {
    const body = {
      documentType: this.documentType,
      restrictionType,
      clientMode,
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/add`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  update(entityItem: DocumentEquipment, restrictionType: DocumentOrganizationRestrictions, clientMode: ClientModes) {
    const body = {
      documentType: this.documentType,
      restrictionType,
      clientMode,
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/update`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  delete(id: string, restrictionType: DocumentOrganizationRestrictions, clientMode: ClientModes) {
    const body = {
      documentType: this.documentType,
      restrictionType,
      clientMode,
      id
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/delete`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
