import {Component, OnDestroy, OnInit} from '@angular/core';
import {colsConfig} from './config/cols.config';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {MainFormService} from '../../../../services/main-form.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {RoutesFormComponent} from '../routes-form/routes-form.component';
import {RoutesDatasource} from './routes.datasource';
import {TableComponent} from '../../../../../../shared/classes/table-component';
import {ProposalRoute} from '../../../../../../shared/models/proposal-route';
import {ColConfig} from '../../../../../../shared/interfaces/col-config';
import {ConfigService} from '../../../../../../shared/services/config.service';
import {ProposalRoutesService} from '../../../../../../shared/services/proposal-routes.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {ErrorService} from '../../../../../../shared/services/error.service';
import {ConfirmDialogData} from '../../../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../../../shared/components/confirm-popup/confirm-popup.component';
import {FormModes} from '../../../../../../shared/enums/form-modes';
import {interval} from 'rxjs';

@Component({
  selector: 'app-proposal-form-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent extends TableComponent<ProposalRoute, RoutesDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['sourceName', 'destinationName', 'distance', 'dateTimeIn', 'dateTimeOut', 'options'];
  storageKey = 'app-carrier-proposal-routes';
  dataSource: RoutesDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;

  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalRoutesService,
              private mainFormService: MainFormService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.masterEntityId = data.id;
            this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
          }
        }
      );

    interval(30000)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => this.reload());
  }

  ngOnDestroy() {}

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

  initDataSource() {
    this.dataSource = new RoutesDatasource(this.entityService, this.errorService, this.snackService);
  }

  openDeleteDialog(entityItem: ProposalRoute) {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Удалить выбранный элемент ?',
      btnsArray: [
        {
          message: 'Удалить',
          action: () => {
            dialog.close();
            this.delete(entityItem.id);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  delete(id: string): void {
    this.entityService.delete(id).subscribe(success => {
        this.update();
        this.toastService.success('Объект удален', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  openAddDialog() {
    if (this.dataSource.source.value.length >= 1) {
      const dialogData: ConfirmDialogData = {
        contentMsg: 'Добавление больше 1 маршрута временно не поддерживается',
        btnsArray: [
          {
            message: 'Ок',
            action: () => {
              dialog.close();
            }
          },
        ]
      };
      const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
      return;
    }

    const dialogRef = this.dialog.open(RoutesFormComponent, {
      disableClose: true,
      width: '900px',
      data: {
        masterEntityId: this.masterEntityId,
        docDateEnd: this.mainFormService.tabGeneralForm.controls.docDateEnd.value,
        formMode: FormModes.add,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  openEditDialog(entityItem: ProposalRoute) {
    const dialogRef = this.dialog.open(RoutesFormComponent, {
      disableClose: true,
      width: '900px',
      data: {
        masterEntityId: this.masterEntityId,
        id: entityItem.id,
        docDateEnd: this.mainFormService.tabGeneralForm.controls.docDateEnd.value,
        formMode: FormModes.edit,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  reload() {
    this.update();
  }
}
