import {Model} from './model';

export class ProposalConfirmationCommon extends Model {
  constructor(
    public code?: string,
    public type?: string,
    public name?: string,
    public carrierValue?: any,
    public ownerValue?: any,
  ) {
    super('', name);
  }
}
