export const colsConfig = [
  {
    name: 'Отправление',
    show: true,
    class: 'sourceName',
  },
  {
    name: 'Назначение',
    show: true,
    class: 'destinationName',
  },
  {
    name: 'Расстояние, км',
    show: true,
    class: 'distance',
  },
  {
    name: 'Радиус отправления',
    show: true,
    class: 'sourceRadius',
  },
  {
    name: 'Радиус назначения',
    show: true,
    class: 'destinationRadius',
  },
];
