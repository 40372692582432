import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {OrderFile} from '../models/order-file';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFilesAdapterService implements EntityAdapter<OrderFile> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): OrderFile {
    return new OrderFile(
      item.id,
      item.uuid,
      item.fileName,
      item.masterEntityId,
    );
  }

  adaptOut(item: OrderFile): any {
    return {
      ...item,
    };
  }
}
