import {Model} from './model';

export class DocumentEquipment extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public masterEntityId?: string,
    public equipmentId?: string,
    public price?: number,
    public quantity?: number,
    public ownerConfirmationQuantity?: number,
    public total?: number,
    public totalOwnerConfirmation?: number,
    public description?: string,
  ) {
    super(id, name);
  }
}
