import {Model} from './model';

export class OrderFile extends Model {
  constructor(
    public id?: string,
    public uuid?: string,
    public fileName?: string,
    public masterEntityId?: string,
  ) {
    super(id, fileName);
  }
}
