import { Directive, Input, Self, Optional, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberInput]'
})
export class NumberInputDirective {
  @Input() maxInputLength: number;

  @Input() min: number;

  @Input() max: number;

  @Input() maxValue: number;

  @Input() allowFloat: boolean = false;

  @Input() digitsAfterDot: number;

  allowedKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  constructor(@Self() @Optional() private ngControl: NgControl) { }

  @HostListener('keypress', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    const key = event.keyCode || event.which;
    if (this.maxInputLength && e.srcElement['value'].length === this.maxInputLength && e.srcElement['selectionStart'] === e.srcElement['selectionEnd']) {
      e.preventDefault();
    }
    if (this.digitsAfterDot && e.srcElement['selectionStart'] === e.srcElement['selectionEnd'] &&
      (e.srcElement['value'].indexOf('.') > -1 && e.srcElement['selectionStart'] > e.srcElement['value'].indexOf('.') && e.srcElement['value'].slice(e.srcElement['value'].indexOf('.') + 1).length === this.digitsAfterDot) ||
      (e.srcElement['value'].indexOf(',') > -1 && e.srcElement['selectionStart'] > e.srcElement['value'].indexOf(',') && e.srcElement['value'].slice(e.srcElement['value'].indexOf(',') + 1).length === this.digitsAfterDot)
    ) {
      e.preventDefault();
    }
    if (this.allowFloat) {
      this.allowedKeyCodes.push(44);
      this.allowedKeyCodes.push(46);
    }

    if (this.allowedKeyCodes.indexOf(key) !== -1 ||
      // Allow: Ctrl+A
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (key === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (key === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (key === 88 && (e.ctrlKey || e.metaKey)) ||
      (key === 8) || (key === 9)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event) {
    const e = event as KeyboardEvent;
    const key = event.keyCode || event.which;
    if (this.maxValue && Number(e.target['value']) > this.maxValue) {
      e.target['value'] = this.maxValue;
      if (this.ngControl) {
        this.ngControl.control.setValue(this.maxValue);
      }
    }
    if (this.max && Number(e.target['value']) > this.max) {
      e.target['value'] = this.max;
      if (this.ngControl) {
        this.ngControl.control.setValue('');
      }
    }
    if (this.min && Number(e.target['value']) < this.min) {
      e.target['value'] = this.min;
      if (this.ngControl) {
        this.ngControl.control.setValue('');
      }
    }
  }
}
