import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {TruckType} from '../models/truck-type';

@Injectable({
  providedIn: 'root'
})
export class TruckTypesAdapterService implements EntityAdapter<TruckType> {
  adaptIn(item: any): TruckType {
    return new TruckType(
      item.id,
      item.name,
      item.parent,
      item.parentId,
    );
  }

  adaptOut(item: TruckType): any {
    return {
      ...item
    };
  }
}
