import {Component, Inject, OnInit} from '@angular/core';
import {Organization} from '../../../../../shared/models/organization';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MetaDataCrud} from '../../../../../shared/interfaces/meta-data-crud';
import {metaData} from '../organizations-reg-form/config/meta.config';
import {EntityEditForm} from '../../../../../shared/classes/entity-edit-form';
import {Model} from '../../../../../shared/models/model';
import {FormModes} from '../../../../../shared/enums/form-modes';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {OrganizationsService} from '../../../../../shared/services/organizations.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from 'ngx-webstorage';
import {UserStatuses} from '../../../../../shared/enums/user-statuses';
import { v4 as uuidv4 } from 'uuid';
import {ConfigService} from '../../../../../shared/services/config.service';
import {RegFilesTypes} from '../../../../../shared/enums/reg-files-types';
import {OrganizationRegFile} from '../../../../../shared/models/organizationRegFile';
import {ConfirmDialogData} from '../../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../../shared/components/confirm-popup/confirm-popup.component';
import {User} from '../../../../../shared/models/user';
import {UserRoles} from '../../../../../shared/enums/user-roles';

@Component({
  selector: 'app-organizations-reg-form',
  templateUrl: './organizations-reg-form.component.html',
  styleUrls: ['./organizations-reg-form.component.scss']
})
export class OrganizationsRegFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: Organization;
  entityForm: FormGroup;
  pending: boolean;
  protected metaData: MetaDataCrud[] = metaData;
  ownerShipTypesList: Model[] = [];
  regFilesList: OrganizationRegFile[] = [];
  fileUploadApi = '';
  regFilesTypes = RegFilesTypes;
  user: User;
  userRoles = UserRoles;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              public dialogRef: MatDialogRef<OrganizationsRegFormComponent>,
              private formBuilder: FormBuilder,
              protected entityService: OrganizationsService,
              private storage: LocalStorageService,
              private toastService: ToastrService,
              private snackService: SnackBarService,
              private dialog: MatDialog,
              protected configService: ConfigService,) {
    super();

    this.user = this.storage.retrieve('currentUser');
    this.formMode = this.data.formMode;
    this.fileUploadApi = `${this.configService.apiUrl}/organizations/registration/upload_file`;
  }

  ngOnInit() {
    this.pending = true;

    this.initForm();

    if ([FormModes.edit, FormModes.readOnly].includes(this.formMode)) {
      forkJoin(
        this.entityService.getOne(this.data.id),
        this.entityService.getOwnerShipTypesList(),
        this.entityService.getRegFilesList(this.data.id),
      ).pipe(
        finalize(() => this.pending = false)
      ).subscribe(([entityData, ownerShipTypesData, regFilesData]) => {
        this.entityItem = entityData.list[0];
        this.ownerShipTypesList = ownerShipTypesData.list;
        this.regFilesList = regFilesData.list;

        this.entityForm.patchValue(this.getFormPatchObject());
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      });
    } else {
      this.pending = true;
    }
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  closeForm() {
    this.dialogRef.close();
  }

  save() {
    if (!this.entityForm.valid) {
      return;
    }

    this.pending = true;

    this.metaData.forEach(item => {
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    if (this.formMode === FormModes.edit) {
      this.entityService.registrationCheck(this.entityItem).subscribe(success => {
          this.pending = false;
          this.dialogRef.close({reload: true});
          this.toastService.success('Объект изменен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });

        const currentUser = this.storage.retrieve('currentUser');
        currentUser.status = UserStatuses.STATUS_CHECK;
        this.storage.store('currentUser', currentUser);
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    }
  }

  onFileComplete(event, uuid) {
    const index = this.regFilesList.findIndex(el => el.uuid === uuid);
    if (index !== -1) {
      this.regFilesList[index].fileName = event.data[0].fileName;
    }
  }

  getRegFilesList(type) {
    return this.regFilesList.filter(el => el.fileType === type);
  }

  getRegFileName(uuid) {
    const item = this.regFilesList.find(el => el.uuid === uuid);
    if (item) {
      return item.fileName;
    }
    return null;
  }

  addFileUploadControl(fileType) {
    this.regFilesList.push({
      uuid: uuidv4(),
      fileType,
    })
  }

  deleteFileUploadControl(uuid) {
    const item = this.regFilesList.find(el => el.uuid === uuid);
    if (!item) {
      return;
    }
    if (item.fileName) {
      const dialogData: ConfirmDialogData = {
        contentMsg: 'Удалить файл ?',
        btnsArray: [
          {
            message: 'Удалить',
            action: () => {
              dialog.close();
              this.deleteRegFile(uuid);
            }
          },
          {
            message: 'Отмена',
            action: () => {
              dialog.close();
            }
          },
        ]
      };
      const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
    } else {
      const index = this.regFilesList.findIndex(el => el.uuid === uuid);
      if (index !== -1) {
        this.regFilesList.splice(index, 1);
      }
    }
  }

  deleteRegFile(uuid: string) {
    this.pending = true;

    this.entityService.deleteRegFile(uuid)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          const index = this.regFilesList.findIndex(el => el.uuid === uuid);
          if (index !== -1) {
            this.regFilesList.splice(index, 1);
          }

          this.toastService.success('Документ удален', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  downloadRegFile(uuid) {
    const link = document.createElement('a');
    const params = {
      token: this.storage.retrieve('token'),
      uuid,
    }

    link.download = 'test';
    link.target = 'blank';
    link.href = `${this.configService.apiUrl}/organizations/regFiles/download_file/?${this.encodeQueryData(params)}`;
    link.click();
  }

  encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }

  getFormDataParams(uuid, type) {
    return {
      masterEntityId: this.data.id,
      uuid,
      type,
      token: this.storage.retrieve('token'),
    };
  }

  buttonCheckAvailable() {

  }
}
