import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../../shared/classes/table-data-source';
import {OrderRoute} from '../../../../../../shared/models/order-route';
import {OrderRoutesService} from '../../../../../../shared/services/order-routes.service';
import {ErrorService} from '../../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../../shared/interfaces/entity-data';
import {ClientModes} from '../../../../../../shared/enums/client-modes';

export class RoutesDatasource extends TableDataSource<OrderRoute> {

  constructor(protected entityService: OrderRoutesService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(orderId: string, clientMode: ClientModes, pageIndex: number, pageSize: number) {
    this.setLoading(true);
    this.entityService.getListClientMode(orderId, clientMode, pageIndex + 1, pageSize)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<OrderRoute>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);
        }, err => this.snackService.showErrorMsg(err));
  }
}
