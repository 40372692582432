import {Model} from './model';

export class ProposalRoute extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public proposalId?: string,
    public sourceName?: string,
    public sourceCoordinates?: string,
    public sourceLongitude?: number,
    public sourceLatitude?: number,
    public destinationName?: string,
    public destinationCoordinates?: string,
    public destinationLongitude?: number,
    public destinationLatitude?: number,
    public distance?: number,
    public sourceRadius?: number,
    public destinationRadius?: number,
    public dateOutType?: string,
    public dateOutStart?: Date,
    public dateOutEnd?: Date,
    public dateInType?: string,
    public dateInStart?: Date,
    public dateInEnd?: Date,
  ) {
    super(id, name);
  }
}
