import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Bid} from '../models/bid';
import {BidsAdapterService} from '../adapters/bids-adapter.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {ClientModes} from '../enums/client-modes';
import {DocumentTypes} from '../enums/document-types';

@Injectable({
  providedIn: 'root'
})
export class BidsService extends EntityCrudService<Bid> {
  baseApiPath = 'documents/bids';
  clientMode: ClientModes;
  documentType: DocumentTypes;

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: BidsAdapterService,
              protected errorService: ErrorService,) {
    super(http, configService, entityAdapter, errorService);
  }

  getList(masterEntityId, page = 1, limit = 100): Observable<EntityData<Bid>> {
    const body = {
      clientMode: this.clientMode,
      documentType: this.documentType,
      masterEntityId: masterEntityId ? masterEntityId : 0,
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Bid>))
      );
  }

  bid(masterEntityId: string, bidPrice: number): Observable<EntityData<Bid>> {
    const body = {
      clientMode: this.clientMode,
      documentType: this.documentType,
      masterEntityId,
      bidPrice
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/bid/auction`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Bid>))
      );
  }

  setBidWin(id: string): Observable<EntityData<Bid>> {
    const body = {
      clientMode: this.clientMode,
      documentType: this.documentType,
      id: id ? id : 0
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/win`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Bid>))
      );
  }
}
