import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../../shared/classes/table-data-source';
import {ProposalRoute} from '../../../../../../shared/models/proposal-route';
import {ProposalRoutesService} from '../../../../../../shared/services/proposal-routes.service';
import {ErrorService} from '../../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../../shared/interfaces/entity-data';

export class RoutesDatasource extends TableDataSource<ProposalRoute> {

  constructor(protected entityService: ProposalRoutesService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(proposalId: string, pageIndex: number, pageSize: number) {
    this.setLoading(true);
    this.entityService.getList(proposalId, pageIndex + 1, pageSize)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<ProposalRoute>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);
        }, err => this.snackService.showErrorMsg(err));
  }
}
