import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {OrderDocument} from '../../../../shared/models/order-document';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';
import {Permissions} from '../../../../shared/interfaces/permissions';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {ClientModes} from '../../../../shared/enums/client-modes';
import {ColConfig} from '../../../../shared/interfaces/col-config';

@Component({
  selector: 'app-order-form-tab-general',
  templateUrl: './tab-general.component.html',
  styleUrls: ['./tab-general.component.scss']
})
export class TabGeneralComponent extends EntityEditForm implements OnInit {
  @Input() entityItem: OrderDocument;
  permissions: Permissions;
  documentTypes = DocumentTypes;
  clientModes = ClientModes;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              private validatorsService: CommonValidatorsService,) {
    super();

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.permissions = data.permissions;
          }
        }
      );
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabGeneralForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabGeneralForm = form;
  }

  get docDateStart() {
    return this.componentForm.get('docDateStart');
  }

  get docDateEnd() {
    return this.componentForm.get('docDateEnd');
  }

  get type() {
    return this.componentForm.get('type');
  }

  get step() {
    return this.componentForm.get('step');
  }

  get status() {
    return this.componentForm.get('status');
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      docDate: ['', Validators.required],
      docNumber: ['', Validators.required],
      organizationId: ['', Validators.required],
      type: ['', Validators.required],
      step: ['', Validators.required],
      docDateStart: ['', [Validators.required, this.validatorsService.dateLTCurrentDate]],
      docDateEnd: ['', [Validators.required, this.validatorsService.dateLTCurrentDate]],
      status: ['', Validators.required],
      comment: [''],
      containerNumber: [''],
      isInOperationProgress: [''],
      isInOperationProgressBefore: [''],
    });
  }

  isStatusDisabled() {
    if (this.permissions) {
      if (this.permissions.hasOwnProperty('all') && !this.permissions.all) {
        return true;
      }
      if (this.permissions.hasOwnProperty('update') && !this.permissions.update.fields.status) {
        return true;
      }
    }

    return this.mainFormService.readOnly;
  }

  isTypeDisabled() {
    if (this.permissions) {
      if (this.permissions.hasOwnProperty('all') && !this.permissions.all) {
        return true;
      }
      if (this.permissions.hasOwnProperty('update') && !this.permissions.update.fields.type) {
        return true;
      }
    }

    return this.mainFormService.readOnly;
  }
}
