import {Component, Inject, OnInit} from '@angular/core';
import {DateAdapter, MAT_DIALOG_DATA, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {SnackBarService} from '../../services/snack-bar.service';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {OrganizationsService} from '../../services/organizations.service';
import {Organization} from '../../models/organization';
import {OrganizationRegFile} from '../../models/organizationRegFile';
import {RegFilesTypes} from '../../enums/reg-files-types';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../services/config.service';
import {ErrorService} from '../../services/error.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'signature-example-dialog',
  templateUrl: './signature-example-dialog.component.html',
  styleUrls: ['./signature-example-dialog.component.scss']
})



export class SignatureExampleDialogComponent implements OnInit {
  //protected entityItem: Organization;
  regFilesList: OrganizationRegFile[] = [];
  regFilesTypes = RegFilesTypes;
  pending: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              protected entityService: OrganizationsService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private snackService: SnackBarService,
              private dialogRef: MatDialogRef<SignatureExampleDialogComponent>,
              private storage: LocalStorageService,
              protected configService: ConfigService,
              private errorService: ErrorService,) {
    dateAdapter.setLocale('ru-RU');
  }

  ngOnInit() {
    this.pending = true;

    forkJoin(
      //this.entityService.getOne(this.data.id),
      this.entityService.getRegFilesList(this.data.id),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([regFilesData]) => {
      //this.entityItem = entityData.list[0];
      this.regFilesList = regFilesData.list.filter(el => el.fileType === this.regFilesTypes.type19);
      this.errorService.checkErrors(regFilesData);
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg("Произошла ошибка при загрузке образцов подписей!");
      });
    });
  }

  close() {
    this.pending = false;
    this.dialogRef.close({reload: true});
  }

  getImageSrc(uuid){
    const params = {
      token: this.storage.retrieve('token'),
      uuid,
    }

    return `${this.configService.apiUrl}/organizations/regFiles/download_file?${this.encodeQueryData(params)}`;
  }

  encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }
}
