export const colsConfig = [
  {
    name: 'Наименование',
    show: true,
    class: 'name_',
  },
  {
    name: 'Предложение Перевозчика, от',
    show: true,
    class: 'carrierDateStart',
  },
  {
    name: 'Предложение Перевозчика, до',
    show: true,
    class: 'carrierDateEnd',
  },
  {
    name: 'Потребность Отправителя, от',
    show: true,
    class: 'ownerDateStart',
  },
  {
    name: 'Потребность Отправителя, до',
    show: true,
    class: 'ownerDateEnd',
  },
];
