export const colsConfig = [
  {
    name: 'Наименование',
    show: true,
    class: 'name_',
  },
  {
    name: 'Предложение перевозчика',
    show: true,
    class: 'carrierValue',
  },
  {
    name: 'Потребность отправителя',
    show: true,
    class: 'ownerValue',
  },
];
