import { Component, OnInit } from '@angular/core';
import {MainFormService} from '../../services/main-form.service';

@Component({
  selector: 'app-proposal-form-tab-owners',
  templateUrl: './tab-owners.component.html',
  styleUrls: ['./tab-owners.component.scss']
})
export class TabOwnersComponent implements OnInit {

  constructor(private mainFormService: MainFormService) { }

  ngOnInit() {
  }

}
