import {Model} from './model';
import {ConfigRole} from './config-role';

export class User extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public email?: string,
    public roles?: string[],
    public role?: string,
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public organization?: string,
    public organizationId?: string,
    public phone?: string,
    public password?: string,
    public passwordConfirm?: string,
    public token?: string,
    public status?: string,
    public containerToken?: string,
    public hasContainerToken?: boolean,
    public configRole?: ConfigRole,
  ) {
    super(id, name);
  }
}
