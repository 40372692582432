import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ProposalConfirmationsService} from '../../../../../../../shared/services/proposal-confirmations.service';
import {ProposalConfirmationRoutesPoints} from '../../../../../../../shared/models/proposal-confirmation-routes-points';
import {EntityEditForm} from '../../../../../../../shared/classes/entity-edit-form';
import {SnackBarService} from '../../../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {MetaDataCrud} from '../../../../../../../shared/interfaces/meta-data-crud';
import {CommonValidatorsService} from '../../../../../../../shared/services/common-validators.service';
import {MainFormService} from '../../../../../services/main-form.service';
import ymaps from 'ymaps';
import {ConfirmDialogData} from '../../../../../../../shared/interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../../../../../../shared/components/confirm-popup/confirm-popup.component';
import {ErrDialogComponent} from '../../../../../../../shared/components/err-dialog/err-dialog.component';
(window as any).global = window;

@Component({
  selector: 'app-owner-route-point-edit-form',
  templateUrl: './owner-route-point-edit-form.component.html',
  styleUrls: ['./owner-route-point-edit-form.component.scss']
})
export class OwnerRoutePointEditFormComponent extends EntityEditForm implements OnInit {
  entityForm: FormGroup;
  pending: boolean;
  entityItem: ProposalConfirmationRoutesPoints;
  protected metaData: MetaDataCrud[] = this.getMetaData();

  constructor(private mainFormService: MainFormService,
              protected entityService: ProposalConfirmationsService,
              private validatorsService: CommonValidatorsService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data,
              private dialogRef: MatDialogRef<OwnerRoutePointEditFormComponent>,
              private formBuilder: FormBuilder) {
    super();
  }

  get carrierRoute() {
    return this.entityForm.get('carrierRoute');
  }

  get ownerRoute() {
    return this.entityForm.get('ownerRoute');
  }

  ngOnInit() {
    this.initForm();

    this.entityForm.patchValue(this.data);

    const me = this;

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90').then(maps => {
      const ownerRouteSV = new maps.SuggestView('ownerRoute');

      if (!this.data.carrierCoordinates) {
        this.data.carrierCoordinates = '55.7558,37.6173';
      }

      const myMap = new maps.Map('map', {
          center: this.data.carrierCoordinates.split(','),
          zoom: 5,
          controls: []
        }),
        // Создадим панель маршрутизации.
        routePanelControl = new maps.control.RoutePanel({
          options: {
            // Добавим заголовок панели.
            showHeader: true,
            visible: false,
            title: 'Выберите назначение'
          }
        }),
        zoomControl = new maps.control.ZoomControl({
          options: {
            size: 'small',
            float: 'none',
            position: {
              bottom: 145,
              right: 10
            }
          }
        });

      // Пользователь сможет построить только автомобильный маршрут.
      routePanelControl.routePanel.options.set({
        types: {auto: true}
      });

      if (me.entityForm.controls.carrierRoute.value) {
        routePanelControl.routePanel.state.set({
          fromEnabled: false,
          from: me.entityForm.controls.carrierRoute.value
        });
      }

      if (me.entityForm.controls.ownerRoute.value) {
        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: me.entityForm.controls.ownerRoute.value
        });
      }

      ownerRouteSV.events.add('select', (event) => {
        me.entityForm.controls.ownerRoute.setValue(event.originalEvent.item.value);
        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: event.originalEvent.item.value
        });
      });

      myMap.controls.add(routePanelControl).add(zoomControl);

      if (me.entityForm.controls.radius.value) {
        // Создаем круг.
        const myCircle = new maps.Circle([
          // Координаты центра круга.
          me.data.carrierCoordinates.split(',').reverse(),
          // Радиус круга в метрах.
          me.entityForm.controls.radius.value * 1000
        ], {
          // Описываем свойства круга.
          // Содержимое балуна.
          balloonContent: 'Радиус круга - ' + me.entityForm.controls.radius.value + ' км',
          // Содержимое хинта.
          hintContent: ''
        }, {
          // Задаем опции круга.
          // не Включаем возможность перетаскивания круга.
          draggable: false,
          // Цвет заливки.
          // Последний байт (77) определяет прозрачность.
          // Прозрачность заливки также можно задать используя опцию "fillOpacity".
          fillColor: "#DB709377",
          // Цвет обводки.
          strokeColor: "#990066",
          // Прозрачность обводки.
          strokeOpacity: 0.8,
          // Ширина обводки в пикселях.
          strokeWidth: 3
        });

        // Добавляем круг на карту.
        myMap.geoObjects.add(myCircle);
      }

      // Получим ссылку на маршрут.
      routePanelControl.routePanel.getRouteAsync().then(function (route) {
        // Зададим максимально допустимое число маршрутов, возвращаемых мультимаршрутизатором.
        route.model.setParams({results: 1}, true);

        // Повесим обработчик на событие построения маршрута.
        route.model.events.add('requestsuccess', function (event) {
          const wayPoints = route.model.properties.get('waypoints');

          if (wayPoints.length > 1) {
            me.entityForm.controls.ownerRoute.setValue(wayPoints[wayPoints.length - 1].address);
          } else {
            me.entityForm.controls.ownerRoute.setValue('');
          }

          if (wayPoints.length > 1 && me.entityForm.controls.radius.value) {
            const activeRoute = route.getActiveRoute();
            if (activeRoute) {
              // Получим протяженность маршрута.
              const distanceProperty = route.getActiveRoute().properties.get('distance'),
                distance = (Math.round(distanceProperty.value / 1000)).toFixed(0);

              me.entityForm.controls.shortestDistance.setValue(distance);

              if (distance > me.entityForm.controls.radius.value) {
                const dialogData: ConfirmDialogData = {
                  contentMsg: 'Расстояние до точки назначения превышает радиус',
                  btnsArray: [
                    {
                      message: 'Ок',
                      action: () => {
                        dialog.close();
                      }
                    },
                  ]
                };
                const dialog = me.dialog.open(ConfirmPopupComponent, { data: dialogData });
              } else {
                //maps.formatter.distance(distance)
                // Создадим макет содержимого балуна маршрута.
                const balloonContentLayout = maps.templateLayoutFactory.createClass(
                  '<span>Расстояние: ' + distance + ' км</span><br/>');
                // Зададим этот макет для содержимого балуна.
                route.options.set('routeBalloonContentLayout', balloonContentLayout);
                // Откроем балун.
                activeRoute.balloon.open();
              }
            }
          }
        });
      });
    }).catch(error => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Ошибка загрузки Яндекс карт'
        }
      });

      console.dir(error);
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject(), {
      validator: this.validatorsService.routesMatch
    });
  }

  getMetaData() {
    return [
      {
        entityField: 'id',
        formField: 'id',
      },
      {
        entityField: 'code',
        formField: 'code',
      },
      {
        entityField: 'carrierRoute',
        formField: 'carrierRoute',
      },
      {
        entityField: 'carrierCoordinates',
        formField: 'carrierCoordinates',
      },
      {
        entityField: 'radius',
        formField: 'radius',
      },
      {
        entityField: 'shortestDistance',
        formField: 'shortestDistance',
      },
      {
        entityField: 'ownerRoute',
        formField: 'ownerRoute',
      },
    ];
  }

  save() {
    /*
    if (!this.entityForm.valid) {
      return;
    }
     */

    if (this.mainFormService.confirmationReadOnly) {
      return;
    }

    if (this.entityForm.controls.shortestDistance.value > this.entityForm.controls.radius.value) {
      const dialogData: ConfirmDialogData = {
        contentMsg: 'Расстояние до точки назначения превышает радиус',
        btnsArray: [
          {
            message: 'Ок',
            action: () => {
              dialog.close();
            }
          },
        ]
      };
      const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });

      return;
    }

    this.pending = true;

    this.entityItem = new ProposalConfirmationRoutesPoints();
    this.metaData.forEach(item => {
      if (item.skip) {
        return;
      }
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    this.entityService.updateOwnerRoutePoint(this.data.entityId, this.entityItem).subscribe(success => {
        this.pending = false;
        this.dialogRef.close({reload: true});
        this.toastService.success('Объект изменен', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }
}
