import {Model} from './model';

export class Bid extends Model {
  constructor(
    public id?: string,
    public masterEntityId?: string,
    public organizationId?: string,
    public organization?: string,
    public userId?: string,
    public createdDate?: Date,
    public price?: number,
    public isWin?: boolean,
  ) {
    super(id, '');
  }
}
