export const colsConfig = [
  {
    name: 'Отправление',
    show: true,
    class: 'sourceName',
  },
  {
    name: 'Назначение',
    show: true,
    class: 'destinationName',
  },
  {
    name: 'Расстояние, км',
    show: false,
    class: 'distance',
  },
  {
    name: 'Дата и время погрузки',
    show: true,
    class: 'dateTimeIn',
  },
  {
    name: 'Дата и время выгрузки',
    show: true,
    class: 'dateTimeOut',
  },
];
