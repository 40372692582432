export const colsConfig = [
  {
    name: 'Организация',
    show: true,
    class: 'organization',
  },
  {
    name: 'Дата и время',
    show: true,
    class: 'createdDate',
  },
  {
    name: 'Цена',
    show: true,
    class: 'price',
  },
];
