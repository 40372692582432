import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { EntityEditForm } from '../../../../../../shared/classes/entity-edit-form';
import { OrderRoute } from '../../../../../../shared/models/order-route';
import { MetaDataCrud } from '../../../../../../shared/interfaces/meta-data-crud';
import { OrderRoutesService } from '../../../../../../shared/services/order-routes.service';
import { CommonValidatorsService } from '../../../../../../shared/services/common-validators.service';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { RoutesUtilitiesService } from '../../../../../../shared/services/routes-utilities.service';
import ymaps from 'ymaps';
import { FormModes } from '../../../../../../shared/enums/form-modes';
import { ErrDialogComponent } from '../../../../../../shared/components/err-dialog/err-dialog.component';
(window as any).global = window;
import { MainFormService } from '../../../../services/main-form.service';
import { DocumentTypes } from '../../../../../../shared/enums/document-types';

@Component({
  selector: 'app-order-form-routes-trunk-form',
  templateUrl: './routes-trunk-form.component.html',
  styleUrls: ['./routes-trunk-form.component.scss']
})
export class RoutesTrunkFormComponent implements OnInit {
  entityForm: FormGroup;
  pending: boolean;
  protected metaData: MetaDataCrud[] = this.getMetaData();
  protected formMode: FormModes;
  formModes = FormModes;
  documentTypes = DocumentTypes;
  // sourceAddressLine: string;
  // destinationAddressLine: string;
  maps: any;
  myMap: any;

  //@ViewChild('timeInPicker', { read: NgxTimepickerFieldComponent, static: true }) timeInPicker: NgxTimepickerFieldComponent;
  //@ViewChild('timeOutPicker', { read: NgxTimepickerFieldComponent, static: true }) timeOutPicker: NgxTimepickerFieldComponent;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    protected entityService: OrderRoutesService,
    private validatorsService: CommonValidatorsService,
    private routesUtilitiesService: RoutesUtilitiesService,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private snackService: SnackBarService,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private mainFormService: MainFormService,
    private dialogRef: MatDialogRef<RoutesTrunkFormComponent>) {
    dateAdapter.setLocale('ru-RU');
  }

  get sourceName() {
    return this.entityForm.get('sourceName');
  }

  get sourceNameShort() {
    return this.entityForm.get('sourceNameShort');
  }

  get sourceLongitude() {
    return this.entityForm.get('sourceLongitude');
  }

  get sourceLatitude() {
    return this.entityForm.get('sourceLatitude');
  }

  get destinationName() {
    return this.entityForm.get('destinationName');
  }

  get destinationNameShort() {
    return this.entityForm.get('destinationNameShort');
  }

  get destinationLongitude() {
    return this.entityForm.get('destinationLongitude');
  }

  get destinationLatitude() {
    return this.entityForm.get('destinationLatitude');
  }

  get distance() {
    return this.entityForm.get('distance');
  }

  get dateIn() {
    return this.entityForm.get('dateIn');
  }

  get timeIn(){
    return this.entityForm.get('timeIn');
  }

  get timeOut() {
    return this.entityForm.get('timeOut');
  }

  get dateOut() {
    return this.entityForm.get('dateOut');
  }

  get contactsOutPerson() {
    return this.entityForm.get('contactsOutPerson');
  }

  get contactsInPerson() {
    return this.entityForm.get('contactsInPerson');
  }

  get contactsInPhone1() {
    return this.entityForm.get('contactsInPhone1');
  }

  get contactsInPhone2() {
    return this.entityForm.get('contactsInPhone2');
  }

  get contactsOutPhone1() {
    return this.entityForm.get('contactsOutPhone1');
  }

  get contactsOutPhone2() {
    return this.entityForm.get('contactsOutPhone2');
  }

  get normativeInTime() {
    return this.entityForm.get('normativeInTime');
  }

  get normativeOutTime() {
    return this.entityForm.get('normativeOutTime');
  }

  waypoints(): FormArray {
    return this.entityForm.get("waypoints") as FormArray
  }

  getMetaData() {
    let cntrlsCfg = [
      {
        entityField: 'sourceName',
        formField: 'sourceName',
        validators: [Validators.required]
      },
      {
        entityField: 'sourceNameShort',
        formField: 'sourceNameShort',
      },
      {
        entityField: 'sourceLongitude',
        formField: 'sourceLongitude',
      },
      {
        entityField: 'sourceLatitude',
        formField: 'sourceLatitude',
      },
      {
        entityField: 'destinationName',
        formField: 'destinationName',
        validators: [Validators.required]
      },
      {
        entityField: 'destinationNameShort',
        formField: 'destinationNameShort',
      },
      {
        entityField: 'destinationLongitude',
        formField: 'destinationLongitude',
      },
      {
        entityField: 'destinationLatitude',
        formField: 'destinationLatitude',
      },
      {
        entityField: 'dateIn',
        formField: 'dateIn',
        validators: [Validators.required]
      },
      {
        entityField: 'timeIn',
        formField: 'timeIn',
      },
      {
        entityField: 'dateOut',
        formField: 'dateOut',
        validators: [Validators.required]
      },
      {
        entityField: 'timeOut',
        formField: 'timeOut',
      },
      {
        entityField: 'distance',
        formField: 'distance',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPhone1',
        formField: 'contactsInPhone1',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPhone2',
        formField: 'contactsInPhone2',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPerson',
        formField: 'contactsInPerson',
        validators: [Validators.required]
      },
      {
        entityField: 'normativeInTime',
        formField: 'normativeInTime',
      },
      {
        entityField: 'contactsOutPhone1',
        formField: 'contactsOutPhone1',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsOutPhone2',
        formField: 'contactsOutPhone2',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsOutPerson',
        formField: 'contactsOutPerson',
        validators: [Validators.required]
      },
      {
        entityField: 'normativeOutTime',
        formField: 'normativeOutTime',
      },
    ];

    return cntrlsCfg;
  }

  ngOnInit() {
    this.initForm();

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90').then(maps => {
      this.maps = maps;
      if (this.data.formMode === FormModes.add) {
        this.loadYmaps();
      }
    }).catch(error => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Ошибка загрузки Яндекс карт'
        }
      });
      console.dir(error);
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  getFormInitObject() {
    const initObject = {};
    this.metaData.forEach(item => {
      initObject[item.formField] = [];
      if (item.value) {
        initObject[item.formField].push(item.value);
      }
      if (item.validators) {
        initObject[item.formField].push('');
        initObject[item.formField].push(item.validators);
      }
    });

    initObject["waypoints"] = this.formBuilder.array([]);

    return initObject;
  }

  newWaypoint(): FormGroup {
    let group = this.formBuilder.group(new Waypoint());
    group.controls['name'].setValidators([Validators.required]);
    group.controls['contactsPhone1'].setValidators([Validators.required]);
    group.controls['contactsPerson'].setValidators([Validators.required]);
    return group;
  }

  addWaypoint() {
    if (this.waypoints().length > 10) {
      return;
    }

    this.waypoints().push(this.newWaypoint());
    setTimeout(_ => {
      const cntrlSV = new this.maps.SuggestView('p' + (this.waypoints().length - 1) + 'Name', { results: 2 });
      let cntrls = (this.waypoints().controls[this.waypoints().length - 1] as any).controls;

      cntrlSV.events.add('select', (event) => {
        this.updatePoint(cntrls.name, cntrls.nameShort, cntrls.latitude, cntrls.longitude, event.originalEvent.item.value);
      });
    }, 500);
  }

  removeWaypoint(i: number) {
    this.waypoints().removeAt(i);
    this.refreshRoute();
  }

  updatePoint(cntrlName: AbstractControl, cntrlShortName: AbstractControl, cntrlLatitude: AbstractControl, cntrlLongitude: AbstractControl, newValue: string) {
    let that = this;
    cntrlName.setValue(newValue);
    cntrlLatitude.setValue('');
    cntrlLongitude.setValue('');

    let timerId = setTimeout(_ => this.pending = true, 300);

    // Ищем координаты указанного адреса
    // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
    const geocoder = that.maps.geocode(newValue);

    // После того, как поиск вернул результат, вызывается callback-функция
    geocoder.then(function (res) {
      // координаты объекта
      // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
      let geoObject = res.geoObjects.get(0);

      let addressShort = [geoObject.getCountry(),
      geoObject.getAdministrativeAreas().join(', '),
      geoObject.getLocalities().join(', ')];

      cntrlLongitude.setValue(geoObject.geometry.getCoordinates()[0]);
      cntrlLatitude.setValue(geoObject.geometry.getCoordinates()[1]);
      cntrlShortName.setValue(addressShort.join(', '));

      //that[cntrlKey+'AddressLine'] = geoObject.getAddressLine();
      that.refreshRoute();

      clearTimeout(timerId);
      timerId = null;
      that.pending = false;
    }
    );
  };

  refreshRoute() {
    let referencePoints = [];

    if (this.entityForm.controls.sourceName.value) {
      referencePoints.push([this.entityForm.controls.sourceLongitude.value, this.entityForm.controls.sourceLatitude.value]);
    }

    this.waypoints() && this.waypoints().value.forEach(i => {
      if (i.name) {
        referencePoints.push([i.longitude, i.latitude]);
      }
    });

    if (this.entityForm.controls.destinationName.value) {
      referencePoints.push([this.entityForm.controls.destinationLongitude.value, this.entityForm.controls.destinationLatitude.value]);
    }

    // Построение маршрута.
    // По умолчанию строится автомобильный маршрут.
    var multiRoute = new this.maps.multiRouter.MultiRoute({
      // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
      referencePoints: referencePoints, params: {
        results: 1
      }
    }, {
      // Автоматически устанавливать границы карты так,
      // чтобы маршрут был виден целиком.
      boundsAutoApply: true,

    });

    this.myMap.geoObjects.removeAll()
    // Добавление маршрута на карту.
    this.myMap.geoObjects.add(multiRoute);

    let that = this;
    multiRoute.events.add('update', (event) => {
      let activeRoute = multiRoute.getActiveRoute();
      that.entityForm.controls.distance.setValue(0);

      if (activeRoute) {
        const distanceProperty = activeRoute.properties.get('distance'),
          distance = (Math.round(distanceProperty.value / 1000 * 100) / 100).toFixed(0);

        that.entityForm.controls.distance.setValue(distance);
      }
    });
  };

  loadYmaps() {
    const me = this;
    this.myMap = new this.maps.Map('map', {
      center: [60.906882, 30.067233],
      zoom: 9,
      controls: []
    });

    this.refreshRoute();

    const sourceNameSV = new this.maps.SuggestView('sourceName', { results: 2 });
    const destinationNameSV = new this.maps.SuggestView('destinationName', { results: 2 });

    sourceNameSV.events.add('select', (event) => {
      this.updatePoint(this.sourceName, this.sourceNameShort, this.sourceLatitude, this.sourceLongitude, event.originalEvent.item.value);
    });

    destinationNameSV.events.add('select', (event) => {
      this.updatePoint(this.destinationName, this.destinationNameShort, this.destinationLatitude, this.destinationLongitude, event.originalEvent.item.value);
    });
  }

  async save() {

    if (this.data.formMode !== FormModes.add) {
      this.pending = false;
      this.dialogRef.close({ reload: false });
      return;
    }

    const errMsg = [];

    if (!this.entityForm.controls.dateIn.value) {
      errMsg.push('Дата погрузки');
    }

    if (!this.entityForm.controls.contactsInPhone1.value || !this.entityForm.controls.contactsInPhone1.valid) {
      errMsg.push('Данные погрузки, телефон №1');
    }

    if (!this.entityForm.controls.contactsInPhone2.value || !this.entityForm.controls.contactsInPhone2.valid) {
      errMsg.push('Данные погрузки, телефон №2');
    }

    if (!this.entityForm.controls.contactsInPerson.value) {
      errMsg.push('Данные погрузки, контакты');
    }

    if (this.waypoints()) {
      for (let i = 0; i < this.waypoints().controls.length; i++) {
        let cntrls = (this.waypoints().controls[i] as any).controls;
        if (!cntrls.date.value) {
          errMsg.push('Дата промежуточной точки ' + (i + 1));
        }
        if (!cntrls.contactsPhone1.value || !cntrls.contactsPhone1.valid) {
          errMsg.push('Данные промежуточной точки ' + (i + 1) + ', телефон №1');
        }
        if (!cntrls.contactsPerson.value || !cntrls.contactsPerson.valid) {
          errMsg.push('Данные промежуточной точки ' + (i + 1) + ', контакты');
        }
      }
    }

    if (!this.entityForm.controls.dateOut.value) {
      errMsg.push('Дата выгрузки');
    }

    if (!this.entityForm.controls.contactsOutPhone1.value || !this.entityForm.controls.contactsOutPhone1.valid) {
      errMsg.push('Данные выгрузки, телефон №1');
    }

    if (!this.entityForm.controls.contactsOutPhone2.value || !this.entityForm.controls.contactsOutPhone2.valid) {
      errMsg.push('Данные выгрузки, телефон №2');
    }

    if (!this.entityForm.controls.contactsOutPerson.value) {
      errMsg.push('Данные выгрузки, контакты');
    }

    if (errMsg.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errMsg
      });

      return;
    }

    if (!this.entityForm.valid) {
      Object.keys(this.entityForm.controls).forEach(field => {
        const control = this.entityForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      return;
    }

    this.pending = true;

    // this.entityForm.controls.sourceName.setValue(this.sourceAddressLine);
    // this.entityForm.controls.destinationName.setValue(this.destinationAddressLine);

    let geoErrors = [];

    if (!this.entityForm.controls.sourceLatitude.value || !this.entityForm.controls.sourceLongitude.value) {
      geoErrors.push('Не удалось определить координаты пункта отправления');
    }

    if (this.waypoints()) {
      for (let i = 0; i < this.waypoints().controls.length; i++) {
        let cntrls = (this.waypoints().controls[i] as any).controls;
        if (!cntrls.latitude.value || !cntrls.longitude.value) {
          geoErrors.push('Не удалось определить координаты промежуточной точки ' + (i + 1));
        }
      }
    }

    if (!this.entityForm.controls.destinationLatitude.value || !this.entityForm.controls.destinationLongitude.value) {
      geoErrors.push('Не удалось определить координаты пункта назначенния');
    }

    if (geoErrors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: geoErrors
      });

      this.pending = false;
      return;
    }

    let allWaypoints: Waypoint[] = [];

    allWaypoints.push(new Waypoint(this.sourceName.value, this.sourceNameShort.value, this.sourceLongitude.value,
      this.sourceLatitude.value, this.dateIn.value, this.timeIn.value, this.distance.value,
      this.contactsInPhone1.value, this.contactsInPhone2.value, this.contactsInPerson.value, this.normativeInTime.value));

    if (this.waypoints()) {
      this.waypoints().value.forEach((i: Waypoint) => { allWaypoints.push(i) });
    }

    allWaypoints.push(new Waypoint(this.destinationName.value, this.destinationNameShort.value, this.destinationLongitude.value,
      this.destinationLatitude.value, this.dateOut.value, this.timeOut.value, this.distance.value,
      this.contactsOutPhone1.value, this.contactsOutPhone2.value, this.contactsOutPerson.value, this.normativeOutTime.value));

    let entityItems: OrderRoute[] = [];

    for (let i = 0; i < allWaypoints.length - 1; i++) {
      if (allWaypoints[i].date > allWaypoints[i + 1].date) {
        const dialogRef = this.dialog.open(ErrDialogComponent, {
          data: ['Дата ' + (i == 0 ? 'погрузки' : 'промежуточной точки ' + i) + ' больше даты следующей точки!']
        });
        this.pending = false;
        return;
      }

      var timeIn = allWaypoints[i].time ? (allWaypoints[i].time.indexOf(':') > 0 ? allWaypoints[i].time : allWaypoints[i].time + ':00') : "";
      var timeOut = allWaypoints[i + 1].time ? (allWaypoints[i + 1].time.indexOf(':') > 0 ? allWaypoints[i + 1].time : allWaypoints[i + 1].time + ':00') : "";
      let entityItem = new OrderRoute(null, allWaypoints[i].name, this.data.masterEntityId,
        allWaypoints[i].name, allWaypoints[i].nameShort, allWaypoints[i].longitude, allWaypoints[i].latitude,
        allWaypoints[i + 1].name, allWaypoints[i + 1].nameShort, allWaypoints[i + 1].longitude, allWaypoints[i + 1].latitude,
        allWaypoints[i].date, timeIn, timeIn ? timeIn.split(':')[0] : null, timeIn ?timeIn.split(':')[1] : null,
        allWaypoints[i + 1].date, timeOut, timeOut ? timeOut.split(':')[0] : null, timeOut ? timeOut.split(':')[1] : null,
        allWaypoints[i].distance,
        allWaypoints[i].contactsPhone1, allWaypoints[i].contactsPhone2, allWaypoints[i].contactsPerson,
        allWaypoints[i + 1].contactsPhone1, allWaypoints[i + 1].contactsPhone2, allWaypoints[i + 1].contactsPerson,
        allWaypoints[i].normativeTime,
      );
      entityItems.push(entityItem);
    }

    this.entityService.addRoutes(entityItems, this.data.docDateEnd, this.data.masterEntityId).subscribe(success => {
      this.pending = false;
      this.dialogRef.close({ reload: true });
      this.toastService.success('Объект добавлен', '', {
        closeButton: true,
        timeOut: 3000,
        toastClass: 'toast custom-style',
      });
    }, errors => {
      this.pending = false;
      errors.map(error => {
        this.snackService.showErrorMsg(error);
      });
    }, () => {
      this.pending = false;
    });
  }

  onPointNameChange(i: number) {
    let cntrls = (this.waypoints().controls[i] as any).controls;
    cntrls.latitude.setValue('');
    cntrls.longitude.setValue('');
    this.resetFocus();
  }

  onSourceNameChange() {
    this.entityForm.controls.sourceLatitude.setValue('');
    this.entityForm.controls.sourceLongitude.setValue('');
    this.resetFocus();
  }

  onDestinationNameChange() {
    this.entityForm.controls.destinationLatitude.setValue('');
    this.entityForm.controls.destinationLongitude.setValue('');
    this.resetFocus();
  }

  resetFocus() {
    setTimeout(() => {
      let el = document.getElementById('map');
      el.focus();
    }, 500);
  }

  onTimeWheel(e, target){
    var value = target.value;
    if(!value || value.indexOf(':') === -1)
    {
      value = '00:00';
    }

    var h_t = value.split(':');
    if (e.deltaY > 0){
      if(+h_t[1] >= 50){
        h_t[0] = +h_t[0] + 1;
        h_t[1] = 0;
      }
      else{
        h_t[1] = +h_t[1] + 10;
      }
      if(h_t[0]>23)
      {
        h_t[0] = 0;
        h_t[1] = 0;
      }
    }
    if (e.deltaY < 0){
      if(+h_t[1] <= 9){
        h_t[0] = +h_t[0] - 1;
        h_t[1] = 50;
      }
      else{
        h_t[1] = +h_t[1] -10;
      }
      if(h_t[0]<0)
      {
        h_t[0] = 23;
        h_t[1] = 50;
      }
    }

    h_t = h_t.map(i=>('' + i).padStart(2, '0'));

    target.setValue(h_t.join(':'));
    e.preventDefault();
    e.stopPropagation();
  }

  onTimeChange(target){
    var value = target.value;
    if(!value) return;
    var h_t = value.split(':');
    if(h_t.length === 1)
    {
      h_t.push('00');
    }
    h_t = h_t.map(i=>('' + i).padStart(2, '0'));
    target.setValue(h_t.join(':'));
  }
}

class Waypoint {
  constructor(
    public name?: string,
    public nameShort?: string,
    public longitude?: number,
    public latitude?: number,
    public date?: Date,
    public time?: string,
    public distance?: number,
    public contactsPhone1?: string,
    public contactsPhone2?: string,
    public contactsPerson?: string,
    public normativeTime?: string,
  ) {
  }
}
