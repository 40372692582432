import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../services/config.service';
import {ErrorService} from '../services/error.service';
import {EntityAdapter} from '../interfaces/entity-adapter';

export abstract class EntityCrudService<T> {
  abstract baseApiPath: string;

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: EntityAdapter<T>,
              protected errorService: ErrorService) { }

  getList(page = 1, limit = 100): Observable<EntityData<T>> {
    const body = {
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<T>))
      );
  }

  getOne(id: string): Observable<EntityData<T>> {
    const body = {
      id
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/one`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<T>))
      );
  }

  delete(id: string) {
    const body = {
      id
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/delete`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  add(entityItem: T) {
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/add`, this.entityAdapter.adaptOut(entityItem))
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  update(entityItem: T) {
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/update`, this.entityAdapter.adaptOut(entityItem))
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
