import {NativeDateAdapter} from '@angular/material';
import * as moment from 'moment';

export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 4;
  }

  parse(value: any): Date | null {
    const date = moment(value, 'DD.MM.YYYY');
    return date.isValid() ? date.toDate() : null;
  }
}

