import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../classes/table-data-source';
import {DocumentOrganization} from '../../../models/document-organization';
import {ErrorService} from '../../../services/error.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {EntityData} from '../../../interfaces/entity-data';
import {RestrictedOrganizationsService} from '../../../services/restricted-organizations.service';
import {DocumentOrganizationRestrictions} from '../../../enums/document-organization-restrictions';

export class RestrictedOrganizationsDatasource extends TableDataSource<DocumentOrganization> {
  restrictionType: DocumentOrganizationRestrictions;

  constructor(protected entityService: RestrictedOrganizationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              restrictionType: DocumentOrganizationRestrictions) {
    super();
    this.restrictionType = restrictionType;
  }

  loadList(masterEntityId: string, pageIndex: number, pageSize: number) {
    this.setLoading(true);
    this.entityService.getList(masterEntityId, this.restrictionType, pageIndex + 1, pageSize)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<DocumentOrganization>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);
        }, err => this.snackService.showErrorMsg(err));
  }
}
