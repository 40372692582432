import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private errorCodes: any = [];

  private getErrorMessageByCode(code) {
    if (this.errorCodes) {
      const elem = this.errorCodes.find(element => element.MessageCode === code);
      return elem ? (elem.MessageDescriptionUA ? elem.MessageDescriptionUA : elem.MessageText) : '';
    }
  }

  checkErrors(data: any): any {
    if (data.success === false) {
      const errors: string[] = [];
      if (errors.length === 0 && data.errors.length) {
        data.errors.forEach((item: string) => {
          errors.push(item);
        });
      }
      throw errors;
    }
    return data;
  }
}
