import {Injectable} from '@angular/core';
import {FilterAdminDriversModel} from '../interfaces/filter-admin-drivers.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DriversFilterAdapterService {

  adaptOut(item: FilterAdminDriversModel): any {
    if (!item) {
      return {};
    }

    return {
      ...item,
      birthDate: item.birthDate ? moment(item.birthDate).format('DD.MM.YYYY') : null,
    };
  }
}
