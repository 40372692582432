import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {Organization} from '../../../../shared/models/organization';
import {OrganizationsService} from '../../../../shared/services/organizations.service';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {LocalStorageService} from 'ngx-webstorage';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {DadataService} from '../../../../shared/services/dadata.service';


@Component({
  selector: 'app-order-form-tab-counterparties',
  templateUrl: './tab-counterparties.component.html',
  styleUrls: ['./tab-counterparties.component.scss']
})
export class TabCounterpartiesComponent implements OnInit {
  documentTypes = DocumentTypes;
  current: {
    org:Organization
  };

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              protected organizationsService: OrganizationsService,
              protected storage: LocalStorageService,
              private snackService: SnackBarService,
              protected dadataService: DadataService) {
  }

  ngOnInit() {
    this.initForm();

    const currentUser = this.storage.retrieve('currentUser');
    this.organizationsService.getOne(currentUser.organizationId).pipe()
      .subscribe((orgData) => {
        this.current.org = orgData.list[0];
    });
  }
  
  get senderName() {
    return this.componentForm.get('senderName');
  }
  get senderInn() {
    return this.componentForm.get('senderInn');
  }
  get senderAddress() {
    return this.componentForm.get('senderAddress');
  }

  get recipientName() {
    return this.componentForm.get('recipientName');
  }
  get recipientInn() {
    return this.componentForm.get('recipientInn');
  }
  get recipientAddress() {
    return this.componentForm.get('recipientAddress');
  }

  get payerName() {
    return this.componentForm.get('payerName');
  }
  get payerInn() {
    return this.componentForm.get('payerInn');
  }
  get payerAddress() {
    return this.componentForm.get('payerAddress');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabCounterpartiesForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabCounterpartiesForm = form;
  }

  initForm() {
    this.current = { org: undefined };
    let cntrlsCfg = {
      senderName: ['', Validators.required],
      senderInn: ['', Validators.required],
      senderAddress: ['', Validators.required],
      recipientName: ['', Validators.required],
      recipientInn: [''],
      recipientAddress: ['', Validators.required],
      payerName: ['', Validators.required],
      payerInn: ['', Validators.required],
      payerAddress: ['', Validators.required],
    };
    if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
      cntrlsCfg = {...cntrlsCfg, ...{
        recipientInn: [''],
      }};
    }
    
    this.componentForm = this.formBuilder.group(cntrlsCfg);
  }

  setSenderToCurrentOrganization() {
    this.senderName.setValue(this.current.org.name);
    this.senderInn.setValue(this.current.org.inn);
    this.senderAddress.setValue(this.current.org.jurAddress);
  }

  setRecipientToCurrentOrganization() {
    this.recipientName.setValue(this.current.org.name);
    this.recipientInn.setValue(this.current.org.inn);
    this.recipientAddress.setValue(this.current.org.jurAddress);
  }

  setPayerToCurrentOrganization() {
    this.payerName.setValue(this.current.org.name);
    this.payerInn.setValue(this.current.org.inn);
    this.payerAddress.setValue(this.current.org.jurAddress);
  }

  setPayerByINN() {
    this.getOrganization(this.payerInn, this.payerName, this.payerAddress);
  }

  setRecipientByINN(){
    this.getOrganization(this.recipientInn, this.recipientName, this.recipientAddress);
  }

  setSenderByINN(){
    this.getOrganization(this.senderInn, this.senderName, this.senderAddress);
  }
  

  async getOrganization(inputInn: any, inputName: any, inputAddress: any)
  {
    this.dadataService.getOrganization(inputInn.value)
    .then(result => {
      if (!result || !result.suggestions || result.suggestions.length < 1)
      {
        this.snackService.showWarningMsg('Организация по указанному ИНН не найдена!');
        return;
      }      
      inputName.setValue(result.suggestions[0].value);
      inputAddress.setValue(result.suggestions[0].data.address.unrestricted_value);
      return result.suggestions[0];
    })
    .catch(error => {
      this.snackService.showErrorMsg('При проверке ИНН произошла ошибка!'); 
      console.error(error)
    });
  }
}
