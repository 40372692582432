import { Injectable } from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {CargoType} from '../models/cargo-type';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {CargoTypesAdapterService} from '../adapters/cargo-types-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class CargoTypesService extends EntityCrudService<CargoType>{
  baseApiPath = 'cargo_types';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: CargoTypesAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }
}
