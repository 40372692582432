import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {OrderDocument} from '../models/order-document';
import {OrderDocumentAdapterService} from '../adapters/order-document-adapter.service';
import {Driver} from '../models/driver';
import {Truck} from '../models/truck';
import {DriversAdapterService} from '../adapters/drivers-adapter.service';
import {TrucksAdapterService} from '../adapters/trucks-adapter.service';
import {Model} from '../models/model';
import {ModelAdapterService} from '../adapters/model-adapter.service';
import {ClientModes} from '../enums/client-modes';
import {ConfirmationFileTypes} from '../enums/confirmation-file-types';
import {OrderDocumentCommonListModel} from '../models/order-document-common-list.model';
import {DocumentTypes} from '../enums/document-types';

@Injectable({
  providedIn: 'root'
})
export class OrderDocumentsService extends EntityCrudService<OrderDocument> {
  baseApiPath = 'owner/order';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: OrderDocumentAdapterService,
              protected driverAdapater: DriversAdapterService,
              protected truckAdapater: TrucksAdapterService,
              protected modelAdapater: ModelAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getOneClientMode(id: string, clientMode: ClientModes): Observable<EntityData<OrderDocument>> {
    const body = {
      id,
      clientMode
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/one_client_mode`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderDocument>))
      );
  }

  addWithType(documentType: DocumentTypes): Observable<EntityData<OrderDocument>> {
    const body = {
      documentType
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/add`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderDocument>))
      );
  }

  estimatePrice(orderId: string, loadTypeIds: string[], sanitaryPassport: boolean): Observable<EntityData<number>> {
    const body = {
      orderId,
      loadTypeIds,
      sanitaryPassport
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/estimate_price`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data
        } as EntityData<number>))
      );
  }

  createCopy(id: string, isTemplate: boolean = false): Observable<EntityData<OrderDocumentCommonListModel>> {
    const body = {
      id,
      isTemplate,
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/copy`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderDocumentCommonListModel>))
      );
  }

  
  createByTemplate(entityItem: OrderDocument): Observable<EntityData<OrderDocumentCommonListModel>> {
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/createByTemplate`, this.entityAdapter.adaptOut(entityItem))
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  getOperatorDriversList(masterEntityId: string, documentType: DocumentTypes): Observable<EntityData<Driver>> {
    const body = {
      masterEntityId,
      documentType,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/drivers/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.driverAdapater.adaptIn(item))
            : []
        } as EntityData<Driver>))
      );
  }

  getCarrierDriversList(): Observable<EntityData<Driver>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/drivers/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.driverAdapater.adaptIn(item))
            : []
        } as EntityData<Driver>))
      );
  }

  getOperatorTrucksList(masterEntityId: string, documentType: DocumentTypes): Observable<EntityData<Truck>> {
    const body = {
      masterEntityId,
      documentType,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/trucks/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.truckAdapater.adaptIn(item))
            : []
        } as EntityData<Truck>))
      );
  }

  getCarrierTrucksList(): Observable<EntityData<Truck>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/trucks/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.truckAdapater.adaptIn(item))
            : []
        } as EntityData<Truck>))
      );
  }

  verifyDriver(masterEntityId: string, driverId: string, truckId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
      driverId,
      truckId
    };
    return this.http.post(`${this.configService.apiUrl}/operator/order/verify_driver`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  verifyDriver1(masterEntityId: string, driverId: string, truckId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
      driverId,
      truckId
    };
    return this.http.post(`${this.configService.apiUrl}/operator/order/verify_driver1`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  setDriverVerified(masterEntityId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/order/set_driver_verified`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  setDriver1Verified(masterEntityId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/order/set_driver_verified1`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  getInsuranceNumber(entityItem: OrderDocument): Observable<EntityData<any>> {
    return this.http.post(`${this.configService.apiUrl}/operator/order/get_insurance_number`, this.entityAdapter.adaptOut(entityItem))
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  getInsuranceFileLink(masterEntityId: string): Observable<EntityData<any>> {
    const body = {
      masterEntityId
    };

    return this.http.post(`${this.configService.apiUrl}/operator/order/get_insurance_link`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  updateInsurance(entityItem: OrderDocument) {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/operator/order/update_insurance`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  updateOwnerCompletedBidsData(entityItem: OrderDocument) {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/owner/order/updateOwnerCompletedBidsData`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  updateCarrierCompletedBidsData(entityItem: OrderDocument) {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/carrier/order/updateCarrierCompletedBidsData`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  confirmDocument(masterEntityId: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes): Observable<EntityData<any>> {
    const body = {
      masterEntityId,
      clientMode,
      confirmationFileType,
    };

    return this.http.post(`${this.configService.apiUrl}/order/confirm_document`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  confirmTransportData(masterEntityId: string, driverId: string, driver1Id: string, truckId: string): Observable<EntityData<any>> {
    const body = {
      masterEntityId,
      driverId,
      driver1Id,
      truckId,
    };

    return this.http.post(`${this.configService.apiUrl}/order/confirm_transport_data`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  deleteConfirmationFile(masterEntityId: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes): Observable<EntityData<OrderDocument>> {
    const body = {
      masterEntityId,
      clientMode,
      confirmationFileType,
    };

    return this.http.post(`${this.configService.apiUrl}/order/delete_file`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderDocument>))
      );
  }

  deleteFile(masterEntityId: string, uuid: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes) {
    const body = {
      masterEntityId,
      clientMode,
      uuid,
      confirmationFileType
    };

    return this.http.post(`${this.configService.apiUrl}/order/delete_file`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
