import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {finalize} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {OrderDocument} from '../../../../shared/models/order-document';
import {OrderDocumentsService} from '../../../../shared/services/order-documents.service';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';
import {LocalStorageService} from 'ngx-webstorage';
import {OrganizationsService} from '../../../../shared/services/organizations.service';
import {DocumentTypes} from '../../../../shared/enums/document-types';

@Component({
  selector: 'app-order-form-tab-calc',
  templateUrl: './tab-calc.component.html',
  styleUrls: ['./tab-calc.component.scss']
})
export class TabCalcComponent extends EntityEditForm implements OnInit, OnDestroy {
  entityItem: OrderDocument;
  pending = false;
  orderId: string;
  paymentDeferDaysAviable = 0;
  isPaymentDeferEnable = false;
  documentTypes = DocumentTypes;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              private orderDocumentsService: OrderDocumentsService,
              private snackService: SnackBarService,
              private validatorsService: CommonValidatorsService,
              protected organizationsService: OrganizationsService,
              protected storage: LocalStorageService) {
    super();

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.orderId = data.id;
            this.entityItem = data;
            this.initForm();
          }
        }
      );
  }

  ngOnInit() {
    this.initForm();
    const currentUser = this.storage.retrieve('currentUser');
    this.organizationsService.getOne(currentUser.organizationId).pipe()
      .subscribe((orgData) => {
        this.paymentDeferDaysAviable = orgData.list[0].deferedPayment;
        this.isPaymentDeferEnable = orgData.list[0].isDeferedPaymentSet;
    });

    if (this.mainFormService.documentType === this.documentTypes.orderTrunk){
      this.taxVat.setValue('VAT_INCLUDED');
    }
  }

  ngOnDestroy() {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabCalcForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabCalcForm = form;
  }

  get price() {
    return this.componentForm.get('price');
  }

  get declaredPrice() {
    return this.componentForm.get('declaredPrice');
  }

  get taxVat() {
    return this.componentForm.get('taxVat');
  }

  get paymentDeferType() {
    return this.componentForm.get('paymentDeferType');
  }
  get isPaymentDefer() {
    return this.componentForm.get('isPaymentDefer');
  }

  initForm() {
    let declaredPriceValidators = ['', [Validators.required, this.validatorsService.numberNotEmpty]];
    if (this.entityItem && this.entityItem.isTrunk) {
      declaredPriceValidators = [];
    }
    this.componentForm = this.formBuilder.group({
      estimatedPrice: [],
      price: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
      declaredPrice: declaredPriceValidators,
      taxVat: ['', Validators.required],
      paymentDeferType: ['', Validators.required],
      paymentDeferDays: [],
      isPaymentDefer: [],
      totalPrice: [],
      insurancePrice: [],
      deferPrice: [],
      siteComissionRate: [],
    });
  }

  estimatePrice() {
    const loadTypeIds = this.mainFormService.tabParametersForm.controls.loadTypeIds.value;
    const sanitaryPassport = this.mainFormService.tabParametersForm.controls.sanitaryPassport.value;
    this.pending = true;

    this.orderDocumentsService.estimatePrice(this.orderId, loadTypeIds, sanitaryPassport)
      .pipe(
        finalize(() => this.pending = false)
      ).subscribe(data => {
        this.mainFormService.tabCalcForm.controls.estimatedPrice.setValue(0);

        if (data.list.length) {
          this.mainFormService.tabCalcForm.controls.estimatedPrice.setValue(data.list[0].toFixed(0));
        }
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      });
  }

  calcPriceData() {
    const settings = this.mainFormService.settings;

    const price = parseFloat(this.mainFormService.tabCalcForm.controls.price.value) || 0;
    const declaredPrice = parseFloat(this.mainFormService.tabCalcForm.controls.declaredPrice.value) || 0;
    const insurance = parseFloat(settings.insurance) || 0;
    const insurancePrice = declaredPrice * insurance / 100;
    const paymentDeferRate = parseFloat(settings.paymentDefer) || 0;
    const paymentDeferDays = parseFloat(this.mainFormService.tabCalcForm.controls.paymentDeferDays.value) || 0;
    const paymentDeferType = this.mainFormService.tabCalcForm.controls.paymentDeferType.value;
    let paymentDefer = 0;
    let deferPrice = 0;

    if (['DEFER_100_4'].includes(paymentDeferType)) {
      paymentDefer = 1;
    }

    deferPrice = price * paymentDefer * (paymentDeferRate * paymentDeferDays / 30) / 100;

    this.mainFormService.tabCalcForm.controls.insurancePrice.setValue(insurancePrice);
    this.mainFormService.tabCalcForm.controls.deferPrice.setValue(deferPrice);

    let result = price + deferPrice;

    if (this.mainFormService.tabCalcForm.controls.taxVat.value === 'TAX_VAT_NOT_INCLUDED') {
      result *= 1.2;
    }

    this.mainFormService.tabCalcForm.controls.totalPrice.setValue(result.toFixed(0));
  }

  changeIsPaymentDefer() {
    const newValue = this.isPaymentDefer.value ? 'DEFER_100_4' : 'DEFER_100_1';
    this.mainFormService.tabCalcForm.controls.paymentDeferType.setValue(newValue);
    this.calcPriceData();
  }
}
