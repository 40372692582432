import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../classes/table-data-source';
import {Bid} from '../../models/bid';
import {BidsService} from '../../services/bids.service';
import {ErrorService} from '../../services/error.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {EntityData} from '../../interfaces/entity-data';

export class DocumentBidsDatasource extends TableDataSource<Bid> {

  constructor(protected entityService: BidsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(masterEntityId: string, pageIndex: number, pageSize: number) {
    this.setLoading(true);
    this.entityService.getList(masterEntityId, pageIndex + 1, pageSize)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<Bid>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);
        }, err => this.snackService.showErrorMsg(err));
  }
}
