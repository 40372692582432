import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../modules/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {ConfirmPopupComponent} from './confirm-popup/confirm-popup.component';
import {DocumentEquipmentComponent} from './document-equipment/document-equipment/document-equipment.component';
import {TableColFilterModule} from './table-col-filter/table-col-filter.module';
import {DocumentEquipmentAddFormComponent} from './document-equipment/document-equipment-add-form/document-equipment-add-form.component';
import {DocumentEquipmentEditFormComponent} from './document-equipment/document-equipment-edit-form/document-equipment-edit-form.component';
import {DirectivesModule} from '../directives/directives.module';
import {RestrictedOrganizationsComponent} from './restricted-organizations/restricted-organizations/restricted-organizations.component';
import {RestrictedOrganizationsAddFormComponent} from './restricted-organizations/restricted-organizations-add-form/restricted-organizations-add-form.component';
import {RestrictedOrganizationsEditFormComponent} from './restricted-organizations/restricted-organizations-edit-form/restricted-organizations-edit-form.component';
import {DocumentBidsComponent} from './document-bids/document-bids.component';
import {PipesModule} from '../pipes/pipes.module';
import {SetDriverVerifiedDialogComponent} from './set-driver-verified-dialog/set-driver-verified-dialog.component';
import {DocumentBidDialogComponent} from './document-bid-dialog/document-bid-dialog.component';
import { OwnerConfirmationProposalComponent } from './owner-confirmation-proposal/owner-confirmation-proposal.component';
import { ProposalEquipmentConfirmedComponent } from './proposal-equipment-confirmed/proposal-equipment-confirmed.component';
import {FilterOrderDocumentCommonComponent} from './filters/filter-order-document-common/filter-order-document-common.component';
import { FilterOperatorCommonComponent } from './filters/filter-operator-common/filter-operator-common.component';
import {RoutePointFormComponent} from './filters/filter-order-document-common/components/route-point-form/route-point-form.component';
import {FilterAdminTrucksComponent} from './filters/filter-admin-trucks/filter-admin-trucks.component';
import {FilterAdminDriversComponent} from './filters/filter-admin-drivers/filter-admin-drivers.component';
import {FilterAdminUsersComponent} from './filters/filter-admin-users/filter-admin-users.component';
import {SignatureExampleDialogComponent} from './signature-example-dialog/signature-example-dialog.component';
import { DocumentOperatorsComponent } from './document-operators/document-operators.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    ConfirmPopupComponent,
    DocumentEquipmentComponent,
    DocumentEquipmentAddFormComponent,
    DocumentEquipmentEditFormComponent,
    RestrictedOrganizationsComponent,
    RestrictedOrganizationsAddFormComponent,
    RestrictedOrganizationsEditFormComponent,
    DocumentBidsComponent,
    SetDriverVerifiedDialogComponent,
    DocumentBidDialogComponent,
    OwnerConfirmationProposalComponent,
    ProposalEquipmentConfirmedComponent,
    FilterOrderDocumentCommonComponent,
    FilterOperatorCommonComponent,
    RoutePointFormComponent,
    FilterAdminTrucksComponent,
    FilterAdminDriversComponent,
    FilterAdminUsersComponent,
    SignatureExampleDialogComponent,
    DocumentOperatorsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    TableColFilterModule,
    DirectivesModule,
    PipesModule
  ],
  exports: [
    ConfirmPopupComponent,
    DocumentEquipmentComponent,
    RestrictedOrganizationsComponent,
    DocumentBidsComponent,
    ProposalEquipmentConfirmedComponent,
    FilterOrderDocumentCommonComponent,
    FilterOperatorCommonComponent,
    FilterAdminTrucksComponent,
    FilterAdminDriversComponent,
    FilterAdminUsersComponent,
    DocumentOperatorsComponent,
  ],
  entryComponents: [
    ConfirmPopupComponent,
    DocumentEquipmentAddFormComponent,
    DocumentEquipmentEditFormComponent,
    RestrictedOrganizationsAddFormComponent,
    RestrictedOrganizationsEditFormComponent,
    SetDriverVerifiedDialogComponent,
    DocumentBidDialogComponent,
    OwnerConfirmationProposalComponent,
    RoutePointFormComponent,
    SignatureExampleDialogComponent,
  ],
})
export class ComponentsModule { }
