import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../shared/classes/table-data-source';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../shared/interfaces/entity-data';
import {ProposalConfirmationRoutesPoints} from '../../../../../shared/models/proposal-confirmation-routes-points';
import {FormArray, FormGroup} from '@angular/forms';

export class GridRoutesPointsDatasource extends TableDataSource<ProposalConfirmationRoutesPoints> {
  constructor(protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(entityId: string, componentForm: FormGroup) {
    this.setLoading(true);
    this.entityService.getListRoutesPoints(entityId)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<ProposalConfirmationRoutesPoints>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);

          data.list.forEach((item, index) => {
            (componentForm.get('items') as FormArray).controls[index].get('id').setValue(item.id);
            (componentForm.get('items') as FormArray).controls[index].get('code').setValue(item.code);
            (componentForm.get('items') as FormArray).controls[index].get('carrierRoute').setValue(item.carrierRoute);
            (componentForm.get('items') as FormArray).controls[index].get('carrierCoordinates').setValue(item.carrierCoordinates);
            (componentForm.get('items') as FormArray).controls[index].get('radius').setValue(item.radius);
            (componentForm.get('items') as FormArray).controls[index].get('ownerRoute').setValue(item.ownerRoute);
          });
        }, err => this.snackService.showErrorMsg(err));
  }
}
