export enum DocumentActionModes {
  edit = 'edit',
  add = 'add',
  view_bids_deffered = 'view_bids_deffered',
  view_bids = 'view_bids',
  view_bids_fixed_price = 'view_bids_fixed_price',
  view_completed_bids = 'view_completed_bids',
  view_confirmed = 'view_confirmed',
  view_completed = 'view_completed',
  view_failed = 'view_failed',
}
