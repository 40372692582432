export const colsConfig = [
  {
    name: 'Оборудование',
    show: true,
    class: 'name',
  },
  {
    name: 'Цена',
    show: true,
    class: 'price',
  },
  {
    name: 'Количество',
    show: true,
    class: 'quantity',
  },
  {
    name: 'Количество (Отправитель)',
    show: true,
    class: 'ownerConfirmationQuantity',
  },
  {
    name: 'Сумма (Отправитель)',
    show: true,
    class: 'totalOwnerConfirmation',
  },
];
