import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {EntityEditForm} from '../../../../../../shared/classes/entity-edit-form';
import {OrderRoute} from '../../../../../../shared/models/order-route';
import {MetaDataCrud} from '../../../../../../shared/interfaces/meta-data-crud';
import {OrderRoutesService} from '../../../../../../shared/services/order-routes.service';
import {CommonValidatorsService} from '../../../../../../shared/services/common-validators.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {RoutesUtilitiesService} from '../../../../../../shared/services/routes-utilities.service';
import ymaps from 'ymaps';
import {FormModes} from '../../../../../../shared/enums/form-modes';
import {ErrDialogComponent} from '../../../../../../shared/components/err-dialog/err-dialog.component';
(window as any).global = window;
import {MainFormService} from '../../../../services/main-form.service';
import {DocumentTypes} from '../../../../../../shared/enums/document-types';

@Component({
  selector: 'app-order-form-routes-form',
  templateUrl: './routes-form.component.html',
  styleUrls: ['./routes-form.component.scss']
})
export class RoutesFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: OrderRoute;
  entityForm: FormGroup;
  pending: boolean;
  protected metaData: MetaDataCrud[] = this.getMetaData();
  formModes = FormModes;
  documentTypes = DocumentTypes;

  //@ViewChild('timeInPicker', { read: NgxTimepickerFieldComponent, static: true }) timeInPicker: NgxTimepickerFieldComponent;
  //@ViewChild('timeOutPicker', { read: NgxTimepickerFieldComponent, static: true }) timeOutPicker: NgxTimepickerFieldComponent;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              protected entityService: OrderRoutesService,
              private validatorsService: CommonValidatorsService,
              private routesUtilitiesService: RoutesUtilitiesService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              private mainFormService: MainFormService,
              private dialogRef: MatDialogRef<RoutesFormComponent>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get sourceName() {
    return this.entityForm.get('sourceName');
  }

  get destinationName() {
    return this.entityForm.get('destinationName');
  }

  get containerName() {
    return this.entityForm.get('containerName');
  }

  get distance() {
    return this.entityForm.get('distance');
  }

  get dateIn() {
    return this.entityForm.get('dateIn');
  }

  get dateOut() {
    return this.entityForm.get('dateOut');
  }

  get dateContainer() {
    return this.entityForm.get('dateContainer');
  }

  get contactsOutPerson() {
    return this.entityForm.get('contactsOutPerson');
  }

  get contactsInPerson() {
    return this.entityForm.get('contactsInPerson');
  }

  get contactsContainerPerson() {
    return this.entityForm.get('contactsContainerPerson');
  }

  get contactsInPhone1() {
    return this.entityForm.get('contactsInPhone1');
  }

  get contactsInPhone2() {
    return this.entityForm.get('contactsInPhone2');
  }

  get contactsOutPhone1() {
    return this.entityForm.get('contactsOutPhone1');
  }

  get contactsOutPhone2() {
    return this.entityForm.get('contactsOutPhone2');
  }

  get contactsContainerPhone1() {
    return this.entityForm.get('contactsContainerPhone1');
  }

  get contactsContainerPhone2() {
    return this.entityForm.get('contactsContainerPhone2');
  }

  getMetaData() {
    let cntrlsCfg = [
      {
        entityField: 'sourceName',
        formField: 'sourceName',
        validators: [Validators.required]
      },
      {
        entityField: 'sourceNameShort',
        formField: 'sourceNameShort',
      },
      {
        entityField: 'sourceLongitude',
        formField: 'sourceLongitude',
      },
      {
        entityField: 'sourceLatitude',
        formField: 'sourceLatitude',
      },
      {
        entityField: 'destinationName',
        formField: 'destinationName',
        validators: [Validators.required]
      },
      {
        entityField: 'destinationNameShort',
        formField: 'destinationNameShort',
      },
      {
        entityField: 'destinationLongitude',
        formField: 'destinationLongitude',
      },
      {
        entityField: 'destinationLatitude',
        formField: 'destinationLatitude',
      },
      {
        entityField: 'dateIn',
        formField: 'dateIn',
        validators: [Validators.required]
      },
      {
        entityField: 'timeInHours',
        formField: 'timeInHours',
      },
      {
        entityField: 'timeInMinutes',
        formField: 'timeInMinutes',
      },
      {
        entityField: 'dateOut',
        formField: 'dateOut',
        validators: [Validators.required]
      },
      {
        entityField: 'timeOutHours',
        formField: 'timeOutHours',
      },
      {
        entityField: 'timeOutMinutes',
        formField: 'timeOutMinutes',
      },
      {
        entityField: 'distance',
        formField: 'distance',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPhone1',
        formField: 'contactsInPhone1',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPhone2',
        formField: 'contactsInPhone2',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsInPerson',
        formField: 'contactsInPerson',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsOutPhone1',
        formField: 'contactsOutPhone1',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsOutPhone2',
        formField: 'contactsOutPhone2',
        validators: [Validators.required]
      },
      {
        entityField: 'contactsOutPerson',
        formField: 'contactsOutPerson',
        validators: [Validators.required]
      },
    ];

    if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
      cntrlsCfg = cntrlsCfg.concat([
          {
            entityField: 'containerName',
            formField: 'containerName',
            validators: [Validators.required]
          },
          {
            entityField: 'containerNameShort',
            formField: 'containerNameShort',
          },
          {
            entityField: 'containerLongitude',
            formField: 'containerLongitude',
          },
          {
            entityField: 'containerLatitude',
            formField: 'containerLatitude',
          },
          {
            entityField: 'dateContainer',
            formField: 'dateContainer',
            validators: [Validators.required]
          },
          {
            entityField: 'timeContainerHours',
            formField: 'timeContainerHours',
          },
          {
            entityField: 'timeContainerMinutes',
            formField: 'timeContainerMinutes',
          },
          {
            entityField: 'contactsContainerPhone1',
            formField: 'contactsContainerPhone1',
            validators: [Validators.required]
          },
          {
            entityField: 'contactsContainerPhone2',
            formField: 'contactsContainerPhone2',
            validators: []
          },
          {
            entityField: 'contactsContainerPerson',
            formField: 'contactsContainerPerson',
            validators: [Validators.required]
          },
          {
            entityField: 'contactsInPhone2',
            formField: 'contactsInPhone2',
            validators: []
          },
          {
            entityField: 'contactsOutPhone2',
            formField: 'contactsOutPhone2',
            validators: []
          }
        ]
      );
    }

    return cntrlsCfg;
  }

  ngOnInit() {
    this.initForm();

    if ([FormModes.edit, FormModes.readOnly].includes(this.data.formMode)) {
      this.pending = true;

      forkJoin(
        this.entityService.getOne(this.data.id),
      ).pipe(
        finalize(() => this.pending = false)
      ).subscribe(([entityData]) => {
        this.entityItem = entityData.list[0];

        this.entityForm.patchValue(this.getFormPatchObject());

        this.loadYmaps();
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      });
    } else if (this.data.formMode === FormModes.add) {
      this.loadYmaps();

      this.entityItem = new OrderRoute();
    }
    if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
      this.entityForm.controls.sourceName.setValue('Россия, Калужская область, Боровский район, сельское поселение Ворсино, индустриальный парк Ворсино, Фрейт Вилладж Ворсино, территория Северная Промышленная Зона, вл15');
      this.entityForm.controls.sourceLongitude.setValue('36.65661800');
      this.entityForm.controls.sourceLatitude.setValue('55.24352600');
      this.entityForm.controls.sourceName.disable();
    }
  }

  loadYmaps() {
    const me = this;

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90').then(maps => {
      const sourceNameSV = new maps.SuggestView('sourceName');
      const destinationNameSV = new maps.SuggestView('destinationName');
      let containerNameSV;
      if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
        containerNameSV = new maps.SuggestView('containerName');
      }

      const myMap = new maps.Map('map', {
          center: [60.906882, 30.067233],
          zoom: 9,
          controls: []
        }),
        // Создадим панель маршрутизации.
        routePanelControl = new maps.control.RoutePanel({
          options: {
            // Добавим заголовок панели.
            showHeader: true,
            visible: false,
            title: 'Расчёт расстояния'
          }
        }),
        zoomControl = new maps.control.ZoomControl({
          options: {
            size: 'small',
            float: 'none',
            position: {
              bottom: 145,
              right: 10
            }
          }
        });

      // Пользователь сможет построить только автомобильный маршрут.
      routePanelControl.routePanel.options.set({
        types: {auto: true}
      });

      if (me.entityForm.controls.sourceName.value) {
        routePanelControl.routePanel.state.set({
          fromEnabled: true,
          from: me.entityForm.controls.sourceName.value
        });
      }

      if (me.entityForm.controls.destinationName.value) {
        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: me.entityForm.controls.destinationName.value
        });
      }

      if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
        if (me.entityForm.controls.containerName.value) {
          routePanelControl.routePanel.state.set({
            viaEnabled: true,
            via: me.entityForm.controls.containerName.value
          });
        }
      }

      sourceNameSV.events.add('select', (event) => {
        me.entityForm.controls.sourceName.setValue(event.originalEvent.item.value);
        me.entityForm.controls.sourceLongitude.setValue('');
        me.entityForm.controls.sourceLatitude.setValue('');

        routePanelControl.routePanel.state.set({
          fromEnabled: true,
          from: event.originalEvent.item.value
        });

        me.pending = true;

        // Ищем координаты указанного адреса
        // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
        const geocoder = maps.geocode(event.originalEvent.item.value);

        // После того, как поиск вернул результат, вызывается callback-функция
        geocoder.then(function (res) {
            // координаты объекта
            // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
            const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

            me.entityForm.controls.sourceLatitude.setValue(coordinates[0]);
            me.entityForm.controls.sourceLongitude.setValue(coordinates[1]);

            me.pending = false;
          }
        );
      });

      destinationNameSV.events.add('select', (event) => {
        me.entityForm.controls.destinationName.setValue(event.originalEvent.item.value);
        me.entityForm.controls.destinationLongitude.setValue('');
        me.entityForm.controls.destinationLatitude.setValue('');

        routePanelControl.routePanel.state.set({
          toEnabled: true,
          to: event.originalEvent.item.value
        });

        // Ищем координаты указанного адреса
        // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
        const geocoder = maps.geocode(event.originalEvent.item.value);

        me.pending = true;

        // После того, как поиск вернул результат, вызывается callback-функция
        geocoder.then(function (res) {
            // координаты объекта
            // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
            const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

            me.entityForm.controls.destinationLatitude.setValue(coordinates[0]);
            me.entityForm.controls.destinationLongitude.setValue(coordinates[1]);

            me.pending = false;
          }
        );
      });

      if (this.mainFormService.documentType == DocumentTypes.orderContainer ){
        containerNameSV.events.add('select', (event) => {
          me.entityForm.controls.containerName.setValue(event.originalEvent.item.value);
          me.entityForm.controls.containerLongitude.setValue('');
          me.entityForm.controls.containerLatitude.setValue('');

          routePanelControl.routePanel.state.set({
            viaEnabled: true,
            via: event.originalEvent.item.value
          });

          // Ищем координаты указанного адреса
          // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
          const geocoder = maps.geocode(event.originalEvent.item.value);

          me.pending = true;

          // После того, как поиск вернул результат, вызывается callback-функция
          geocoder.then(function (res) {
              // координаты объекта
              // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
              const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

              me.entityForm.controls.containerLatitude.setValue(coordinates[0]);
              me.entityForm.controls.containerLongitude.setValue(coordinates[1]);

              me.pending = false;
            }
          );
        });
      }

      myMap.controls.add(routePanelControl).add(zoomControl);

      me.pending = true;

      // Получим ссылку на маршрут.
      routePanelControl.routePanel.getRouteAsync().then(function (route) {
        // Зададим максимально допустимое число маршрутов, возвращаемых мультимаршрутизатором.
        route.model.setParams({results: 1}, true);

        me.pending = false;

        // Повесим обработчик на событие построения маршрута.
        route.model.events.add('requestsuccess', function (event) {
          me.entityForm.controls.distance.setValue(0);

          const activeRoute = route.getActiveRoute();

          if (activeRoute) {
            // Получим протяженность маршрута.
            const distanceProperty = route.getActiveRoute().properties.get('distance'),
                  distance = (Math.round(distanceProperty.value / 1000 * 100) / 100).toFixed(0);

            me.entityForm.controls.distance.setValue(distance);

            // Создадим макет содержимого балуна маршрута.
            const balloonContentLayout = maps.templateLayoutFactory.createClass(
              '<span>Расстояние: ' + distance + ' км.</span><br/>');
            // Зададим этот макет для содержимого балуна.
            route.options.set('routeBalloonContentLayout', balloonContentLayout);
            // Откроем балун.
            activeRoute.balloon.open();
          }
        });
      });
    }).catch(error => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Ошибка загрузки Яндекс карт'
        }
      });

      console.dir(error);
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  async save() {
    const errMsg = [];

    if (!this.entityForm.controls.dateIn.value) {
      errMsg.push('Дата погрузки');
    }

    if (!this.entityForm.controls.contactsInPhone1.value || !this.entityForm.controls.contactsInPhone1.valid) {
      errMsg.push('Данные погрузки, телефон №1');
    }

    if (this.mainFormService.documentType != DocumentTypes.orderContainer  && (!this.entityForm.controls.contactsInPhone2.value || !this.entityForm.controls.contactsInPhone2.valid)) {
      errMsg.push('Данные погрузки, телефон №2');
    }

    if (!this.entityForm.controls.contactsInPerson.value) {
      errMsg.push('Данные погрузки, контакты');
    }

    if (!this.entityForm.controls.dateOut.value) {
      errMsg.push('Дата выгрузки');
    }

    if (!this.entityForm.controls.contactsOutPhone1.value || !this.entityForm.controls.contactsOutPhone1.valid) {
      errMsg.push('Данные выгрузки, телефон №1');
    }

    if (this.mainFormService.documentType != DocumentTypes.orderContainer  && (!this.entityForm.controls.contactsOutPhone2.value || !this.entityForm.controls.contactsOutPhone2.valid)) {
      errMsg.push('Данные выгрузки, телефон №2');
    }

    if (!this.entityForm.controls.contactsOutPerson.value) {
      errMsg.push('Данные выгрузки, контакты');
    }

    if (errMsg.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errMsg
      });

      return;
    }

    if (!this.entityForm.valid) {
      Object.keys(this.entityForm.controls).forEach(field => {
        const control = this.entityForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      return;
    }

    this.pending = true;

    const maps = await ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90');

    const geocodeSource = await maps.geocode(this.entityForm.controls.sourceName.value);
    const geocodeDestination = await maps.geocode(this.entityForm.controls.destinationName.value);

    let geoObject = geocodeSource.geoObjects.get(0);
    let addressShort = [geoObject.getCountry(),
      geoObject.getAdministrativeAreas().join(', '),
      geoObject.getLocalities().join(', ')];

/*    if (geoObject.getThoroughfare()) {
      addressShort.push(geoObject.getThoroughfare());
    }*/

    this.entityForm.controls.sourceName.setValue(geoObject.getAddressLine());
    this.entityForm.controls.sourceNameShort.setValue(addressShort.join(', '));

    geoObject = geocodeDestination.geoObjects.get(0);
    addressShort = [geoObject.getCountry(),
      geoObject.getAdministrativeAreas().join(', '),
      geoObject.getLocalities().join(', ')];

/*    if (geoObject.getThoroughfare()) {
      addressShort.push(geoObject.getThoroughfare());
    }*/

    this.entityForm.controls.destinationName.setValue(geoObject.getAddressLine());
    this.entityForm.controls.destinationNameShort.setValue(addressShort.join(', '));

    let geoErrors = [];

    if (!this.entityForm.controls.sourceLatitude.value || !this.entityForm.controls.sourceLongitude.value) {
      // координаты объекта
      // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
      if (geocodeSource.geoObjects.length) {
        let coordinates = geocodeSource.geoObjects.get(0).geometry.getCoordinates();

        this.entityForm.controls.sourceLatitude.setValue(coordinates[0]);
        this.entityForm.controls.sourceLongitude.setValue(coordinates[1]);
      } else {
        geoErrors.push('Не удалось определить координаты пункта отправления');
      }
    }

    if (!this.entityForm.controls.destinationLatitude.value || !this.entityForm.controls.destinationLongitude.value) {
      // координаты объекта
      // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
      if (geocodeDestination.geoObjects.length) {
        let coordinates = geocodeDestination.geoObjects.get(0).geometry.getCoordinates();

        this.entityForm.controls.destinationLatitude.setValue(coordinates[0]);
        this.entityForm.controls.destinationLongitude.setValue(coordinates[1]);
      } else {
        geoErrors.push('Не удалось определить координаты пункта назначенния');
      }
    }

    if (geoErrors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: geoErrors
      });

      this.pending = false;
      return;
    }

    this.metaData.forEach(item => {
      if (item.skip) {
        return;
      }
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    this.entityItem.orderId = this.data.masterEntityId;

    if (this.data.formMode === FormModes.edit) {
      this.entityService.updateCheck(this.entityItem, this.data.docDateEnd).subscribe(success => {
          this.pending = false;
          this.dialogRef.close({reload: true});
          this.toastService.success('Объект изменен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    } else if (this.data.formMode === FormModes.add) {
      this.entityService.addCheck(this.entityItem, this.data.docDateEnd).subscribe(success => {
          this.pending = false;
          this.dialogRef.close({reload: true});
          this.toastService.success('Объект добавлен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.pending = false;
            this.snackService.showErrorMsg(error);
          });
        }
      );
    } else {
      this.pending = false;
      this.dialogRef.close({reload: false});
    }
  }

  onSourceNameChange() {
    this.entityForm.controls.sourceLatitude.setValue('');
    this.entityForm.controls.sourceLongitude.setValue('');
  }

  onDestinationNameChange() {
    this.entityForm.controls.destinationLatitude.setValue('');
    this.entityForm.controls.destinationLongitude.setValue('');
  }

  onContainerNameChange() {
    this.entityForm.controls.containerLatitude.setValue('');
    this.entityForm.controls.containerLongitude.setValue('');
  }
}
