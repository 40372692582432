import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersAdapterService implements EntityAdapter<User> {
  adaptIn(item: any): User {
    return new User(
      item.id,
      item.name,
      item.email,
      item.roles,
      item.role,
      item.firstName,
      item.middleName,
      item.lastName,
      item.organization,
      item.organizationId,
      item.phone,
      item.password,
      item.passwordConfirm,
      item.token,
      item.status,
      item.containerToken,
      item.hasContainerToken
    );
  }

  adaptOut(item: User): any {
    return {
      ...item
    };
  }
}
