import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatMenuTrigger} from '@angular/material';
import {FilterOrderDocumentDraftsModel} from '../../../interfaces/filter-order-document-drafts.model';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {CargoTypesService} from '../../../services/cargo-types.service';
import {CargoType} from '../../../models/cargo-type';
import {CommonDictService} from '../../../services/common-dict.service';
import {Model} from '../../../models/model';
import {RoutePointFormComponent} from './components/route-point-form/route-point-form.component';
import {TruckType} from '../../../models/truck-type';
import {TruckTypesService} from '../../../services/truck-types.service';
import {ErrDialogComponent} from '../../err-dialog/err-dialog.component';
import {OrderCommonDictService} from '../../../services/order-common-dict.service';

@Component({
  selector: 'app-filter-order-document-common',
  templateUrl: './filter-order-document-common.component.html',
  styleUrls: ['./filter-order-document-common.component.scss']
})
export class FilterOrderDocumentCommonComponent implements OnInit {
  filterForm: FormGroup;
  pending = false;
  cargoTypesList: CargoType[] = [];
  documentTypesList: Model[] = [];
  truckTypesList: TruckType[] = [];

  @ViewChild(MatMenuTrigger, { static: false }) filterMenuTrigger: MatMenuTrigger;

  @Output() applyFilterEvent: EventEmitter<FilterOrderDocumentDraftsModel> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private cargoTypesService: CargoTypesService,
              private commonDictService: CommonDictService,
              private orderCommonDictService: OrderCommonDictService,
              private truckTypesService: TruckTypesService,
              private dialog: MatDialog,) { }

  get sourceName() {
    return this.filterForm.get('sourceName');
  }

  get sourceLongitude() {
    return this.filterForm.get('sourceLongitude');
  }

  get sourceLatitude() {
    return this.filterForm.get('sourceLatitude');
  }

  get destinationName() {
    return this.filterForm.get('destinationName');
  }

  get destinationLongitude() {
    return this.filterForm.get('destinationLongitude');
  }

  get destinationLatitude() {
    return this.filterForm.get('destinationLatitude');
  }

  ngOnInit() {
    this.pending = true;

    this.initFilterForm();

    forkJoin(
      this.cargoTypesService.getList(),
      this.orderCommonDictService.getDocumentTypesList(),
      this.truckTypesService.getTree(),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([cargoTypesData, documentTypesData, truckTypesData]) => {
      this.cargoTypesList = cargoTypesData.list;
      this.documentTypesList = documentTypesData.list;
      this.truckTypesList = truckTypesData.list;
    });
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      number: [''],
      docDateEndStart: [''],
      docDateEndEnd: [''],
      loadingDateStart: [''],
      loadingDateEnd: [''],
      unloadingDateStart: [''],
      unloadingDateEnd: [''],
      truckTypeIds: [''],
      weightStart: [''],
      weightEnd: [''],
      cargoTypeId: [''],
      sourceName: [''],
      sourceLongitude: [''],
      sourceLatitude: [''],
      sourceRadius: [''],
      destinationName: [''],
      destinationLongitude: [''],
      destinationLatitude: [''],
      destinationRadius: [''],
      capacityStart: [''],
      capacityEnd: [''],
      equipmentPalletsQuantityStart: [''],
      equipmentPalletsQuantityEnd: [''],
      equipmentGrasshoppersQuantityStart: [''],
      equipmentGrasshoppersQuantityEnd: [''],
      isLoadingLift: ['0'],
      isLoadTypeFull: ['0'],
      isLoadTypeUp: ['0'],
      isLoadTypeSide: ['0'],
      sanitaryPassport: ['0'],
      addCargo: ['0'],
      typeInfo: [''],
      startPriceStart: [''],
      startPriceEnd: [''],
      auctionPriceStart: [''],
      auctionPriceEnd: [''],
      documentsBy: ['0'],
    });
  }

  closeFilterMenu() {
    this.filterMenuTrigger.closeMenu();
  }

  applyFilter() {
    const errors = [];

    if (this.filterForm.controls.docDateEndStart.value && this.filterForm.controls.docDateEndEnd.value) {
      if (this.filterForm.controls.docDateEndStart.value > this.filterForm.controls.docDateEndEnd.value) {
        errors.push('Дата окончания торгов, значение "с" не может быть больше значения "по"');
      }
    }

    if (this.filterForm.controls.loadingDateStart.value && this.filterForm.controls.loadingDateEnd.value) {
      if (this.filterForm.controls.loadingDateStart.value > this.filterForm.controls.loadingDateEnd.value) {
        errors.push('Дата погрузки, значение "с" не может быть больше значения "по"');
      }
    }

    if (this.filterForm.controls.unloadingDateStart.value && this.filterForm.controls.unloadingDateEnd.value) {
      if (this.filterForm.controls.unloadingDateStart.value > this.filterForm.controls.unloadingDateEnd.value) {
        errors.push('Дата выгрузки, значение "с" не может быть больше значения "по"');
      }
    }

    if (this.filterForm.controls.docDateEndStart.value && this.filterForm.controls.docDateEndEnd.value) {
      if (this.filterForm.controls.docDateEndStart.value > this.filterForm.controls.docDateEndEnd.value) {
        errors.push('Дата окончания торгов, значение "с" не может быть больше значения "по"');
      }
    }

    if (this.filterForm.controls.weightStart.value && this.filterForm.controls.weightEnd.value) {
      const weightStart = parseFloat(this.filterForm.controls.weightStart.value);
      const weightEnd = parseFloat(this.filterForm.controls.weightEnd.value);

      if (weightStart > weightEnd) {
        errors.push('Масса, кг, значение "от" не может быть больше значения "до"');
      }
    }

    if (this.filterForm.controls.capacityStart.value && this.filterForm.controls.capacityEnd.value) {
      const capacityStart = parseFloat(this.filterForm.controls.capacityStart.value);
      const capacityEnd = parseFloat(this.filterForm.controls.capacityEnd.value);

      if (capacityStart > capacityEnd) {
        errors.push('Объем, значение "от" не может быть больше значения "до"');
      }
    }

    if (this.filterForm.controls.equipmentPalletsQuantityStart.value && this.filterForm.controls.equipmentPalletsQuantityEnd.value) {
      const equipmentPalletsQuantityEnd = parseFloat(this.filterForm.controls.equipmentPalletsQuantityEnd.value);
      const equipmentPalletsQuantityStart = parseFloat(this.filterForm.controls.equipmentPalletsQuantityStart.value);

      if (equipmentPalletsQuantityStart > equipmentPalletsQuantityEnd) {
        errors.push('Паллеты, значение "от" не может быть больше значения "до"');
      }
    }

    if (this.filterForm.controls.equipmentGrasshoppersQuantityStart.value && this.filterForm.controls.equipmentGrasshoppersQuantityEnd.value) {
      const equipmentGrasshoppersQuantityEnd = parseFloat(this.filterForm.controls.equipmentGrasshoppersQuantityEnd.value);
      const equipmentGrasshoppersQuantityStart = parseFloat(this.filterForm.controls.equipmentGrasshoppersQuantityStart.value);

      if (equipmentGrasshoppersQuantityStart > equipmentGrasshoppersQuantityEnd) {
        errors.push('Коники, значение "от" не может быть больше значения "до"');
      }
    }

    if (this.filterForm.controls.auctionPriceStart.value && this.filterForm.controls.auctionPriceEnd.value) {
      const auctionPriceEnd = parseFloat(this.filterForm.controls.auctionPriceEnd.value);
      const auctionPriceStart = parseFloat(this.filterForm.controls.auctionPriceStart.value);

        if (auctionPriceStart > auctionPriceEnd) {
        errors.push('Цена аукциона, значение "от" не может быть больше значения "до"');
      }
    }

    if (this.filterForm.controls.startPriceStart.value && this.filterForm.controls.startPriceEnd.value) {
      const startPriceEnd = parseFloat(this.filterForm.controls.startPriceEnd.value);
      const startPriceStart = parseFloat(this.filterForm.controls.startPriceStart.value);

      if (startPriceStart > startPriceEnd) {
        errors.push('Начальная цена, значение "от" не может быть больше значения "до"');
      }
    }

    if (errors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errors
      });

      return;
    }

    this.applyFilterEvent.emit(this.filterForm.value);

    this.closeFilterMenu();
  }

  resetFilter() {
    this.filterForm.reset();
    this.filterForm.controls.isLoadingLift.setValue('0');
    this.filterForm.controls.isLoadTypeFull.setValue('0');
    this.filterForm.controls.isLoadTypeUp.setValue('0');
    this.filterForm.controls.isLoadTypeSide.setValue('0');
    this.filterForm.controls.sanitaryPassport.setValue('0');
    this.filterForm.controls.addCargo.setValue('0');
    this.filterForm.controls.documentsBy.setValue('0');
  }

  openAddressEditDialog(type: string = 'sourceName') {
    const dialogRef = this.dialog.open(RoutePointFormComponent, {
      disableClose: true,
      width: '900px',
      data: {
        address: type === 'sourceName' ? this.sourceName.value : this.destinationName.value,
        longitude: type === 'sourceName' ? this.sourceLongitude.value : this.destinationLongitude.value,
        latitude: type === 'sourceName' ? this.sourceLatitude.value : this.destinationLatitude.value,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (type === 'sourceName') {
          this.filterForm.controls.sourceName.setValue(data.address);
          this.filterForm.controls.sourceLongitude.setValue(data.longitude);
          this.filterForm.controls.sourceLatitude.setValue(data.latitude);
        } else {
          this.filterForm.controls.destinationName.setValue(data.address);
          this.filterForm.controls.destinationLongitude.setValue(data.longitude);
          this.filterForm.controls.destinationLatitude.setValue(data.latitude);
        }
      } else {
        if (type === 'sourceName') {
          this.filterForm.controls.sourceName.setValue('');
          this.filterForm.controls.sourceLongitude.setValue('');
          this.filterForm.controls.sourceLatitude.setValue('');
        } else {
          this.filterForm.controls.destinationName.setValue('');
          this.filterForm.controls.destinationLongitude.setValue('');
          this.filterForm.controls.destinationLatitude.setValue('');
        }
      }
    });
  }

  resetAddress(type: string = 'sourceName') {
    if (type === 'sourceName') {
      this.filterForm.controls.sourceName.setValue('');
      this.filterForm.controls.sourceLongitude.setValue('');
      this.filterForm.controls.sourceLatitude.setValue('');
    } else {
      this.filterForm.controls.destinationName.setValue('');
      this.filterForm.controls.destinationLongitude.setValue('');
      this.filterForm.controls.destinationLatitude.setValue('');
    }
  }
}
