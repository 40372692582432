import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateParserService {
  public parseDate(dateStr: string) {
    if (!dateStr) {
      return null;
    }

    if (dateStr.length === 10) {
      const parts = dateStr.split('-');

      if (parts.length === 3) {
        const year = parseFloat(parts[0]);
        const month = parseFloat(parts[1]) - 1;
        const day = parseFloat(parts[2]);
        const hours = 0;
        const minutes = 0;
        const seconds = 0;

        return new Date(year, month, day, hours, minutes, seconds);
      }
    } else if (dateStr.length === 19) {
      const parts = dateStr.split('-');

      if (parts.length === 3) {
        const timeParts = parts[2].substr(3, 8).split(':');

        const year = parseFloat(parts[0]);
        const month = parseFloat(parts[1]) - 1;
        const day = parseFloat(parts[2].substr(0, 2));
        const hours = parseFloat(timeParts[0]);
        const minutes = parseFloat(timeParts[1]);
        const seconds = parseFloat(timeParts[2]);

        return new Date(year, month, day, hours, minutes, seconds);
      }
    }

    return null;
  }
}
