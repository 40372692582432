import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../shared/classes/table-data-source';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../shared/interfaces/entity-data';
import {ProposalConfirmationEquipment} from '../../../../../shared/models/proposal-confirmation-equipment';
import {FormArray, FormGroup} from '@angular/forms';
import {ClientModes} from '../../../../../shared/enums/client-modes';

export class GridEquipmentDatasource extends TableDataSource<ProposalConfirmationEquipment> {
  constructor(protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(entityId: string, clientMode: ClientModes, componentForm: FormGroup) {
    this.setLoading(true);
    this.entityService.getListEquipment(entityId, clientMode)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<ProposalConfirmationEquipment>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);

          data.list.forEach((item, index) => {
            (componentForm.get('items') as FormArray).controls[index].get('id').setValue(item.id);
            (componentForm.get('items') as FormArray).controls[index].get('carrierPrice').setValue(item.carrierPrice);
            (componentForm.get('items') as FormArray).controls[index].get('carrierQuantity').setValue(item.carrierQuantity);
            (componentForm.get('items') as FormArray).controls[index].get('carrierTotal').setValue(item.carrierTotal);
            (componentForm.get('items') as FormArray).controls[index].get('ownerPrice').setValue(item.ownerPrice);
            (componentForm.get('items') as FormArray).controls[index].get('ownerQuantity').setValue(item.ownerQuantity);
            (componentForm.get('items') as FormArray).controls[index].get('ownerTotal').setValue(item.ownerTotal);
          });
        }, err => this.snackService.showErrorMsg(err));
  }
}
