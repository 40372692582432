import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {EntityEditForm} from '../../../classes/entity-edit-form';
import {DocumentOrganization} from '../../../models/document-organization';
import {Organization} from '../../../models/organization';
import {MetaDataCrud} from '../../../interfaces/meta-data-crud';
import {OrganizationsService} from '../../../services/organizations.service';
import {CommonValidatorsService} from '../../../services/common-validators.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {RestrictedOrganizationsService} from '../../../services/restricted-organizations.service';

@Component({
  selector: 'app-restricted-organizations-add-form',
  templateUrl: './restricted-organizations-add-form.component.html',
  styleUrls: ['./restricted-organizations-add-form.component.scss']
})
export class RestrictedOrganizationsAddFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: DocumentOrganization;
  entityForm: FormGroup;
  pending: boolean;
  organizationsList: Organization[] = [];
  protected metaData: MetaDataCrud[] = this.getMetaData();

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              protected entityService: RestrictedOrganizationsService,
              protected organizationsService: OrganizationsService,
              private validatorsService: CommonValidatorsService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialogRef: MatDialogRef<RestrictedOrganizationsAddFormComponent>) {
    super();
  }

  get organizationId() {
    return this.entityForm.get('organizationId');
  }

  getMetaData() {
    return [
      {
        entityField: 'organizationId',
        formField: 'organizationId',
        validators: [Validators.required]
      },
    ];
  }

  ngOnInit() {
    this.pending = true;

    this.initForm();

    forkJoin(
      this.organizationsService.getShortList(this.data.clientMode),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([organizationsData]) => {
      this.organizationsList = organizationsData.list;
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg(error);
      });
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  save() {
    if (!this.entityForm.valid) {
      return;
    }

    this.pending = true;

    this.entityItem = new DocumentOrganization();
    this.metaData.forEach(item => {
      if (item.skip) {
        return;
      }
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    this.entityItem.masterEntityId = this.data.masterEntityId;

    this.entityService.add(this.entityItem, this.data.restrictionType, this.data.clientMode).subscribe(success => {
        this.pending = false;
        this.dialogRef.close({reload: true});
        this.toastService.success('Объект добавлен', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }
}
