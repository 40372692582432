import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalRoute} from '../models/proposal-route';
import * as moment from 'moment';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalRoutesAdapterService implements EntityAdapter<ProposalRoute> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): ProposalRoute {
    return new ProposalRoute(
      item.id,
      item.name,
      item.proposalId,
      item.sourceName,
      item.sourceCoordinates,
      +item.sourceLongitude,
      +item.sourceLatitude,
      item.destinationName,
      item.destinationCoordinates,
      +item.destinationLongitude,
      +item.destinationLatitude,
      Math.round(+item.distance),
      Math.round(+item.sourceRadius),
      Math.round(+item.destinationRadius),
      item.dateOutType,
      this.dateParserService.parseDate(item.dateOutStart),
      this.dateParserService.parseDate(item.dateOutEnd),
      item.dateInType,
      this.dateParserService.parseDate(item.dateInStart),
      this.dateParserService.parseDate(item.dateInEnd),
    );
  }

  adaptOut(item: ProposalRoute): any {
    return {
      ...item,
      dateOutStart: item.dateOutStart ? moment(item.dateOutStart).format('DD.MM.YYYY') : null,
      dateOutEnd: item.dateOutEnd ? moment(item.dateOutEnd).format('DD.MM.YYYY') : null,
      dateInStart: item.dateInStart ? moment(item.dateInStart).format('DD.MM.YYYY') : null,
      dateInEnd: item.dateInEnd ? moment(item.dateInEnd).format('DD.MM.YYYY') : null,
    };
  }
}
