import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatMenuTrigger} from '@angular/material';
import {FilterOrderDocumentCommonModel} from '../../../interfaces/filter-order-document-common.model';
import {DocumentTypes} from '../../../enums/document-types';
import {Model} from '../../../models/model';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {CargoType} from '../../../models/cargo-type';
import {CargoTypesService} from '../../../services/cargo-types.service';
import {OrderCommonDictService} from '../../../services/order-common-dict.service';

@Component({
  selector: 'app-filter-operator-common',
  templateUrl: './filter-operator-common.component.html',
  styleUrls: ['./filter-operator-common.component.scss']
})
export class FilterOperatorCommonComponent implements OnInit {
  filterForm: FormGroup;
  pending = false;
  cargoTypesList: CargoType[] = [];
  documentTypesList: Model[] = [];
  auctionStepsList: Model[] = [];

  @ViewChild(MatMenuTrigger, { static: false }) filterMenuTrigger: MatMenuTrigger;

  @Output() applyFilterEvent: EventEmitter<FilterOrderDocumentCommonModel> = new EventEmitter();

  @Input() documentType: DocumentTypes;

  constructor(private formBuilder: FormBuilder,
              private cargoTypesService: CargoTypesService,
              private orderCommonDictService: OrderCommonDictService,) { }

  ngOnInit() {
    this.pending = true;

    this.initFilterForm();

    forkJoin(
      this.cargoTypesService.getList(),
      this.orderCommonDictService.getDocumentTypesList(),
      this.orderCommonDictService.getAuctionStepsList(),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([cargoTypesData, documentTypesData, auctionStepsData]) => {
      this.cargoTypesList = cargoTypesData.list;
      this.documentTypesList = documentTypesData.list;
      this.auctionStepsList = auctionStepsData.list;
    })
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      docDateStart: [''],
      docDateEnd: [''],
      statusExecution: [''],
      cargoTypeId: [''],
      typeInfo: [''],
      step: [''],
      startPriceStart: [''],
      startPriceEnd: [''],
      auctionPriceStart: [''],
      auctionPriceEnd: [''],
      insuranceNumber: [''],
    });
  }

  closeFilterMenu() {
    this.filterMenuTrigger.closeMenu();
  }

  applyFilter() {
    this.applyFilterEvent.emit(this.filterForm.value);

    this.closeFilterMenu();
  }

  resetFilter() {
    this.filterForm.reset();
  }
}
