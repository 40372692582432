import {Model} from './model';

export class ProposalConfirmationRoutesPoints extends Model {
  constructor(
    public id?: string,
    public code?: string,
    public name?: string,
    public carrierRoute?: string,
    public carrierCoordinates?: string,
    public radius?: number,
    public ownerRoute?: string,
  ) {
    super('', name);
  }
}
