import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ProposalConfirmationRoutesDates} from '../../../../../shared/models/proposal-confirmation-routes-dates';
import {GridRoutesDatesDatasource} from './grid-routes-dates.datasource';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {MainFormService} from '../../../services/main-form.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, MatDatepickerInputEvent, NativeDateAdapter} from '@angular/material';
import {colsConfig} from './config/cols.config';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-proposal-confirmation-grid-routes-dates',
  templateUrl: './grid-routes-dates.component.html',
  styleUrls: ['./grid-routes-dates.component.scss']
})
export class GridRoutesDatesComponent extends TableComponent<(ProposalConfirmationRoutesDates), GridRoutesDatesDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name_', 'carrierDateStart', 'carrierDateEnd', 'ownerDateStart', 'ownerDateEnd'];
  storageKey = 'app-proposal-confirmation-grid-routes-dates';
  dataSource: GridRoutesDatesDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  entityId: string;

  constructor(private mainFormService: MainFormService,
              protected storage: LocalStorageService,
              private formBuilder: FormBuilder,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.routesDatesForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.routesDatesForm = form;
  }

  ngOnInit() {
    this.initForm();

    super.ngOnInit();

    this.initDataSource();
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          id: [''],
          code: [''],
          carrierDateStart: [''],
          carrierDateEnd: [''],
          ownerDateStart: [''],
          ownerDateEnd: [''],
        }),
        this.formBuilder.group({
          id: [''],
          code: [''],
          carrierDateStart: [''],
          carrierDateEnd: [''],
          ownerDateStart: [''],
          ownerDateEnd: [''],
        })
      ])
    });
  }

  ngOnDestroy() {}

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  initDataSource() {
    this.dataSource = new GridRoutesDatesDatasource(this.entityService, this.errorService, this.snackService);

    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  ownerDateStartChange(event: MatDatepickerInputEvent<Date>, entityItem: ProposalConfirmationRoutesDates, index: number) {
    if (!event.value) {
      return;
    }

    const ownerDateEnd = (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').value;
    if (ownerDateEnd && moment(event.value).isAfter(ownerDateEnd, 'day')) {
      this.snackService.showErrorMsg('Дата отправителя (от) не может быть больше даты отправителя (до)');
      (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').setValue(null);
      return;
    }

    if (entityItem.carrierDateStart && entityItem.carrierDateEnd) {
      if (moment(event.value).isBefore(entityItem.carrierDateStart, 'day')) {
        this.snackService.showErrorMsg('Дата отправителя (от) не может быть меньше даты Перевозчика (от)');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').setValue(null);
      }

      if (moment(event.value).isAfter(entityItem.carrierDateEnd, 'day')) {
        this.snackService.showErrorMsg('Дата отправителя (от) не может быть больше даты Перевозчика (до)');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').setValue(null);
      }
    } else {
      if (!moment(event.value).isSame(entityItem.carrierDateStart, 'day')) {
        this.snackService.showErrorMsg('Потребность отправителя должна быть равна предложению перевозчика');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').setValue(null);
      }
    }
  }

  ownerDateEndChange(event: MatDatepickerInputEvent<Date>, entityItem: ProposalConfirmationRoutesDates, index: number) {
    if (!event.value) {
      return;
    }

    const ownerDateStart = (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateStart').value;
    if (ownerDateStart && moment(event.value).isBefore(ownerDateStart, 'day')) {
      this.snackService.showErrorMsg('Дата отправителя (до) не может быть меньше даты отправителя (от)');
      (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').setValue(null);
      return;
    }

    if (entityItem.carrierDateStart && entityItem.carrierDateEnd) {
      if (moment(event.value).isBefore(entityItem.carrierDateStart, 'day')) {
        this.snackService.showErrorMsg('Дата отправителя (до) не может быть меньше даты Перевозчика (от)');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').setValue(null);
      }

      if (moment(event.value).isAfter(entityItem.carrierDateEnd, 'day')) {
        this.snackService.showErrorMsg('Дата отправителя (до) не может быть больше даты Перевозчика (до)');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').setValue(null);
      }
    } else {
      if (!moment(event.value).isSame(entityItem.carrierDateEnd, 'day')) {
        this.snackService.showErrorMsg('Потребность отправителя должна быть равна предложению перевозчика');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerDateEnd').setValue(null);
      }
    }
  }
}
