import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalConfirmationRoutesPoints} from '../models/proposal-confirmation-routes-points';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsRoutesPointsAdapterService implements EntityAdapter<ProposalConfirmationRoutesPoints> {
  adaptIn(item: any): ProposalConfirmationRoutesPoints {
    if (item.initial) {
      return item
    } else {
      return new ProposalConfirmationRoutesPoints(
        item.id,
        item.code,
        item.name,
        item.carrierRoute,
        item.carrierCoordinates,
        Math.round(+item.radius * 100) / 100,
        item.ownerRoute,
      );
    }
  }

  adaptOut(item: ProposalConfirmationRoutesPoints): any {
    return {
      ...item
    };
  }
}
