import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatOption} from '@angular/material';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {EntityEditForm} from '../../../classes/entity-edit-form';
import {DocumentEquipment} from '../../../models/document-equipment';
import {TruckEquipment} from '../../../models/truck-equipment';
import {MetaDataCrud} from '../../../interfaces/meta-data-crud';
import {DocumentEquipmentService} from '../../../services/document-equipment.service';
import {TruckEquipmentService} from '../../../services/truck-equipment.service';
import {CommonValidatorsService} from '../../../services/common-validators.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ErrDialogComponent} from '../../err-dialog/err-dialog.component';

@Component({
  selector: 'app-order-form-equipment-add-form',
  templateUrl: './document-equipment-add-form.component.html',
  styleUrls: ['./document-equipment-add-form.component.scss']
})
export class DocumentEquipmentAddFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: DocumentEquipment;
  entityForm: FormGroup;
  pending: boolean;
  truckEquipmentList: TruckEquipment[] = [];
  equipmentName: string;
  protected metaData: MetaDataCrud[] = this.getMetaData();

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              protected entityService: DocumentEquipmentService,
              protected truckEquipmentService: TruckEquipmentService,
              private validatorsService: CommonValidatorsService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<DocumentEquipmentAddFormComponent>) {
    super();
  }

  get equipmentId() {
    return this.entityForm.get('equipmentId');
  }

  getMetaData() {
    return [
      {
        entityField: 'equipmentId',
        formField: 'equipmentId',
        validators: [Validators.required]
      },
      {
        entityField: 'price',
        formField: 'price'
      },
      {
        entityField: 'quantity',
        formField: 'quantity'
      },
      {
        entityField: 'total',
        formField: 'total',
      },
      {
        entityField: 'description',
        formField: 'description',
      },
    ];
  }

  ngOnInit() {
    this.pending = true;

    this.initForm();

    forkJoin(
      this.truckEquipmentService.getList(),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([truckEquipmentData]) => {
      this.truckEquipmentList = truckEquipmentData.list;
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg(error);
      });
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  onEquipmentChange($event) {
    if ($event.value) {
      this.pending = true;

      const selectedData = {
        text: ($event.source.selected as MatOption).viewValue,
        value: $event.source.value
      };

      this.equipmentName = selectedData.text;

      //if (this.equipmentName === 'Гидроборт') {
        this.entityForm.get('quantity').setValue(1);
      //}

      this.truckEquipmentService.getOneClientMode(this.data.masterEntityId, this.data.documentType, this.data.clientMode, $event.value)
        .pipe(
          finalize(() => this.pending = false),
        )
        .subscribe(data => {
          if (data.list.length) {
            this.entityForm.get('price').setValue(data.list[0].price);

            this.setTotal();
          }
        }, err => this.snackService.showErrorMsg(err));
    }
  }

  setTotal() {
    const price = this.entityForm.get('price').value;
    const quantity = this.entityForm.get('quantity').value;

    this.entityForm.get('total').setValue(price * quantity);
  }

  save() {
    if (!this.entityForm.valid) {
      return;
    }

    this.pending = true;

    this.entityItem = new DocumentEquipment();
    this.metaData.forEach(item => {
      if (item.skip) {
        return;
      }
      this.entityItem[item.entityField] = this.entityForm.get(item.formField).value;
    });

    this.entityItem.masterEntityId = this.data.masterEntityId;

    this.entityService.add(this.entityItem).pipe(
      finalize(() => this.pending = false)
    ).subscribe(success => {
        this.dialogRef.close({reload: true});
        this.toastService.success('Объект добавлен', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errors
      });
      }
    );
  }
}
