import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Proposal} from '../../../shared/models/proposal';
import {Organization} from '../../../shared/models/organization';
import {Model} from '../../../shared/models/model';
import {TruckType} from '../../../shared/models/truck-type';
import {CargoType} from '../../../shared/models/cargo-type';
import {LoadType} from '../../../shared/models/load-type';
import {Settings} from '../../../shared/models/settings';
import {ClientModes} from '../../../shared/enums/client-modes';
import {DocumentActionModes} from '../../../shared/enums/document-action-modes';
import {DocumentTypes} from '../../../shared/enums/document-types';
import {DocumentOrganizationRestrictions} from '../../../shared/enums/document-organization-restrictions';
import {Driver} from '../../../shared/models/driver';
import {Truck} from '../../../shared/models/truck';
import {ProposalStatus} from '../../../shared/enums/proposal-status';
import {ProposalStatusExecution} from '../../../shared/enums/proposal-status-execution';
import {ConfirmationFileTypes} from '../../../shared/enums/confirmation-file-types';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {User} from '../../../shared/models/user';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class MainFormService {
  private thisMainForm: FormGroup;
  private entityItemObservable: BehaviorSubject<Proposal> = new BehaviorSubject<Proposal>(null);
  entityItem: Proposal;
  currentUser: User;
  organizationsList: Organization[] = [];
  documentTypesList: Model[] = [];
  auctionStepsList: Model[] = [];
  statusList: Model[] = [];
  truckTypesList: TruckType[] = [];
  cargoTypesList: CargoType[] = [];
  loadTypesList: LoadType[] = [];
  taxVatList: Model[] = [];
  paymentDeferTypeList: Model[] = [];
  operatorDriversList: Driver[] = [];
  operatorTrucksList: Truck[] = [];
  carrierDriversList: Driver[] = [];
  carrierTrucksList: Truck[] = [];
  settings: Settings;
  proposalId: string;
  clientMode: ClientModes;
  clientModes = ClientModes;
  documentActionMode: DocumentActionModes;
  documentType: DocumentTypes;
  statuses = ProposalStatus;
  status: ProposalStatus;
  statusExecution: ProposalStatusExecution;
  winBidSum: number;
  equipmentSum: number;
  deferSum: number;
  totalSumInsuranceAdd: number;

  documentTypes = DocumentTypes;
  restrictionTypes = DocumentOrganizationRestrictions;
  confirmationFileTypes = ConfirmationFileTypes;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              protected storage: LocalStorageService) {
    this.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.entityItem = data;
          }
        }
      );
    this.currentUser = this.storage.retrieve('currentUser');
  }

  ngOnDestroy() {}

  getEntityItemObservable(): Observable<Proposal> {
    return this.entityItemObservable;
  }

  setEntityItem(value: Proposal) {
    this.entityItemObservable.next(value);
  }

  get readOnly() {
    if (!this.entityItem) {
      return true;
    }
    if (this.entityItem.permissions && this.entityItem.permissions.hasOwnProperty('all') && !this.entityItem.permissions.all) {
      return true;
    }
    return ![DocumentActionModes.add, DocumentActionModes.edit].includes(this.documentActionMode);
  }

  get insuranceReadOnly() {
    return !([DocumentActionModes.view_completed_bids].includes(this.documentActionMode) &&
      [ClientModes.operator].includes(this.clientMode));
  }

  get confirmationReadOnly() {
    if (![DocumentActionModes.view_completed_bids].includes(this.documentActionMode)) {
      return true;
    }

    if ([ClientModes.owner].includes(this.clientMode) && this.statusExecution !== ProposalStatusExecution.WAITING_CONFIRMATION_BY_OWNER) {
      return true;
    }

    if ([ClientModes.carrier].includes(this.clientMode)) {
      return true;
    }

    if ([ClientModes.operator].includes(this.clientMode)) {
      return true;
    }

    if (this.entityItem.permissions) {
      if (this.entityItem.permissions.hasOwnProperty('all') && !this.entityItem.permissions.all) {
        return true;
      }
      if (this.entityItem.permissions.hasOwnProperty('confirmation') && !this.entityItem.permissions.confirmation) {
        return true;
      }
    }

    return false;
  }

  get ownerConfirmationCargoDocReadOnly() {
    if (this.confirmationReadOnly) {
      return true;
    }
    // if (this.entityItem.organizationId !== this.currentUser.organizationId) {
    //   return true;
    // }
    return false;
  }

  get mainForm() {
    return this.thisMainForm;
  }
  set mainForm(form: FormGroup) {
    this.thisMainForm = form;
  }


  get tabGeneralForm(): FormGroup {
    return this.mainForm.get('tabGeneralForm') as FormGroup;
  }
  set tabGeneralForm(form: FormGroup) {
    this.mainForm.controls.tabGeneralForm = form;
  }


  get tabParametersForm(): FormGroup {
    return this.mainForm.get('tabParametersForm') as FormGroup;
  }
  set tabParametersForm(form: FormGroup) {
    this.mainForm.controls.tabParametersForm = form;
  }


  get tabCalcForm(): FormGroup {
    return this.mainForm.get('tabCalcForm') as FormGroup;
  }
  set tabCalcForm(form: FormGroup) {
    this.mainForm.controls.tabCalcForm = form;
  }


  get tabBidsForm(): FormGroup {
    return this.mainForm.get('tabBidsForm') as FormGroup;
  }
  set tabBidsForm(form: FormGroup) {
    this.mainForm.controls.tabBidsForm = form;
  }


  get tabCheckForm(): FormGroup {
    return this.mainForm.get('tabCheckForm') as FormGroup;
  }
  set tabCheckForm(form: FormGroup) {
    this.mainForm.controls.tabCheckForm = form;
  }


  get tabInsuranceForm(): FormGroup {
    return this.mainForm.get('tabInsuranceForm') as FormGroup;
  }
  set tabInsuranceForm(form: FormGroup) {
    this.mainForm.controls.tabInsuranceForm = form;
  }


  get tabDocumentsForm(): FormGroup {
    return this.mainForm.get('tabDocumentsForm') as FormGroup;
  }
  set tabDocumentsForm(form: FormGroup) {
    this.mainForm.controls.tabDocumentsForm = form;
  }


  get tabDocumentsConfirmationForm(): FormGroup {
    return this.mainForm.get('tabDocumentsConfirmationForm') as FormGroup;
  }
  set tabDocumentsConfirmationForm(form: FormGroup) {
    this.mainForm.controls.tabDocumentsConfirmationForm = form;
  }


  get tabRequiredDocumentsForm(): FormGroup {
    return this.mainForm.get('tabRequiredDocumentsForm') as FormGroup;
  }
  set tabRequiredDocumentsForm(form: FormGroup) {
    this.mainForm.controls.tabRequiredDocumentsForm = form;
  }


  get tabConfirmationForm(): FormGroup {
    return this.mainForm.get('tabConfirmationForm') as FormGroup;
  }
  set tabConfirmationForm(form: FormGroup) {
    this.mainForm.controls.tabConfirmationForm = form;
  }


  get totalSum(): number {
    return Math.round(this.winBidSum + this.equipmentSum  + this.deferSum);
  }

  get totalSumOwner(): number {
    //let totalSumInsuranceAdd = 0

    // if (this.entityItem) {
    //   totalSumInsuranceAdd = this.entityItem.insuranceLimitSum *
    //     this.entityItem.insuranceRate *
    //     this.entityItem.orgComissionRate / 100 / 100;
    // }

    return Math.round(this.winBidSum + this.equipmentSum + this.deferSum);
  }

  get totalSumCarrier(): number {
    return Math.round(this.winBidSum /*+ this.equipmentSum*/);
  }

  get totalSumCarrierProposal(): number {
    return Math.round(this.winBidSum + this.equipmentSum);
  }

  get insurancePrice(): number {
    return Math.round(this.totalSumInsuranceAdd);
  }

  get isInOperationProgressDisabled(): boolean {
    if (!this.entityItem) {
      return true;
    }

    if (!this.entityItem.isInOperationProgressAvailable) {
      return true;
    }

    return this.entityItem.isInOperationProgressBefore;
  }
}
