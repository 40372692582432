import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {Driver} from '../models/driver';
import * as moment from 'moment';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class DriversAdapterService implements EntityAdapter<Driver> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): Driver {
    return new Driver(
      item.id,
      item.name,
      item.lastName,
      item.firstName,
      item.middleName,
      item.phone,
      item.passport,
      this.dateParserService.parseDate(item.passportDate),
      this.dateParserService.parseDate(item.birthDate),
      item.licenseNumber,
      this.dateParserService.parseDate(item.licenseDate),
      item.organization,
      item.organizationId,
    );
  }

  adaptOut(item: Driver): any {
    return {
      ...item,
      passportDate: item.passportDate ? moment(item.passportDate).format('DD.MM.YYYY') : null,
      birthDate: item.birthDate ? moment(item.birthDate).format('DD.MM.YYYY') : null,
      licenseDate: item.licenseDate ? moment(item.licenseDate).format('DD.MM.YYYY') : null
    };
  }
}
