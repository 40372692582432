import {Component, OnDestroy, OnInit} from '@angular/core';
import {ColConfig} from '../../interfaces/col-config';
import {ProposalEquipmentConfirmedDatasource} from './proposal-equipment-confirmed.datasource';
import {TableComponent} from '../../classes/table-component';
import {DocumentEquipment} from '../../models/document-equipment';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../services/config.service';
import {DocumentEquipmentService} from '../../services/document-equipment.service';
import {MainFormService as OrderMainFormService} from '../../../modules/order-form/services/main-form.service';
import {MainFormService as ProposalMainFormService} from '../../../modules/proposal-form/services/main-form.service';
import {ErrorService} from '../../services/error.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {colsConfig} from './config/cols.config';

@Component({
  selector: 'app-proposal-equipment-confirmed',
  templateUrl: './proposal-equipment-confirmed.component.html',
  styleUrls: ['./proposal-equipment-confirmed.component.scss']
})
export class ProposalEquipmentConfirmedComponent extends TableComponent<DocumentEquipment, ProposalEquipmentConfirmedDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name', 'price', 'quantity', 'ownerConfirmationQuantity', 'total', 'totalOwnerConfirmation', 'options'];
  storageKey = 'app-proposal-equipment-confirmed';
  dataSource: ProposalEquipmentConfirmedDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;

  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: DocumentEquipmentService,
              private orderMainFormService: OrderMainFormService,
              private proposalMainFormService: ProposalMainFormService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    this.entityService.clientMode = this.proposalMainFormService.clientMode;
    this.entityService.documentType = this.proposalMainFormService.documentType;

    this.proposalMainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.masterEntityId = data.id;
            this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
          }
        }
      );
  }

  ngOnDestroy() {}

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

  initDataSource() {
    this.dataSource = new ProposalEquipmentConfirmedDatasource(this.entityService, this.errorService, this.snackService);
  }
}
