import {Model} from './model';

export class ProposalConfirmationRoutesContacts extends Model {
  constructor(
    public id?: string,
    public code?: string,
    public name?: string,
    public ownerContactsPhone1?: string,
    public ownerContactsPhone2?: string,
    public ownerContactsPerson?: string,
  ) {
    super('', name);
  }
}
