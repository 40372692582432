import {MatPaginatorIntl} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Paginator extends MatPaginatorIntl {
  itemsPerPageLabel;
  nextPageLabel;
  previousPageLabel;

  constructor() {
    super();
    this.itemsPerPageLabel = 'Элементов на странице';
    this.nextPageLabel = 'Следующая страница';
    this.previousPageLabel = 'Предыдущая страница';
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (!length || !pageSize) { return `0 из 0`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1}–${endIndex} из ${length}`;
  }
}
