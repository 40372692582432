import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {OrderDocumentsService} from '../../../../shared/services/order-documents.service';
import {ToastrService} from 'ngx-toastr';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {DateAdapter, MatDialog, NativeDateAdapter} from '@angular/material';
import {OrderDocument} from '../../../../shared/models/order-document';
import {finalize} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import * as moment from 'moment';
import {ErrDialogComponent} from '../../../../shared/components/err-dialog/err-dialog.component';

@Component({
  selector: 'app-order-form-tab-insurance',
  templateUrl: './tab-insurance.component.html',
  styleUrls: ['./tab-insurance.component.scss']
})
export class TabInsuranceComponent extends EntityEditForm implements OnInit, OnDestroy {
  pending = false;
  masterEntityId: string;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              protected entityService: OrderDocumentsService,
              private toastService: ToastrService,
              private snackService: SnackBarService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private dialog: MatDialog) {
    super();
    dateAdapter.setLocale('ru-RU');

    this.pending = true;

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.pending = false;
            this.masterEntityId = data.id;
          }
        }
      );
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabInsuranceForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabInsuranceForm = form;
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      insuranceCreationDate: [''],
      insuranceStartDate: [''],
      insuranceStartTransportationDate: [''],
      insuranceEndTransportationDate: [''],
      insuranceCargoTypeId: [''],
      insuranceBeneficiary: [''],
      insuranceTransportTypeId: [''],
      insuranceSeats: [''],
      insuranceWeight: [''],
      insuranceBeginningResponsibilityId: [''],
      insuranceNumber: [''],
      insuranceEndDate: [''],
      insurancePayDate: [''],
      insuranceCargoName: [''],
      insuranceCargoDoc: [''],
      insuranceCurrency: [''],
      insuranceLimitSum: [''],
      insurancePackageTypeId: [''],
      insuranceCarrierName: [''],
      insurancePointFrom: [''],
      insurancePointTo: [''],
      insuranceEndResponsibilityId: [''],
    });
  }

  getInsuranceNumber() {
    if (this.mainFormService.tabInsuranceForm.controls.insuranceLimitSum.value < this.mainFormService.settings['minInsuranceSum']) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Страховая сумма не может быть меньше ' + this.mainFormService.settings['minInsuranceSum'] + ' руб.'
        }
      });

      return;
    }

    const entityItem = new OrderDocument();
    const now = moment();

    this.mainFormService.tabInsuranceForm.controls.insuranceCreationDate.setValue(now.toDate());
    this.mainFormService.tabInsuranceForm.controls.insuranceStartDate.setValue(now.toDate());
    this.mainFormService.tabInsuranceForm.controls.insuranceEndDate.setValue(moment().add(29, 'days').toDate());
    this.mainFormService.tabInsuranceForm.controls.insurancePayDate.setValue(now.toDate());

    entityItem.id = this.masterEntityId;
    entityItem.driverId = this.mainFormService.tabCheckForm.controls.driverId.value;
    entityItem.truckId = this.mainFormService.tabCheckForm.controls.truckId.value;
    entityItem.insuranceCreationDate = this.mainFormService.tabInsuranceForm.controls.insuranceCreationDate.value;
    entityItem.insuranceStartDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartDate.value;
    entityItem.insuranceStartTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartTransportationDate.value;
    entityItem.insuranceEndTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndTransportationDate.value;
    entityItem.insuranceEndDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndDate.value;
    entityItem.insurancePayDate = this.mainFormService.tabInsuranceForm.controls.insurancePayDate.value;
    entityItem.insuranceLimitSum = this.mainFormService.tabInsuranceForm.controls.insuranceLimitSum.value;
    entityItem.insuranceSeats = this.mainFormService.tabInsuranceForm.controls.insuranceSeats.value;
    entityItem.insuranceWeight = this.mainFormService.tabInsuranceForm.controls.insuranceWeight.value;
    entityItem.insuranceCargoName = this.mainFormService.tabInsuranceForm.controls.insuranceCargoName.value;
    entityItem.insuranceCargoDoc = this.mainFormService.tabInsuranceForm.controls.insuranceCargoDoc.value;
    entityItem.insuranceCargoTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceCargoTypeId.value;
    entityItem.insuranceCurrency = this.mainFormService.tabInsuranceForm.controls.insuranceCurrency.value;
    entityItem.insuranceTransportTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceTransportTypeId.value;
    entityItem.insuranceBeneficiary = this.mainFormService.tabInsuranceForm.controls.insuranceBeneficiary.value;
    entityItem.insurancePackageTypeId = this.mainFormService.tabInsuranceForm.controls.insurancePackageTypeId.value;
    entityItem.insuranceCarrierName = this.mainFormService.tabInsuranceForm.controls.insuranceCarrierName.value;
    entityItem.insurancePointFrom = this.mainFormService.tabInsuranceForm.controls.insurancePointFrom.value;
    entityItem.insurancePointTo = this.mainFormService.tabInsuranceForm.controls.insurancePointTo.value;
    entityItem.insuranceBeginningResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceBeginningResponsibilityId.value;
    entityItem.insuranceEndResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceEndResponsibilityId.value;

    this.pending = true;

    this.entityService.getInsuranceNumber(entityItem)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          this.componentForm.controls.insuranceNumber.setValue(data.list[0].insuranceNumber);

          this.toastService.success('Полис оформлен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  downloadInsuranceNumber() {
    this.pending = true;

    this.entityService.getInsuranceFileLink(this.masterEntityId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(data => {
          const link = document.createElement('a');
          link.download = 'test';
          link.target = 'blank';
          link.href = data.list[0].file;
          link.click();
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }
}
