import { Injectable } from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import * as moment from 'moment';
import {Bid} from '../models/bid';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class BidsAdapterService implements EntityAdapter<Bid> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): Bid {
    return new Bid(
      item.id,
      item.masterEntityId,
      item.organizationId,
      item.organization,
      item.userId,
      this.dateParserService.parseDate(item.createdDate),
      Math.round(+item.price),
      JSON.parse(item.isWin),
    );
  }

  adaptOut(item: Bid): any {
    return {
      ...item,
      createdDate: item.createdDate ? moment(item.createdDate).format('DD.MM.YYYY') : null,
    };
  }
}
