import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {Proposal} from '../models/proposal';
import {ProposalAdapterService} from '../adapters/proposal-adapter.service';
import {OrderDocument} from '../models/order-document';
import {Driver} from '../models/driver';
import {Truck} from '../models/truck';
import {Model} from '../models/model';
import {DriversAdapterService} from '../adapters/drivers-adapter.service';
import {TrucksAdapterService} from '../adapters/trucks-adapter.service';
import {ModelAdapterService} from '../adapters/model-adapter.service';
import {ClientModes} from '../enums/client-modes';
import {ConfirmationFileTypes} from '../enums/confirmation-file-types';
import {DocumentTypes} from '../enums/document-types';

@Injectable({
  providedIn: 'root'
})
export class ProposalsService extends EntityCrudService<Proposal> {
  baseApiPath = 'carrier/proposals';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: ProposalAdapterService,
              protected driverAdapater: DriversAdapterService,
              protected truckAdapater: TrucksAdapterService,
              protected modelAdapater: ModelAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getOneClientMode(id: string, clientMode: ClientModes): Observable<EntityData<Proposal>> {
    const body = {
      id,
      clientMode
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/one_client_mode`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Proposal>))
      );
  }

  add(): Observable<EntityData<Proposal>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/add`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Proposal>))
      );
  }

  estimatePrice(proposalId: string, loadTypeIds: string[], sanitaryPassport: boolean): Observable<EntityData<number>> {
    const body = {
      proposalId,
      loadTypeIds,
      sanitaryPassport
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/estimate_price`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data
        } as EntityData<number>))
      );
  }

  createCopy(id: string, isTemplate:boolean = false): Observable<EntityData<Proposal>> {
    const body = {
      id,
      isTemplate
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/copy`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Proposal>))
      );
  }

  getOperatorDriversList(masterEntityId: string, documentType: DocumentTypes): Observable<EntityData<Driver>> {
    const body = {
      masterEntityId,
      documentType,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/drivers/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.driverAdapater.adaptIn(item))
            : []
        } as EntityData<Driver>))
      );
  }

  getCarrierDriversList(): Observable<EntityData<Driver>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/drivers/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.driverAdapater.adaptIn(item))
            : []
        } as EntityData<Driver>))
      );
  }

  getOperatorTrucksList(masterEntityId: string, documentType: DocumentTypes): Observable<EntityData<Truck>> {
    const body = {
      masterEntityId,
      documentType,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/trucks/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.truckAdapater.adaptIn(item))
            : []
        } as EntityData<Truck>))
      );
  }

  getCarrierTrucksList(): Observable<EntityData<Truck>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/trucks/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.truckAdapater.adaptIn(item))
            : []
        } as EntityData<Truck>))
      );
  }

  verifyDriver(masterEntityId: string, driverId: string, truckId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
      driverId,
      truckId
    };
    return this.http.post(`${this.configService.apiUrl}/operator/proposal/verify_driver`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  verifyDriver1(masterEntityId: string, driverId: string, truckId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
      driverId,
      truckId
    };
    return this.http.post(`${this.configService.apiUrl}/operator/proposal/verify_driver1`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  setDriverVerified(masterEntityId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/proposal/set_driver_verified`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  setDriver1Verified(masterEntityId: string): Observable<EntityData<Model>> {
    const body = {
      masterEntityId,
    };
    return this.http.post(`${this.configService.apiUrl}/operator/proposal/set_driver_verified1`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.modelAdapater.adaptIn(item))
            : []
        } as EntityData<Model>))
      );
  }

  getInsuranceNumber(entityItem: OrderDocument): Observable<EntityData<any>> {
    return this.http.post(`${this.configService.apiUrl}/operator/proposal/get_insurance_number`, this.entityAdapter.adaptOut(entityItem))
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  getInsuranceFileLink(masterEntityId: string): Observable<EntityData<any>> {
    const body = {
      masterEntityId
    };

    return this.http.post(`${this.configService.apiUrl}/operator/proposal/get_insurance_link`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  confirmDocument(masterEntityId: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes): Observable<EntityData<any>> {
    const body = {
      masterEntityId,
      clientMode,
      confirmationFileType,
    };

    return this.http.post(`${this.configService.apiUrl}/proposal/confirm_document`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  updateInsurance(entityItem: OrderDocument) {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/operator/proposal/update_insurance`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  updateCarrierConfirmedData(entityItem: OrderDocument) {
    const body = {
      ...this.entityAdapter.adaptOut(entityItem)
    };

    return this.http.post(`${this.configService.apiUrl}/carrier/proposal/updateCarrierConfirmedData`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  ownerConfirm(entityId: string, requestData: any): Observable<EntityData<Proposal>> {
    const body = {
      entityId,
      requestData
    };

    return this.http.post(`${this.configService.apiUrl}/owner/proposal/confirm`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Proposal>))
      );
  }

  carrierConfirm(entityId: string): Observable<EntityData<Proposal>> {
    const body = {
      entityId
    };

    return this.http.post(`${this.configService.apiUrl}/carrier/proposal/confirm`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Proposal>))
      );
  }

  confirmTransportData(masterEntityId: string, driverId: string, driver1Id: string, truckId: string): Observable<EntityData<any>> {
    const body = {
      masterEntityId,
      driverId,
      driver1Id,
      truckId,
    };

    return this.http.post(`${this.configService.apiUrl}/proposal/confirm_transport_data`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<any>))
      );
  }

  deleteConfirmationFile(masterEntityId: string, clientMode: ClientModes, confirmationFileType: ConfirmationFileTypes): Observable<EntityData<OrderDocument>> {
    const body = {
      masterEntityId,
      clientMode,
      confirmationFileType,
    };

    return this.http.post(`${this.configService.apiUrl}/proposal/delete_file`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderDocument>))
      );
  }
}
