import {Injectable} from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {Driver} from '../models/driver';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {DriversFilterAdapterService} from '../adapters/drivers-filter-adapter.service';
import {FilterAdminDriversModel} from '../interfaces/filter-admin-drivers.model';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {DriversAdapterService} from '../adapters/drivers-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class DriversService extends EntityCrudService<Driver> {
  baseApiPath = 'drivers';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: DriversAdapterService,
              protected filterAdapter: DriversFilterAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getListAdmin(filter: FilterAdminDriversModel, page = 1, limit = 100): Observable<EntityData<Driver>> {
    const body = {
      filter: this.filterAdapter.adaptOut(filter),
      page,
      limit,
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<Driver>))
      );
  }
}
