import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonValidatorsService {
  validEmail(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }
    const forbidden = !/^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
      .test(control.value);
    return forbidden ? { validEmail: { value: control.value } } : null;
  }

  passwordMatch(control: AbstractControl) {
    const password: string = control.get('password').value;
    const passwordConfirm: string = control.get('passwordConfirm').value;
    return password === passwordConfirm ? null : { passwordMatch: true };
  }

  routesMatch(control: AbstractControl) {
    let carrierRoute: string = control.get('carrierRoute').value;
    let ownerRoute: string = control.get('ownerRoute').value;
    carrierRoute = carrierRoute ? carrierRoute.trim().toLowerCase() : '';
    ownerRoute = ownerRoute ? ownerRoute.trim().toLowerCase() : '';
    return carrierRoute === ownerRoute ? null : { routesMatch: true };
  }

  validDate(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    return isNaN(Date.parse(control.value.split('.').reverse().join('-'))) ? { validDate: { value: control.value } } : null;
  }

  dateLTCurrentDate(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    const now = moment();
    return now.diff(control.value, 'days') > 0 ? { dateLTCurrentDate: { value: control.value } } : null;
  }

  numberNotEmpty(control: AbstractControl) {
    return !control.value || control.value === '0' ? { numberNotEmpty: { value: control.value } } : null;
  }

  numberMin(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value && +(control.value) < min 
        ? { numberMin: { value: control.value } } : null;
    }
  }
}
