import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {EntityCrudService} from '../classes/entity-crud-service';
import {ProposalRoute} from '../models/proposal-route';
import {ProposalRoutesAdapterService} from '../adapters/proposal-routes-adapter.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProposalRoutesService extends EntityCrudService<ProposalRoute> {
  baseApiPath = 'carrier/proposal/routes';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: ProposalRoutesAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getList(proposalId, page = 1, limit = 100): Observable<EntityData<ProposalRoute>> {
    const body = {
      proposalId: proposalId ? proposalId : 0,
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<ProposalRoute>))
      );
  }

  addCheck(entityItem: ProposalRoute, docDateEnd: Date) {
    const body = {
      docDateEnd: docDateEnd ? moment(docDateEnd).format('DD.MM.YYYY') : null,
      ...this.entityAdapter.adaptOut(entityItem)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/addCheck`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  updateCheck(entityItem: ProposalRoute, docDateEnd: Date) {
    const body = {
      docDateEnd: docDateEnd ? moment(docDateEnd).format('DD.MM.YYYY') : null,
      ...this.entityAdapter.adaptOut(entityItem)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/updateCheck`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
