import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import * as moment from 'moment';
import {OrderDocument} from '../models/order-document';

@Injectable({
  providedIn: 'root'
})
export class OrderDocumentAdapterService implements EntityAdapter<OrderDocument> {
  adaptIn(item: any): OrderDocument {
    return new OrderDocument(
      item.id,
      item.name,
      item.docDate ? new Date(item.docDate) : null,
      item.docNumber,
      item.type,
      item.status,
      item.statusExecution,
      Math.round(+item.price),
      Math.round(+item.estimatedPrice),
      Math.round(+item.resultPrice),
      Math.round(+item.totalPrice),
      Math.round(+item.declaredPrice),
      Math.round(+item.insurancePrice),
      Math.round(+item.siteComissionRate),
      Math.round(+item.deferPrice),
      item.step,
      item.organizationId,
      item.userId,
      item.docDateStart ? new Date(item.docDateStart) : null,
      item.docDateEnd ? new Date(item.docDateEnd) : null,
      item.comment,
      item.truckTypeIds,
      item.truckTypeOther,
      item.cargoTypeId,
      item.cargoTypeOther,
      Math.round(+item.weight),
      JSON.parse(item.addCargo),
      JSON.parse(item.sanitaryPassport),
      JSON.parse(item.isDriverVerified),
      JSON.parse(item.isDriver1Verified),
      JSON.parse(item.isTransportDataConfirmed),
      item.taxVat,
      Math.round(+item.capacity),
      item.paymentDeferType,
      Math.round(+item.paymentDeferDays),
      item.loadTypeIds,
      item.driverId,
      item.driver1Id,
      item.truckId,
      item.insuranceNumber,
      item.insuranceId,
      item.insuranceCreationDate ? new Date(item.insuranceCreationDate) : null,
      item.insuranceStartDate ? new Date(item.insuranceStartDate) : null,
      item.insuranceStartTransportationDate ? new Date(item.insuranceStartTransportationDate) : null,
      item.insuranceEndTransportationDate ? new Date(item.insuranceEndTransportationDate) : null,
      item.insuranceEndDate ? new Date(item.insuranceEndDate) : null,
      item.insurancePayDate ? new Date(item.insurancePayDate) : null,
      item.insuranceCargoName,
      item.insuranceCargoDoc,
      item.insuranceCargoTypeId,
      item.insuranceCurrency,
      Math.round(+item.insuranceLimitSum),
      item.insuranceTransportTypeId,
      item.insuranceBeneficiary,
      item.insurancePackageTypeId,
      item.packageTypeId,
      item.insuranceCarrierName,
      Math.round(+item.insuranceSeats),
      Math.round(+item.seats),
      Math.round(+item.insuranceWeight),
      item.insurancePointFrom,
      item.insurancePointTo,
      item.insuranceBeginningResponsibilityId,
      item.insuranceEndResponsibilityId,
      JSON.parse(item.isLoadingLift),
      JSON.parse(item.isRequiredDocumentType1),
      JSON.parse(item.isRequiredDocumentType3),
      JSON.parse(item.isRequiredDocumentType4),
      JSON.parse(item.isRequiredDocumentType5),
      JSON.parse(item.isRequiredDocumentType6),
      JSON.parse(item.isRequiredDocumentType7),
      JSON.parse(item.isRequiredDocumentType8),
      item.requiredDocumentTypeOther,
      JSON.parse(item.isProvidedDocumentType1),
      JSON.parse(item.isProvidedDocumentType2),
      item.providedDocumentTypeOther,
      item.ownerStep1FileName,
      item.ownerStep1FileUid,
      JSON.parse(item.isOwnerStep1FileConfirmed),
      item.carrierStep1FileName,
      item.carrierStep1FileUid,
      JSON.parse(item.isCarrierStep1FileConfirmed),
      item.carrierStep2FileName1,
      item.carrierStep2FileUid1,
      item.carrierStep2FileName2,
      item.carrierStep2FileUid2,
      item.carrierStep2FileName3,
      item.carrierStep2FileUid3,
      JSON.parse(item.isCarrierStep2FileConfirmed),
      item.senderName,
      item.senderInn,
      item.senderAddress,
      item.recipientName,
      item.recipientInn,
      item.recipientAddress,
      item.payerName,
      item.payerInn,
      item.payerAddress,
      item.ownerConfirmationCargoDoc,
      Math.round(+item.totalPriceCarrier),
      item.permissions,
      item.containerNumber,
      item.containerType,
      item.isTrunk,
      item.isInOperationProgress,
      item.isInOperationProgressBefore,
      item.isInOperationProgressAvailable,
    );
  }

  adaptOut(item: OrderDocument): any {
    return {
      ...item,
      docDate: item.docDate ? moment(item.docDate).format('DD.MM.YYYY') : null,
      docDateStart: item.docDateStart ? moment(item.docDateStart).format('DD.MM.YYYY') : null,
      docDateEnd: item.docDateEnd ? moment(item.docDateEnd).format('DD.MM.YYYY') : null,
      insuranceCreationDate: item.insuranceCreationDate ? moment(item.insuranceCreationDate).format('DD.MM.YYYY') : null,
      insuranceStartDate: item.insuranceStartDate ? moment(item.insuranceStartDate).format('DD.MM.YYYY') : null,
      insuranceStartTransportationDate: item.insuranceStartTransportationDate ? moment(item.insuranceStartTransportationDate).format('DD.MM.YYYY') : null,
      insuranceEndTransportationDate: item.insuranceEndTransportationDate ? moment(item.insuranceEndTransportationDate).format('DD.MM.YYYY') : null,
      insuranceEndDate: item.insuranceEndDate ? moment(item.insuranceEndDate).format('DD.MM.YYYY') : null,
      insurancePayDate: item.insurancePayDate ? moment(item.insurancePayDate).format('DD.MM.YYYY') : null,
    };
  }
}
