import {MetaDataCrud} from '../interfaces/meta-data-crud';
import {Model} from '../models/model';
import {FormModes} from '../enums/form-modes';

export class EntityEditForm {
  protected metaData: MetaDataCrud[] = [];
  protected entityItem: Model;
  protected formMode: FormModes;

  getFormPatchObject() {
    const patchObject = {};
    this.metaData.forEach(item => {
      patchObject[item.formField] = this.entityItem[item.entityField];
    });
    return patchObject;
  }

  getFormInitObject() {
    const initObject = {};
    this.metaData.forEach(item => {
      initObject[item.formField] = [];
      if (item.value) {
        initObject[item.formField].push(item.value);
      }
      if (item.validators) {
        initObject[item.formField].push('');
        initObject[item.formField].push(item.validators);
      }
    });
    return initObject;
  }

  getFormModeTitle() {
    switch (this.formMode) {
      case FormModes.add:
        return 'добавление'
        break;
      case FormModes.edit:
        return 'изменение'
        break;
      case FormModes.readOnly:
        return 'просмотр'
        break;
      default:
        return '';
        break;
    }
    return '';
  }
}
