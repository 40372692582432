import {Model} from './model';

export class Truck extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public organization?: string,
    public organizationId?: string,
    public regNumber?: string,
    public vin?: string,
    public sts?: string,
    public truckType?: string,
    public truckTypeId?: string,
    public weight?: number,
    public truckTypeOther?: string,
    public capacity?: number,
  ) {
    super(id, name);
  }
}
