import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  defaultDuration = 5000;
  messagesHeaders: { [key: string]: string; } = {
    SUCCESS: 'Успешно!',
    WARNING: 'Внимание!',
    ERROR: 'Ошибка!'
  };

  constructor(private snackBar: MatSnackBar) {}

  showSuccessMsg(message, duration: number = this.defaultDuration) {
    const alertMessage = `${this.messagesHeaders['SUCCESS']} ${message}`;
    this.snackBar.open(alertMessage, '', { duration: duration });
  }

  showErrorMsg(message, duration: number = this.defaultDuration) {
    this.snackBar
    const alertMessage = `${this.messagesHeaders['ERROR']} ${message}`;
    this.snackBar.open(alertMessage, 'x', { duration: duration });
  }

  showWarningMsg(message, duration: number = this.defaultDuration) {
    const alertMessage = `${this.messagesHeaders['WARNING']} ${message}`;
    this.snackBar.open(alertMessage, '', { duration: duration });
  }
}
