import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../classes/table-component';
import {Bid} from '../../models/bid';
import {ColConfig} from '../../interfaces/col-config';
import {DocumentTypes} from '../../enums/document-types';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../services/config.service';
import {BidsService} from '../../services/bids.service';
import {MainFormService as OrderMainFormService} from '../../../modules/order-form/services/main-form.service';
import {ErrorService} from '../../services/error.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {MainFormService as ProposalMainFormService} from '../../../modules/proposal-form/services/main-form.service';
import {ConfirmDialogData} from '../../interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../confirm-popup/confirm-popup.component';
import {colsConfig} from './config/cols.config';
import {DocumentBidsDatasource} from './document-bids.datasource';
import {DocumentActionModes} from '../../enums/document-action-modes';
import {ClientModes} from '../../enums/client-modes';
import {DocumentBidDialogComponent} from '../document-bid-dialog/document-bid-dialog.component';
import {interval} from 'rxjs';
import {Permissions} from '../../interfaces/permissions';

@Component({
  selector: 'app-document-bids',
  templateUrl: './document-bids.component.html',
  styleUrls: ['./document-bids.component.scss']
})
export class DocumentBidsComponent extends TableComponent<Bid, DocumentBidsDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['organization', 'createdDate', 'price', 'options'];
  storageKey = 'app-document-bids' + '-' + this.orderMainFormService.clientMode + '-' + this.orderMainFormService.documentType;
  dataSource: DocumentBidsDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;
  clientModes = ClientModes;
  documentTypes = DocumentTypes;
  permissions: Permissions;

  @Input() documentType: DocumentTypes;

  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: BidsService,
              private orderMainFormService: OrderMainFormService,
              private proposalMainFormService: ProposalMainFormService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    if (this.documentType === DocumentTypes.proposal) {
      this.entityService.clientMode = this.proposalMainFormService.clientMode;
      this.entityService.documentType = this.proposalMainFormService.documentType;

      this.proposalMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.permissions = data.permissions;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    } else {
      this.entityService.clientMode = this.orderMainFormService.clientMode;
      this.entityService.documentType = this.orderMainFormService.documentType;

      this.orderMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.permissions = data.permissions;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    }

    interval(30000)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => this.reload());
  }

  get readOnly() {
    if (this.documentType === DocumentTypes.order && this.entityService.clientMode !== ClientModes.owner) {
      return true;
    }

    if (this.documentType === DocumentTypes.proposal && this.entityService.clientMode !== ClientModes.carrier) {
      return true;
    }

    if (this.documentType === DocumentTypes.order) {
      return ![DocumentActionModes.view_bids, DocumentActionModes.view_bids_fixed_price].includes(this.orderMainFormService.documentActionMode);
    } else {
      return ![DocumentActionModes.view_bids, DocumentActionModes.view_bids_fixed_price].includes(this.proposalMainFormService.documentActionMode);
    }
  }

  ngOnDestroy() {}

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

  initDataSource() {
    this.dataSource = new DocumentBidsDatasource(this.entityService, this.errorService, this.snackService);
  }

  get componentForm(): FormGroup {
    if (this.documentType === DocumentTypes.order) {
      return this.orderMainFormService.tabBidsForm;
    } else {
      return this.proposalMainFormService.tabBidsForm;
    }
  }

  set componentForm(form: FormGroup) {
    if (this.documentType === DocumentTypes.order) {
      this.orderMainFormService.tabBidsForm = form;
    } else {
      this.proposalMainFormService.tabBidsForm = form;
    }
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      insurancePrice: [''],
      deferPrice: [''],
    });
  }

  openWinDialog(entityItem: Bid) {
    const dialogData: ConfirmDialogData = {
      contentMsg: `Определить как выигрышную ставку ? Торги по заказу будут завершены`,
      btnsArray: [
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
        {
          message: 'Ок',
          action: () => {
            dialog.close();
            this.setBidWin(entityItem.id);
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  setBidWin(id: string): void {
    this.entityService.setBidWin(id).subscribe(success => {
        if (this.documentType === DocumentTypes.order) {
          this.router.navigateByUrl('/cab/owner/order/biddings_completed');
        } else {
          this.router.navigateByUrl('/cab/carrier/proposal/biddings_completed');
        }

        this.toastService.success('Ставка отмечена как выигрышная', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  openBidDialog() {
    const dialogRef = this.dialog.open(DocumentBidDialogComponent, {
      disableClose: true,
      data: {
        id: this.masterEntityId
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.action) {
        this.update();
      }
    });
  }

  reload() {
    this.update();
  }

  isBidDialogButtonVisible() {
    switch(this.entityService.clientMode) {
      case ClientModes.carrier:
        if (this.entityService.documentType !== DocumentTypes.order && this.entityService.documentType !== DocumentTypes.orderContainer && this.entityService.documentType !== DocumentTypes.orderTrunk) {
          return false;
        }

        if (this.permissions) {
          return this.permissions.bid;
        }

        break;

      case ClientModes.owner:
        if (this.entityService.documentType !== DocumentTypes.proposal) {
          return false;
        }

        if (this.permissions) {
          return this.permissions.bid;
        }

        break;

      default:
        return false;
    }
  }
}
