import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../classes/table-component';
import {DocumentOperator} from '../../models/document-operator';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {MainFormService as OrderMainFormService} from '../../../modules/order-form/services/main-form.service';
import {DocumentOperatorsService} from '../../services/document-operators.service';
import {ErrorService} from '../../services/error.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {DocumentTypes} from '../../enums/document-types';
import {ColConfig} from '../../interfaces/col-config';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {interval} from 'rxjs';
import {MainFormService as ProposalMainFormService} from '../../../modules/proposal-form/services/main-form.service';
import {colsConfig} from './config/cols.config';
import {DocumentOperatorsDatasource} from './document-operators.datasource';

@Component({
  selector: 'app-document-operators',
  templateUrl: './document-operators.component.html',
  styleUrls: ['./document-operators.component.scss']
})
export class DocumentOperatorsComponent extends TableComponent<DocumentOperator, DocumentOperatorsDatasource> implements OnInit, OnDestroy{
  @Input() documentType: DocumentTypes;

  displayedColumns = ['userName', 'actionDateTime', 'changes'];
  storageKey = 'app-document-operators';
  dataSource: DocumentOperatorsDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;
  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              protected storage: LocalStorageService,
              public orderMainFormService: OrderMainFormService,
              public proposalMainFormService: ProposalMainFormService,
              protected entityService: DocumentOperatorsService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    if (this.documentType === DocumentTypes.order) {
      this.entityService.documentType = this.orderMainFormService.documentType;

      this.orderMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    } else {
      this.entityService.documentType = this.proposalMainFormService.documentType;

      this.proposalMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    }

    interval(30000)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => this.reload());
  }

  ngOnDestroy() {}

  initDataSource() {
    this.dataSource = new DocumentOperatorsDatasource(this.entityService, this.errorService, this.snackService);
  }

  reload() {
    this.update();
  }

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

}
