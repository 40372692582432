import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutesUtilitiesService {
  baseApiPath = 'routes';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected errorService: ErrorService) {}

  getRoute(sourcePos: string, destinationPos: string): Observable<any> {
    const body = {
      sourcePos,
      destinationPos
    };

    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/get_route`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          routeId: response.data.list[0].routeId,
          apiKey: response.data.list[0].apiKey,
        } as any))
      );
  }
}
