import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableColFilterComponent } from './table-col-filter.component';
import { MatButtonModule, MatIconModule, MatMenuModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [TableColFilterComponent],
  exports: [TableColFilterComponent],
})
export class TableColFilterModule { }
