import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import * as moment from 'moment';
import {Proposal} from '../models/proposal';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalAdapterService implements EntityAdapter<Proposal> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): Proposal {
    return new Proposal(
      item.id,
      item.name,
      item.isArchive,
      this.dateParserService.parseDate(item.docDate),
      item.docNumber,
      item.type,
      item.status,
      item.statusExecution,
      Math.round(+item.price),
      Math.round(+item.estimatedPrice),
      Math.round(+item.resultPrice),
      Math.round(+item.totalPrice),
      Math.round(+item.declaredPrice),
      Math.round(+item.insurancePrice),
      Math.round(+item.siteComissionRate),
      Math.round(+item.orgComissionRate),
      Math.round(+item.deferPrice),
      Math.round(+item.totalSumInsuranceAdd),
      Number(item.insuranceRate),
      Math.round(+item.deferSum),
      item.step,
      item.organizationId,
      item.userId,
      item.winBidUserId,
      this.dateParserService.parseDate(item.docDateStart),
      this.dateParserService.parseDate(item.docDateEnd),
      item.comment,
      item.truckTypeIds,
      item.truckTypeOther,
      item.cargoTypeId,
      item.cargoTypeOther,
      Math.round(+item.weight),
      JSON.parse(item.addCargo),
      JSON.parse(item.sanitaryPassport),
      JSON.parse(item.isDriverVerified),
      JSON.parse(item.isDriver1Verified),
      JSON.parse(item.isTransportDataConfirmed),
      item.taxVat,
      Math.round(+item.capacity),
      item.paymentDeferType,
      Math.round(+item.paymentDeferDays),
      item.loadTypeIds,
      item.driverId,
      item.driver1Id,
      item.truckId,
      item.insuranceNumber,
      item.insuranceId,
      this.dateParserService.parseDate(item.insuranceCreationDate),
      this.dateParserService.parseDate(item.insuranceStartDate),
      this.dateParserService.parseDate(item.insuranceStartTransportationDate),
      this.dateParserService.parseDate(item.insuranceEndTransportationDate),
      this.dateParserService.parseDate(item.insuranceEndDate),
      this.dateParserService.parseDate(item.insurancePayDate),
      item.insuranceCargoName,
      item.insuranceCargoDoc,
      item.insuranceCargoTypeId,
      item.insuranceCurrency,
      Math.round(+item.insuranceLimitSum),
      item.insuranceTransportTypeId,
      item.insuranceBeneficiary,
      item.insurancePackageTypeId,
      item.insuranceCarrierName,
      Math.round(+item.insuranceSeats),
      Math.round(+item.seats),
      Math.round(+item.ownerSeats),
      Math.round(+item.insuranceWeight),
      item.insurancePointFrom,
      item.insurancePointTo,
      item.insuranceBeginningResponsibilityId,
      item.insuranceEndResponsibilityId,
      JSON.parse(item.isLoadingLift),
      JSON.parse(item.isRequiredDocumentType1),
      JSON.parse(item.isRequiredDocumentType2),
      item.requiredDocumentTypeOther,
      JSON.parse(item.isProvidedDocumentType1),
      JSON.parse(item.isProvidedDocumentType3),
      JSON.parse(item.isProvidedDocumentType4),
      JSON.parse(item.isProvidedDocumentType5),
      item.providedDocumentTypeOther,
      this.dateParserService.parseDate(item.confirmationSignDate),
      this.dateParserService.parseDate(item.confirmationStartDate),
      this.dateParserService.parseDate(item.confirmationEndDate),
      this.dateParserService.parseDate(item.confirmationPayDate),
      this.dateParserService.parseDate(item.confirmationStartTransportationDate),
      this.dateParserService.parseDate(item.confirmationEndTransportationDate),
      item.confirmationCargoName,
      item.confirmationCargoDoc,
      item.confirmationCurrency,
      Math.round(+item.confirmationLimitSum),
      item.confirmationBeneficiary,
      item.confirmationTransportTypeId,
      item.confirmationPackageTypeId,
      item.confirmationCarrierName,
      Math.round(+item.confirmationSeats),
      Math.round(+item.confirmationWeight),
      item.confirmationPointFrom,
      item.confirmationPointTo,
      item.confirmationBeginningResponsibilityId,
      item.confirmationEndResponsibilityId,
      Math.round(+item.winBidSum),
      Math.round(+item.equipmentSum),
      item.ownerStep1FileName,
      item.ownerStep1FileUid,
      JSON.parse(item.isOwnerStep1FileConfirmed),
      item.carrierStep1FileName,
      item.carrierStep1FileUid,
      JSON.parse(item.isCarrierStep1FileConfirmed),
      item.carrierStep2FileName1,
      item.carrierStep2FileUid1,
      item.carrierStep2FileName2,
      item.carrierStep2FileUid2,
      item.carrierStep2FileName3,
      item.carrierStep2FileUid3,
      JSON.parse(item.isCarrierStep2FileConfirmed),
      item.senderName,
      item.senderInn,
      item.senderAddress,
      item.recipientName,
      item.recipientInn,
      item.recipientAddress,
      item.payerName,
      item.payerInn,
      item.payerAddress,
      item.ownerConfirmationCargoDoc,
      item.permissions,
      item.cargoNote,
      item.isInOperationProgress,
      item.isInOperationProgressBefore,
      item.isInOperationProgressAvailable,
    );
  }

  adaptOut(item: Proposal): any {
    return {
      ...item,
      docDate: item.docDate ? moment(item.docDate).format('DD.MM.YYYY') : null,
      docDateStart: item.docDateStart ? moment(item.docDateStart).format('DD.MM.YYYY') : null,
      docDateEnd: item.docDateEnd ? moment(item.docDateEnd).format('DD.MM.YYYY') : null,
      insuranceCreationDate: item.insuranceCreationDate ? moment(item.insuranceCreationDate).format('DD.MM.YYYY') : null,
      insuranceStartDate: item.insuranceStartDate ? moment(item.insuranceStartDate).format('DD.MM.YYYY') : null,
      insuranceStartTransportationDate: item.insuranceStartTransportationDate ? moment(item.insuranceStartTransportationDate).format('DD.MM.YYYY') : null,
      insuranceEndTransportationDate: item.insuranceEndTransportationDate ? moment(item.insuranceEndTransportationDate).format('DD.MM.YYYY') : null,
      insuranceEndDate: item.insuranceEndDate ? moment(item.insuranceEndDate).format('DD.MM.YYYY') : null,
      insurancePayDate: item.insurancePayDate ? moment(item.insurancePayDate).format('DD.MM.YYYY') : null,
    };
  }
}
