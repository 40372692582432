import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {ErrorService} from "./error.service";
import {Observable} from "rxjs";
import {EntityData} from "../interfaces/entity-data";
import {map} from "rxjs/operators";
import {Model} from '../models/model';
import {TruckType} from '../models/truck-type';
import {EntityCrudService} from '../classes/entity-crud-service';
import {TruckTypesAdapterService} from '../adapters/truck-types-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class TruckTypesService extends EntityCrudService<TruckType> {
  baseApiPath = 'truck_types';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: TruckTypesAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getParentsList(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/parents/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  getTree(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/tree`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }

  getListCarrier(): Observable<EntityData<Model>> {
    const body = {};
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list_carrier`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => item)
            : []
        } as EntityData<Model>))
      );
  }
}
