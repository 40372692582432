import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {finalize} from 'rxjs/operators';
import {MainFormService} from '../../services/main-form.service';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {Proposal} from '../../../../shared/models/proposal';
import {ProposalsService} from '../../../../shared/services/proposals.service';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';

@Component({
  selector: 'app-proposal-form-tab-calc',
  templateUrl: './tab-calc.component.html',
  styleUrls: ['./tab-calc.component.scss']
})
export class TabCalcComponent extends EntityEditForm implements OnInit, OnDestroy {
  @Input() entityItem: Proposal;
  pending = false;
  proposalId: string;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              private proposalsService: ProposalsService,
              private snackService: SnackBarService,
              private validatorsService: CommonValidatorsService,) {
    super();

    this.mainFormService.getEntityItemObservable()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
          if (data) {
            this.proposalId = data.id;
          }
        }
      );
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabCalcForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabCalcForm = form;
  }

  get price() {
    return this.componentForm.get('price');
  }

  get taxVat() {
    return this.componentForm.get('taxVat');
  }

  get paymentDeferType() {
    return this.componentForm.get('paymentDeferType');
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      estimatedPrice: [],
      price: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
      taxVat: ['', Validators.required],
      paymentDeferType: ['', Validators.required],
      paymentDeferDays: [],
      totalPrice: [],
      insurancePrice: [],
      deferPrice: [],
      siteComissionRate: [],
    });
  }

  estimatePrice() {
    const loadTypeIds = this.mainFormService.tabParametersForm.controls.loadTypeIds.value;
    const sanitaryPassport = this.mainFormService.tabParametersForm.controls.sanitaryPassport.value;
    this.pending = true;

    this.proposalsService.estimatePrice(this.proposalId, loadTypeIds, sanitaryPassport)
      .pipe(
        finalize(() => this.pending = false)
      ).subscribe(data => {
      this.mainFormService.tabCalcForm.controls.estimatedPrice.setValue(0);

      if (data.list.length) {
        this.mainFormService.tabCalcForm.controls.estimatedPrice.setValue(data.list[0].toFixed(0));
      }
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg(error);
      });
    });
  }

  calcPriceData() {
    const settings = this.mainFormService.settings;

    const price = parseFloat(this.mainFormService.tabCalcForm.controls.price.value) || 0;
    const insurance = parseFloat(settings.insurance) || 0;
    const paymentDeferRate = parseFloat(settings.paymentDefer) || 0;
    const paymentDeferDays = parseFloat(this.mainFormService.tabCalcForm.controls.paymentDeferDays.value) || 0;
    const paymentDeferType = this.mainFormService.tabCalcForm.controls.paymentDeferType.value;
    let paymentDefer = 0;
    let deferPrice = 0;

    if (['DEFER_100_4'].includes(paymentDeferType)) {
      paymentDefer = 1;
    }

    deferPrice = price * paymentDefer * (paymentDeferRate * paymentDeferDays / 30) / 100;

    this.mainFormService.tabCalcForm.controls.insurancePrice.setValue(0);
    this.mainFormService.tabCalcForm.controls.deferPrice.setValue(deferPrice);

    let result = price + deferPrice;

    if (this.mainFormService.tabCalcForm.controls.taxVat.value === 'TAX_VAT_NOT_INCLUDED') {
      result *= 1.2;
    }

    this.mainFormService.tabCalcForm.controls.totalPrice.setValue(result.toFixed(0));
  }
}
