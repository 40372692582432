import {Model} from './model';

export class DocumentOperator extends Model {
  constructor(
    public id?: string,
    public userName?: string,
    public masterEntityId?: string,
    public actionDateTime?: Date,
    public changes?: string,
  ) {
    super(id, name);
  }
}
