import { Component, OnInit } from '@angular/core';
import {DocumentTypes} from '../../../../shared/enums/document-types';

@Component({
  selector: 'app-order-form-tab-bids',
  templateUrl: './tab-bids.component.html',
  styleUrls: ['./tab-bids.component.scss']
})
export class TabBidsComponent implements OnInit {
  documentTypes = DocumentTypes;

  constructor() { }

  ngOnInit() {
  }

}
