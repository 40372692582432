import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';

@Component({
  selector: 'app-order-form-tab-parameters',
  templateUrl: './tab-parameters.component.html',
  styleUrls: ['./tab-parameters.component.scss']
})
export class TabParametersComponent extends EntityEditForm implements OnInit {
  documentTypes = DocumentTypes;
  currentTruckTypeIds: string[];

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              private validatorsService: CommonValidatorsService,) {
    super();
  }

  ngOnInit() {
    this.initForm();

    if (this.componentForm.controls.truckTypeIds && this.componentForm.controls.truckTypeIds.value) {
      this.currentTruckTypeIds = this.componentForm.controls.truckTypeIds.value.map(item => item.id);
    } else {
      this.currentTruckTypeIds = [];
    }
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabParametersForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabParametersForm = form;
  }

  get truckTypeIds() {
    return this.componentForm.get('truckTypeIds');
  }

  get cargoTypeId() {
    return this.componentForm.get('cargoTypeId');
  }

  get weight() {
    return this.componentForm.get('weight');
  }

  get capacity() {
    return this.componentForm.get('capacity');
  }

  initForm() {
    let cntrlsCfg = {
      weight: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
      cargoTypeId: ['', Validators.required],
      cargoTypeOther: [''],
      addCargo: [''],
      sanitaryPassport: [''],
      loadTypeIds: [''],
      containerType: [''],
    };
    if (this.mainFormService.documentType != DocumentTypes.orderContainer ){
      cntrlsCfg = {...cntrlsCfg, ...{
        packageTypeId: ['', Validators.required],
        truckTypeIds: ['', Validators.required],
        capacity: ['', [Validators.required, this.validatorsService.numberNotEmpty]],
        seats: [''],
      }}
    }
    this.componentForm = this.formBuilder.group(cntrlsCfg);

  }

  onTruckTypeIdsSelect(event) {
    let newIds = [];
    const selectedIds = event.value;
    let parentL1SelectionChanged: boolean;
    let parentL1SelectAllChildren: boolean;
    let parentL2SelectionChanged: boolean;
    let parentL2SelectAllChildren: boolean;

    this.mainFormService.truckTypesList.forEach(truckType => {
      if (truckType.level === 1) {
        parentL1SelectionChanged = false;
        parentL1SelectAllChildren = false;

        if (selectedIds.find(item => item === truckType.id) && !this.currentTruckTypeIds.find(item => item === truckType.id)) {
          parentL1SelectionChanged = true;
          parentL1SelectAllChildren = true;
        } else if (!selectedIds.find(item => item === truckType.id) && this.currentTruckTypeIds.find(item => item === truckType.id)) {
          parentL1SelectionChanged = true;
          parentL1SelectAllChildren = false;
        }
      }

      if (truckType.level === 2) {
        parentL2SelectionChanged = false;
        parentL2SelectAllChildren = false;

        if (parentL1SelectionChanged) {
          parentL2SelectionChanged = parentL1SelectionChanged;
          parentL2SelectAllChildren = parentL1SelectAllChildren;
        } else {
          if (selectedIds.find(item => item === truckType.id) && !this.currentTruckTypeIds.find(item => item === truckType.id)) {
            parentL2SelectionChanged = true;
            parentL2SelectAllChildren = true;
          } else if (!selectedIds.find(item => item === truckType.id) && this.currentTruckTypeIds.find(item => item === truckType.id)) {
            parentL2SelectionChanged = true;
            parentL2SelectAllChildren = false;
          }
        }
      }

      if (truckType.level === 1) {
        if (selectedIds.find(item => item === truckType.id)) {
          newIds.push(truckType.id);
        }
      } else if (truckType.level === 2) {
        if (parentL1SelectionChanged) {
          if (parentL1SelectAllChildren) {
            newIds.push(truckType.id);
          }
        } else if (selectedIds.find(item => item === truckType.id)) {
          newIds.push(truckType.id);
        }
      } else if (truckType.level === 3) {
        if (parentL2SelectionChanged) {
          if (parentL2SelectAllChildren) {
            newIds.push(truckType.id);
          }
        } else if (selectedIds.find(item => item === truckType.id)) {
          newIds.push(truckType.id);
        }
      }
    });

    newIds = Array.from(new Set(newIds));
    this.currentTruckTypeIds = newIds;
    this.componentForm.controls.truckTypeIds.setValue(newIds);
  }
}
