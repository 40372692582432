import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {GridCounterpartiesDatasource} from './grid-counterparties.datasource';
import {ProposalConfirmationCounterparties} from '../../../../../shared/models/proposal-confirmation-counterparties';
import {MainFormService} from '../../../services/main-form.service';
import {FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, NativeDateAdapter} from '@angular/material';
import {colsConfig} from './config/cols.config';
import {MatDialog} from '@angular/material';
import {Organization} from '../../../../../shared/models/organization';
import {OrganizationsService} from '../../../../../shared/services/organizations.service';
import {DadataService} from '../../../../../shared/services/dadata.service';

@Component({
  selector: 'app-proposal-confirmation-grid-counterparties',
  templateUrl: './grid-counterparties.component.html',
  styleUrls: ['./grid-counterparties.component.scss']
})
export class GridCounterpartiesComponent extends TableComponent<(ProposalConfirmationCounterparties), GridCounterpartiesDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['description', 'name_', 'inn', 'address', 'options'];
  storageKey = 'app-proposal-confirmation-grid-counterparties';
  dataSource: GridCounterpartiesDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  entityId: string;
  current: {
    org:Organization
  };

  colsShowConfig = {
    options: 'end'
  };

  constructor(private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              protected organizationsService: OrganizationsService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private dialog: MatDialog,
              protected dadataService: DadataService) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.counterpartiesForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.counterpartiesForm = form;
  }

  ngOnInit() {
    this.initForm();

    super.ngOnInit();

    this.initDataSource();
  }

  ngOnDestroy() {}

  initForm() {
    this.current = { org: undefined };
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          type: [''],
          description: [''],
          name: [''],
          inn: [''],
          address: [''],
        }),
        this.formBuilder.group({
          type: [''],
          description: [''],
          name: [''],
          inn: [''],
          address: [''],
        }),
        this.formBuilder.group({
          type: [''],
          description: [''],
          name: [''],
          inn: [''],
          address: [''],
        }),
      ])
    });
  }

  inputInn(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('inn')
  }

  inputAddress(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('address')
  }

  inputName(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('name')
  }

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm, this.current);
  }

  initDataSource() {

    this.dataSource = new GridCounterpartiesDatasource(this.entityService, this.errorService, this.snackService, this.organizationsService, this.storage );
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm, this.current);
  }

  setCurrentOrganization(index: number) {
    this.inputAddress(index).setValue(this.current.org.jurAddress);    
    this.inputName(index).setValue(this.current.org.name);
    this.inputInn(index).setValue(this.current.org.inn);
  }

  setOrganizationByInn(index: number) {
    this.dadataService.getOrganization(this.inputInn(index).value)
    .then(result => {
      if (!result || !result.suggestions || result.suggestions.length < 1)
      {
        this.snackService.showWarningMsg('Организация по указанному ИНН не найдена!');
        return;
      }      
      this.inputName(index).setValue(result.suggestions[0].value);
      this.inputAddress(index).setValue(result.suggestions[0].data.address.unrestricted_value);
      return result.suggestions[0];
    })
    .catch(error => {
      this.snackService.showErrorMsg('При проверке ИНН произошла ошибка!'); 
      console.error(error)
    });
  }
}
