import { Injectable } from '@angular/core';
import {EntityCrudService} from '../classes/entity-crud-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {LoadType} from '../models/load-type';
import {LoadTypesAdapterService} from '../adapters/load-types-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class LoadTypesService extends EntityCrudService<LoadType>{
  baseApiPath = 'load_types';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: LoadTypesAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }
}
