import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {EntityEditForm} from '../../../../../../shared/classes/entity-edit-form';
import {OrderRoute} from '../../../../../../shared/models/order-route';
import {MetaDataCrud} from '../../../../../../shared/interfaces/meta-data-crud';
import {OrderRoutesService} from '../../../../../../shared/services/order-routes.service';
import {CommonValidatorsService} from '../../../../../../shared/services/common-validators.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {RoutesUtilitiesService} from '../../../../../../shared/services/routes-utilities.service';
import ymaps from 'ymaps';
import {FormModes} from '../../../../../../shared/enums/form-modes';
import {ErrDialogComponent} from '../../../../../../shared/components/err-dialog/err-dialog.component';
(window as any).global = window;

@Component({
  selector: 'app-filter-route-point-form-form',
  templateUrl: './route-point-form.component.html',
  styleUrls: ['./route-point-form.component.scss']
})
export class RoutePointFormComponent extends EntityEditForm implements OnInit {
  protected entityItem: OrderRoute;
  entityForm: FormGroup;
  pending: boolean;
  protected metaData: MetaDataCrud[] = this.getMetaData();
  formModes = FormModes;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              protected entityService: OrderRoutesService,
              private validatorsService: CommonValidatorsService,
              private routesUtilitiesService: RoutesUtilitiesService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<RoutePointFormComponent>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get address() {
    return this.entityForm.get('address');
  }

  get longitude() {
    return this.entityForm.get('longitude');
  }

  get latitude() {
    return this.entityForm.get('latitude');
  }


  getMetaData() {
    return [
      {
        entityField: 'address',
        formField: 'address',
        validators: [Validators.required]
      },
      {
        entityField: 'longitude',
        formField: 'longitude',
      },
      {
        entityField: 'latitude',
        formField: 'latitude',
      },
    ];
  }

  ngOnInit() {
    this.initForm();

    this.entityForm.controls.address.setValue(this.data.address);
    this.entityForm.controls.longitude.setValue(this.data.longitude);
    this.entityForm.controls.latitude.setValue(this.data.latitude);

    this.loadYmaps();
  }

  loadYmaps() {
    const me = this;

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90').then(maps => {
      const addressSV = new maps.SuggestView('address');

      const myMap =  new maps.Map('map', {
          center: [
            this.entityForm.controls.longitude.value ? this.entityForm.controls.longitude.value : 55.76,
            this.entityForm.controls.latitude.value ? this.entityForm.controls.latitude.value : 37.64
          ],
          zoom: 10
        }, {
          searchControlProvider: 'yandex#search'
        });

      addressSV.events.add('select', (event) => {
        const address = event.originalEvent.item.value;

        me.entityForm.controls.address.setValue(address);
        me.entityForm.controls.longitude.setValue('');
        me.entityForm.controls.latitude.setValue('');

        // Ищем координаты указанного адреса
        // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode-docpage/
        const geocoder = maps.geocode(address);

        // После того, как поиск вернул результат, вызывается callback-функция
        geocoder.then(res => {
            // координаты объекта
            // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
            const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

            // Добавление метки (Placemark) на карту
            const placemark = new maps.Placemark(
              coordinates, {
                iconCaption: address,
              }, {
                preset: 'islands#redDotIcon'
              }
            );

            me.entityForm.controls.latitude.setValue(coordinates[0]);
            me.entityForm.controls.longitude.setValue(coordinates[1]);

            myMap.setCenter(
              coordinates,
              10
            );

            myMap.geoObjects.removeAll();
            myMap.geoObjects.add(placemark);
          }
        );
      });
    }).catch(error => {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: {
          title: 'Ошибка загрузки Яндекс карт'
        }
      });

      console.dir(error);
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group(this.getFormInitObject());
  }

  async save() {
    if (!this.entityForm.valid) {
      Object.keys(this.entityForm.controls).forEach(field => {
        const control = this.entityForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      return;
    }

    this.pending = true;

    const geoErrors = [];

    if (!this.entityForm.controls.latitude.value || !this.entityForm.controls.longitude.value) {
      const maps = await ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9f1644f0-359d-4a43-ad7e-0d7a28eeb6d2&suggest_apikey=fbd9ebc8-7a25-418e-a0bf-3551db9bcf90');
      const res = await maps.geocode(this.entityForm.controls.address.value);
      // координаты объекта
      // По умолчанию координаты возвращаются в последовательности: долгота, широта ('longlat');
      if (res.geoObjects.length) {
        const coordinates = res.geoObjects.get(0).geometry.getCoordinates();

        this.entityForm.controls.latitude.setValue(coordinates[0]);
        this.entityForm.controls.longitude.setValue(coordinates[1]);
      } else {
        geoErrors.push('Не удалось определить координаты адреса');
      }
    }

    if (geoErrors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: geoErrors
      });

      this.pending = false;
      return;
    }

    this.dialogRef.close({
      address: this.entityForm.controls.address.value,
      longitude: this.entityForm.controls.longitude.value,
      latitude: this.entityForm.controls.latitude.value,
    });
  }
}
