import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DateAdapter, MAT_DIALOG_DATA, MatDialog, MatDialogRef, NativeDateAdapter} from '@angular/material';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {OrderDocument} from '../../../../shared/models/order-document';
import {OrderDocumentsService} from '../../../../shared/services/order-documents.service';
import {OrganizationsService} from '../../../../shared/services/organizations.service';
import {CommonDictService} from '../../../../shared/services/common-dict.service';
import {TruckTypesService} from '../../../../shared/services/truck-types.service';
import {CargoTypesService} from '../../../../shared/services/cargo-types.service';
import {LoadTypesService} from '../../../../shared/services/load-types.service';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {DocumentActionModes} from '../../../../shared/enums/document-action-modes';
import {DocumentTypes} from '../../../../shared/enums/document-types';
import {ClientModes} from '../../../../shared/enums/client-modes';
import {OrderStatus} from '../../../../shared/enums/order-status';
import {ErrDialogComponent} from '../../../../shared/components/err-dialog/err-dialog.component';
import {OrderCommonDictService} from '../../../../shared/services/order-common-dict.service';
import {SignatureExampleDialogComponent} from '../../../../shared/components/signature-example-dialog/signature-example-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import {BidsService} from '../../../../shared/services/bids.service';

@Component({
  selector: 'app-order-main',
  templateUrl: './order-main.component.html',
  styleUrls: ['./order-main.component.scss']
})
export class OrderMainComponent implements OnInit {
  entityId: string;
  entityItem: OrderDocument;
  documentActionModes = DocumentActionModes;
  documentTypes = DocumentTypes;
  clientModes = ClientModes;
  pending = false;
  carrierId: number;
  isTemplate = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderDocumentsService: OrderDocumentsService,
              private organizationsService: OrganizationsService,
              private commonDictService: CommonDictService,
              private orderCommonDictService: OrderCommonDictService,
              private truckTypesService: TruckTypesService,
              private cargoTypesService: CargoTypesService,
              private loadTypesService: LoadTypesService,
              private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<OrderMainComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private overlay: Overlay,
              protected bidsService: BidsService,
              ) {
    dateAdapter.setLocale('ru-RU');
  }

  ngOnInit() {
    this.mainFormService.clientMode = this.data.clientMode;
    this.mainFormService.documentActionMode = this.data.documentActionMode;
    this.mainFormService.documentType = this.data.documentType ? this.data.documentType : DocumentTypes.order;
    this.mainFormService.orderId = this.data.entityId;
    this.isTemplate = this.data.isTemplate;

    this.entityId = this.data.entityId;

    this.bidsService.clientMode = this.mainFormService.clientMode;
    this.bidsService.documentType = this.mainFormService.documentType;

    this.pending = true;

    this.initForm();

    const orderDocumentObservable = this.entityId ? this.orderDocumentsService.getOneClientMode(this.entityId, this.mainFormService.clientMode) : this.orderDocumentsService.addWithType(this.mainFormService.documentType);

    forkJoin(
      orderDocumentObservable,
      this.organizationsService.getShortList(this.mainFormService.clientMode),
      this.orderCommonDictService.getDocumentTypesList(),
      this.orderCommonDictService.getAuctionStepsList(),
      this.orderCommonDictService.getStatusList(),
      this.truckTypesService.getTree(),
      this.cargoTypesService.getList(),
      this.loadTypesService.getList(),
      this.orderCommonDictService.getTaxVatList(),
      this.orderCommonDictService.getPaymentDeferTypeList(),
      this.commonDictService.getSettingsList(),
      this.orderDocumentsService.getOperatorDriversList(this.entityId, DocumentTypes.order),
      this.orderDocumentsService.getOperatorTrucksList(this.entityId, DocumentTypes.order),
      this.orderDocumentsService.getCarrierDriversList(),
      this.orderDocumentsService.getCarrierTrucksList(),
      this.orderDocumentsService.getCarrierTrucksList(),
      this.bidsService.getList(this.entityId),
      // this.fetchWinBid(this.entityId),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([entityData, organizationsData, documentTypesData, auctionStepsData, statusData,
                            truckTypesData, cargoTypesData, loadTypesData,
                          taxVatData, paymentDeferTypeData, settingsData, operatorDriversData,
                            operatorTrucksData, carrierDriversData, carrierTrucksData, ss, bidsData, ]) => {
      this.mainFormService.setEntityItemObservable(entityData.list[0]);
      this.entityItem = entityData.list[0];

      if (this.mainFormService.documentActionMode === DocumentActionModes.add) {
        this.entityItem.status = statusData.list[0].id;
        this.entityItem.paymentDeferType = paymentDeferTypeData.list[0].id;
      }

      if (this.entityItem.cargoTypeOther) {
        this.entityItem.cargoTypeId = '0';
      }

      this.mainFormService.organizationsList = organizationsData.list;
      this.mainFormService.documentTypesList = documentTypesData.list;
      this.mainFormService.auctionStepsList = auctionStepsData.list;

      switch (this.mainFormService.documentActionMode) {
        case DocumentActionModes.add:
          this.mainFormService.statusList = statusData.list.filter(item => [OrderStatus.draft, OrderStatus.bidding].includes(item.id));
          break;

        case DocumentActionModes.edit:
          this.mainFormService.statusList = statusData.list.filter(item => [OrderStatus.draft, OrderStatus.bidding].includes(item.id));
          break;

        default:
          this.mainFormService.statusList = statusData.list;
          break;
      }

      this.mainFormService.truckTypesList = truckTypesData.list;
      this.mainFormService.cargoTypesList = cargoTypesData.list;
      this.mainFormService.loadTypesList = loadTypesData.list;
      this.mainFormService.taxVatList = taxVatData.list;
      this.mainFormService.paymentDeferTypeList = paymentDeferTypeData.list;
      this.mainFormService.settings = settingsData.list[0];
      this.mainFormService.operatorDriversList = operatorDriversData.list;
      this.mainFormService.operatorTrucksList = operatorTrucksData.list;
      this.mainFormService.carrierDriversList = carrierDriversData.list;
      this.mainFormService.carrierTrucksList = carrierTrucksData.list;

      this.mainFormService.tabGeneralForm.patchValue(this.entityItem);

      if (this.mainFormService.tabCounterpartiesForm) {
        this.mainFormService.tabCounterpartiesForm.patchValue(this.entityItem);
      }

      this.mainFormService.tabParametersForm.patchValue(this.entityItem);

      if (this.mainFormService.tabCalcForm) {
        this.mainFormService.tabCalcForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabCheckForm) {
        this.mainFormService.tabCheckForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabInsuranceForm) {
        this.mainFormService.tabInsuranceForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabDocumentsForm) {
        this.mainFormService.tabDocumentsForm.patchValue(this.entityItem);
      }

      if (this.mainFormService.tabRequiredDocumentsForm) {
        this.mainFormService.tabRequiredDocumentsForm.patchValue(this.entityItem);
      }

      if (bidsData && bidsData.list) {
        const winBids = bidsData.list.filter(el => el.isWin);
        if (winBids.length > 0) {
          this.carrierId = winBids[0].organizationId;
        }
      }
    }, errors => {
      errors.map(error => {
        this.pending = false;
        this.snackService.showErrorMsg(error);
      });
    });
  }

  initForm() {
    this.mainFormService.mainForm = this.formBuilder.group({
      tabGeneralForm: this.formBuilder.group({}),
      tabParametersForm: this.formBuilder.group({}),
      tabCalcForm: this.formBuilder.group({}),
      tabBidsForm: this.formBuilder.group({}),
      tabOrderFilesForm: this.formBuilder.group({})
    });
  }

  async closeForm() {
    this.dialogRef.close();
    /*
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Закрыть окно заказа ?',
      btnsArray: [
        {
          message: 'Ок',
          action: () => {
            dialog.close();

            this.dialogRef.close();
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
     */
  }

  async openExample(orgId) {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(SignatureExampleDialogComponent, {
      data: { id: orgId },
      scrollStrategy,
      minWidth: '50vh',
    });
  }

  buttonOkIsDisabled() {
    if (this.pending) {
      return true;
    }
    if (!this.entityItem) {
      return true;
    }
    if (this.entityItem.permissions && this.entityItem.permissions.hasOwnProperty('all') && !this.entityItem.permissions.all) {
      return true;
    }
    if (![DocumentActionModes.add, DocumentActionModes.edit, DocumentActionModes.view_completed_bids].includes(this.mainFormService.documentActionMode)) {
      return true;
    }

    return false;
  }

  save() {
    if ([DocumentActionModes.add, DocumentActionModes.edit].includes(this.mainFormService.documentActionMode)) {
      if (!this.mainFormService.tabGeneralForm.valid ||
        !this.mainFormService.tabParametersForm.valid ||
        !this.mainFormService.tabCalcForm.valid ||
        !this.mainFormService.tabCounterpartiesForm.valid) {
        Object.keys(this.mainFormService.tabGeneralForm.controls).forEach(field => {
          const control = this.mainFormService.tabGeneralForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        Object.keys(this.mainFormService.tabParametersForm.controls).forEach(field => {
          const control = this.mainFormService.tabParametersForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        Object.keys(this.mainFormService.tabCalcForm.controls).forEach(field => {
          const control = this.mainFormService.tabCalcForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        Object.keys(this.mainFormService.tabCounterpartiesForm.controls).forEach(field => {
          const control = this.mainFormService.tabCounterpartiesForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });

        if (this.mainFormService.tabGeneralForm.valid &&
          this.mainFormService.tabParametersForm.valid &&
          this.mainFormService.tabCalcForm.valid &&
          !this.mainFormService.tabCounterpartiesForm.valid) {
            this.snackService.showWarningMsg('Обнаружены ошибки при заполнении данных о контрагентах. Проверьте правильность заполнения вкладки "Контрагенты"');
            return;
        }

        this.snackService.showWarningMsg('Обнаружены ошибки при заполнении данных. Проверьте правильность заполнения данной заявки на всех вкладках');
        return;
      }

      const errMsg = [];

      if (!this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType1.value &&
        !this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType2.value) {
        errMsg.push('В требованиях к документам должен быть отмечен хотя бы один из предоставляемых документов (ТТН или ТН и Торг 12)');
      }

      if (!!this.mainFormService.tabParametersForm.controls.seats) {
        if (
          !this.mainFormService.tabParametersForm.controls.seats.value
          || this.mainFormService.tabParametersForm.controls.seats.value === 0) {
          errMsg.push('В параметрах перевозки не указано Количество мест');
        }
      }

      if (errMsg.length) {
        const dialogRef = this.dialog.open(ErrDialogComponent, {
          data: errMsg
        });

        return;
      }

      this.pending = true;

      this.entityItem.docDate = this.mainFormService.tabGeneralForm.controls.docDate.value;
      this.entityItem.docNumber = this.mainFormService.tabGeneralForm.controls.docNumber.value;
      this.entityItem.type = this.mainFormService.tabGeneralForm.controls.type.value;
      this.entityItem.status = this.mainFormService.tabGeneralForm.controls.status.value;
      this.entityItem.containerNumber = this.mainFormService.tabGeneralForm.controls.containerNumber.value;
      this.entityItem.containerType = this.mainFormService.tabGeneralForm.controls.containerType && this.mainFormService.tabGeneralForm.controls.containerType.value;
      this.entityItem.price = this.mainFormService.tabCalcForm.controls.price.value;
      this.entityItem.estimatedPrice = this.mainFormService.tabCalcForm.controls.estimatedPrice.value;
      this.entityItem.totalPrice = this.mainFormService.tabCalcForm.controls.totalPrice.value;
      this.entityItem.declaredPrice = this.mainFormService.tabCalcForm.controls.declaredPrice.value;
      this.entityItem.insurancePrice = this.mainFormService.tabCalcForm.controls.insurancePrice.value;
      this.entityItem.siteComissionRate = this.mainFormService.tabCalcForm.controls.siteComissionRate.value;
      this.entityItem.deferPrice = this.mainFormService.tabCalcForm.controls.deferPrice.value;
      this.entityItem.step = this.mainFormService.tabGeneralForm.controls.step.value;
      this.entityItem.docDateStart = this.mainFormService.tabGeneralForm.controls.docDateStart.value;
      this.entityItem.docDateEnd = this.mainFormService.tabGeneralForm.controls.docDateEnd.value;
      this.entityItem.comment = this.mainFormService.tabGeneralForm.controls.comment.value;
      this.entityItem.truckTypeIds = this.mainFormService.tabParametersForm.controls.truckTypeIds && this.mainFormService.tabParametersForm.controls.truckTypeIds.value;
      this.entityItem.truckTypeOther = '';
      this.entityItem.cargoTypeId = this.mainFormService.tabParametersForm.controls.cargoTypeId.value;
      if (this.mainFormService.tabParametersForm.controls.cargoTypeId.value === '0') {
        this.entityItem.cargoTypeOther = this.mainFormService.tabParametersForm.controls.cargoTypeOther.value;
      } else {
        this.entityItem.cargoTypeOther = '';
      }
      this.entityItem.weight = this.mainFormService.tabParametersForm.controls.weight.value;
      this.entityItem.addCargo = this.mainFormService.tabParametersForm.controls.addCargo.value;
      this.entityItem.sanitaryPassport = this.mainFormService.tabParametersForm.controls.sanitaryPassport.value;
      this.entityItem.taxVat = this.mainFormService.tabCalcForm.controls.taxVat.value;
      this.entityItem.capacity = this.mainFormService.tabParametersForm.controls.capacity && this.mainFormService.tabParametersForm.controls.capacity.value;
      this.entityItem.paymentDeferType = this.mainFormService.tabCalcForm.controls.paymentDeferType.value;
      this.entityItem.paymentDeferDays = this.mainFormService.tabCalcForm.controls.paymentDeferDays.value;
      this.entityItem.loadTypeIds = this.mainFormService.tabParametersForm.controls.loadTypeIds.value;
      this.entityItem.isRequiredDocumentType1 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType1.value;
      this.entityItem.isRequiredDocumentType3 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType3.value;
      this.entityItem.isRequiredDocumentType4 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType4.value;
      this.entityItem.isRequiredDocumentType5 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType5.value;
      this.entityItem.isRequiredDocumentType6 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType6.value;
      this.entityItem.isRequiredDocumentType7 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType7.value;
      this.entityItem.isRequiredDocumentType8 = this.mainFormService.tabRequiredDocumentsForm.controls.isRequiredDocumentType8.value;
      this.entityItem.requiredDocumentTypeOther = this.mainFormService.tabRequiredDocumentsForm.controls.requiredDocumentTypeOther.value;
      this.entityItem.isProvidedDocumentType1 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType1.value;
      this.entityItem.isProvidedDocumentType2 = this.mainFormService.tabRequiredDocumentsForm.controls.isProvidedDocumentType2.value;
      this.entityItem.providedDocumentTypeOther = this.mainFormService.tabRequiredDocumentsForm.controls.providedDocumentTypeOther.value;
      this.entityItem.packageTypeId = this.mainFormService.tabParametersForm.controls.packageTypeId && this.mainFormService.tabParametersForm.controls.packageTypeId.value;
      this.entityItem.seats = this.mainFormService.tabParametersForm.controls.seats && this.mainFormService.tabParametersForm.controls.seats.value;

      this.entityItem.senderName = this.mainFormService.tabCounterpartiesForm.controls.senderName.value;
      this.entityItem.senderInn = this.mainFormService.tabCounterpartiesForm.controls.senderInn.value;
      this.entityItem.senderAddress = this.mainFormService.tabCounterpartiesForm.controls.senderAddress.value;
      this.entityItem.recipientName = this.mainFormService.tabCounterpartiesForm.controls.recipientName.value;
      this.entityItem.recipientInn = this.mainFormService.tabCounterpartiesForm.controls.recipientInn.value;
      this.entityItem.recipientAddress = this.mainFormService.tabCounterpartiesForm.controls.recipientAddress.value;
      this.entityItem.payerName = this.mainFormService.tabCounterpartiesForm.controls.payerName.value;
      this.entityItem.payerInn = this.mainFormService.tabCounterpartiesForm.controls.payerInn.value;
      this.entityItem.payerAddress = this.mainFormService.tabCounterpartiesForm.controls.payerAddress.value;

      if (this.isTemplate) {
          this.orderDocumentsService.createByTemplate(this.entityItem).subscribe(success => {
            this.pending = false;

            let $navigateUrl = '/templates';
            if (this.mainFormService.tabGeneralForm.controls.type.value === 'AUCTION') {
              if (this.mainFormService.tabGeneralForm.controls.status.value === OrderStatus.bidding) {
                $navigateUrl =  new Date(this.mainFormService.tabGeneralForm.controls.docDateStart.value) > new Date() ? '/biddings_deffered' : '/biddings';
              }
            }

            this.router.navigateByUrl('/cab/owner/' + this.mainFormService.documentType + $navigateUrl);

            this.dialogRef.close({ action: true });

            const toastMsg = 'Объект добавлен';

            this.toastService.success(toastMsg, '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      } else {
        this.orderDocumentsService.update(this.entityItem).subscribe(success => {
            this.pending = false;

            let $navigateUrl = '/drafts';
            if (this.mainFormService.tabGeneralForm.controls.type.value === 'AUCTION') {
              if (this.mainFormService.tabGeneralForm.controls.status.value === OrderStatus.bidding) {
                $navigateUrl =  new Date(this.mainFormService.tabGeneralForm.controls.docDateStart.value) > new Date() ? '/biddings_deffered' : '/biddings';
              }
            }

            this.router.navigateByUrl('/cab/owner/' + this.mainFormService.documentType + $navigateUrl);

            this.dialogRef.close({ action: true });

            const toastMsg = this.mainFormService.documentActionMode === DocumentActionModes.add ? 'Объект добавлен' : 'Объект изменен';

            this.toastService.success(toastMsg, '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      }
    } else if ([DocumentActionModes.view_completed_bids].includes(this.mainFormService.documentActionMode)) {
      if (this.mainFormService.clientMode === ClientModes.operator) {
        if (this.mainFormService.tabInsuranceForm && !this.mainFormService.tabInsuranceForm.valid) {
          Object.keys(this.mainFormService.tabInsuranceForm.controls).forEach(field => {
            const control = this.mainFormService.tabGeneralForm.get(field);
            control.markAsTouched({ onlySelf: true });
          });

          this.snackService.showWarningMsg('Обнаружены ошибки при заполнении данных. Проверьте правильность заполнения данной заявки на всех вкладках');

          return;
        }

        this.pending = true;

        this.entityItem.insuranceCreationDate = this.mainFormService.tabInsuranceForm.controls.insuranceCreationDate.value;
        this.entityItem.insuranceStartDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartDate.value;
        this.entityItem.insuranceEndDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndDate.value;
        this.entityItem.insurancePayDate = this.mainFormService.tabInsuranceForm.controls.insurancePayDate.value;
        this.entityItem.insuranceStartTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceStartTransportationDate.value;
        this.entityItem.insuranceEndTransportationDate = this.mainFormService.tabInsuranceForm.controls.insuranceEndTransportationDate.value;
        this.entityItem.insuranceCargoName = this.mainFormService.tabInsuranceForm.controls.insuranceCargoName.value;
        this.entityItem.insuranceCargoDoc = this.mainFormService.tabInsuranceForm.controls.insuranceCargoDoc.value;
        this.entityItem.insuranceCargoTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceCargoTypeId.value;
        this.entityItem.insuranceCurrency = this.mainFormService.tabInsuranceForm.controls.insuranceCurrency.value;
        this.entityItem.insuranceLimitSum = this.mainFormService.tabInsuranceForm.controls.insuranceLimitSum.value;
        this.entityItem.insuranceBeneficiary = this.mainFormService.tabInsuranceForm.controls.insuranceBeneficiary.value;
        this.entityItem.insuranceTransportTypeId = this.mainFormService.tabInsuranceForm.controls.insuranceTransportTypeId.value;
        this.entityItem.insurancePackageTypeId = this.mainFormService.tabInsuranceForm.controls.insurancePackageTypeId.value;
        this.entityItem.insuranceCarrierName = this.mainFormService.tabInsuranceForm.controls.insuranceCarrierName.value;
        this.entityItem.insuranceSeats = this.mainFormService.tabInsuranceForm.controls.insuranceSeats.value;
        this.entityItem.insuranceWeight = this.mainFormService.tabInsuranceForm.controls.insuranceWeight.value;
        this.entityItem.insurancePointFrom = this.mainFormService.tabInsuranceForm.controls.insurancePointFrom.value;
        this.entityItem.insurancePointTo = this.mainFormService.tabInsuranceForm.controls.insurancePointTo.value;
        this.entityItem.insuranceBeginningResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceBeginningResponsibilityId.value;
        this.entityItem.insuranceEndResponsibilityId = this.mainFormService.tabInsuranceForm.controls.insuranceEndResponsibilityId.value;
        this.entityItem.insuranceNumber = this.mainFormService.tabInsuranceForm.controls.insuranceNumber.value;
        this.entityItem.isInOperationProgress = this.mainFormService.tabGeneralForm.controls.isInOperationProgress.value;

        this.orderDocumentsService.updateInsurance(this.entityItem).subscribe(success => {
            this.pending = false;

            this.dialogRef.close({ action: true });

            this.toastService.success('Объект изменен', '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      } else if (this.mainFormService.clientMode === ClientModes.owner) {
        this.entityItem.ownerConfirmationCargoDoc = this.mainFormService.tabDocumentsForm.controls.ownerConfirmationCargoDoc.value;

        const errMsg = [];

        if (!this.entityItem.ownerConfirmationCargoDoc) {
          errMsg.push('Номер накладной');
        }

        if (errMsg.length) {
          const dialogRef = this.dialog.open(ErrDialogComponent, {
            data: errMsg
          });
          return;
        }

        this.pending = true;

        this.orderDocumentsService.updateOwnerCompletedBidsData(this.entityItem).subscribe(success => {
            this.pending = false;

            this.dialogRef.close({ action: true });

            this.toastService.success('Объект изменен', '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      } else if (this.mainFormService.clientMode === ClientModes.carrier) {
        const errMsg = [];

        if (!this.mainFormService.tabDocumentsForm.controls.driverId.value &&
          !this.mainFormService.tabDocumentsForm.controls.driver1Id.value) {
          errMsg.push('Должен быть указан хотя бы один водитель');
          this.mainFormService.tabDocumentsForm.controls.driverId.markAsTouched();
        }

        if (!this.mainFormService.tabDocumentsForm.controls.truckId.value) {
          errMsg.push('Не указан автотранспорт');
          this.mainFormService.tabDocumentsForm.controls.truckId.markAsTouched();
        }

        if (errMsg.length) {
          const dialogRef = this.dialog.open(ErrDialogComponent, {
            data: errMsg
          });

          return;
        }

        this.entityItem.driverId = this.mainFormService.tabDocumentsForm.controls.driverId.value;
        this.entityItem.driver1Id = this.mainFormService.tabDocumentsForm.controls.driver1Id.value;
        this.entityItem.truckId = this.mainFormService.tabDocumentsForm.controls.truckId.value;

        this.pending = true;

        this.orderDocumentsService.updateCarrierCompletedBidsData(this.entityItem).subscribe(success => {
            this.pending = false;

            this.dialogRef.close({ action: true });

            this.toastService.success('Объект изменен', '', {
              closeButton: true,
              timeOut: 3000,
              toastClass: 'toast custom-style',
            });
          }, errors => {
            errors.map(error => {
              this.pending = false;
              this.snackService.showErrorMsg(error);
            });
          }
        );
      }
    }
  }
}
