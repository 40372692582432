import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {Truck} from '../models/truck';

@Injectable({
  providedIn: 'root'
})
export class TrucksAdapterService implements EntityAdapter<Truck> {
  adaptIn(item: any): Truck {
    let truckTypeId;
    if (item.truckTypeId) {
      truckTypeId = item.truckTypeId;
    } else {
      truckTypeId = item.truckTypeOther ? '0' : '';
    }

    return new Truck(
      item.id,
      item.name,
      item.organization,
      item.organizationId,
      item.regNumber,
      item.vin,
      item.sts,
      item.truckType,
      truckTypeId,
      item.weight,
      item.truckTypeOther,
      item.capacity,
    );
  }

  adaptOut(item: Truck): any {
    const entityItem = {
      ...item
    };
    entityItem.truckTypeId = entityItem.truckTypeId ? entityItem.truckTypeId : '';
    return entityItem;
  }
}
