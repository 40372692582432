import {Model} from './model';
import {Permissions} from '../interfaces/permissions';

export class Organization extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public fullName?: string,
    public deferedPayment?: number,
    public isDeferedPaymentSet?: boolean,
    public factAddress?: string,
    public jurAddress?: string,
    public phones?: string,
    public email?: string,
    public regCode?: string,
    public inn?: string,
    public kpp?: string,
    public ogrn?: string,
    public taxSystem?: string,
    public ownerRating?: string,
    public carrierRating?: string,
    public bankAccount?: string,
    public bankBik?: string,
    public corrAccount?: string,
    public bankName?: string,
    public ownerShipType?: string,
    public siteComissionRate?: number,
    public permissions?: Permissions,
    public balance?: number,
    public balanceLimit?: number,
    public isBalanceNotify?: boolean
  ) {
    super(id, name);
  }
}
