import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { FilterAdminDriversModel } from '../../../interfaces/filter-admin-drivers.model';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OrganizationsService } from '../../../services/organizations.service';
import { Organization } from '../../../models/organization';
import { ErrDialogComponent } from '../../err-dialog/err-dialog.component';
import {CustomDateAdapter} from '../../../classes/custom-date-adapter';
import {DateAdapter, MatDialogRef, NativeDateAdapter} from '@angular/material';

@Component({
  selector: 'filter-admin-drivers',
  templateUrl: './filter-admin-drivers.component.html',
  styleUrls: ['./filter-admin-drivers.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter},
  ],
})
export class FilterAdminDriversComponent implements OnInit {
  filterForm: FormGroup;
  pending = false;
  organizationsList: Organization[] = [];

  @ViewChild(MatMenuTrigger, { static: false }) filterMenuTrigger: MatMenuTrigger;

  @Output() applyFilterEvent: EventEmitter<FilterAdminDriversModel> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
    private organizationsService: OrganizationsService,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private dialog: MatDialog) {
      dateAdapter.setLocale('ru-RU');
    }

  ngOnInit() {
    this.pending = true;

    this.initFilterForm();

    forkJoin(
      this.organizationsService.getList(1, 1000),
    ).pipe(
      finalize(() => this.pending = false)
    ).subscribe(([organizationsData]) => {
      this.organizationsList = organizationsData.list;
    });
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      organizationIds:  [''],
      lastName: [''],
      firstName: [''],
      middleName: [''],
      birthDate: [''],
      licenseNumber: [''],
      passport: ['']
    });
  }

  closeFilterMenu() {
    this.filterMenuTrigger.closeMenu();
  }

  applyFilter() {
    const errors = [];

    if (errors.length) {
      const dialogRef = this.dialog.open(ErrDialogComponent, {
        data: errors
      });

      return;
    }

    this.applyFilterEvent.emit(this.filterForm.value);

    this.closeFilterMenu();
  }

  resetFilter() {
    this.filterForm.reset();
  }
}
