import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ColConfig} from "../../interfaces/col-config";

export interface TableColFilterChange {
  cols: ColConfig[];
}

@Component({
  selector: 'app-table-col-filter',
  templateUrl: './table-col-filter.component.html',
  styleUrls: ['./table-col-filter.component.scss'],
})
export class TableColFilterComponent {
  @Input() cols: ColConfig[] = [];
  @Output() colsChange = new EventEmitter<TableColFilterChange>();
  @Input() color: string;

  onChange($event: Event, item: ColConfig) {
    $event.stopPropagation();
    item.show = item.show ? false : true;
    this.colsChange.emit({ cols: this.cols });
  }

  trackByFn(index: number, item: ColConfig) {
    return item.class;
  }
}
