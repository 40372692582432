import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {TruckEquipment} from '../models/truck-equipment';

@Injectable({
  providedIn: 'root'
})
export class TruckEquipmentAdapterService implements EntityAdapter<TruckEquipment> {
  adaptIn(item: any): TruckEquipment {
    return new TruckEquipment(
      item.id,
      item.name,
      Math.round(+item.price * 100) / 100,
    );
  }

  adaptOut(item: TruckEquipment): any {
    return {
      ...item
    };
  }
}
