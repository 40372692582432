import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalConfirmationRoutesDates} from '../models/proposal-confirmation-routes-dates';
import * as moment from 'moment';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsRoutesDatesAdapterService implements EntityAdapter<ProposalConfirmationRoutesDates> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): ProposalConfirmationRoutesDates {
    if (item.initial) {
      return item
    } else {
      return new ProposalConfirmationRoutesDates(
        item.id,
        item.code,
        item.name,
        this.dateParserService.parseDate(item.carrierDateStart),
        this.dateParserService.parseDate(item.carrierDateEnd),
        this.dateParserService.parseDate(item.ownerDateStart),
        this.dateParserService.parseDate(item.ownerDateEnd),
      );
    }
  }

  adaptOut(item: ProposalConfirmationRoutesDates): any {
    return {
      ...item,
      carrierDateStart: item.carrierDateStart ? moment(item.carrierDateStart).format('DD.MM.YYYY') : null,
      carrierDateEnd: item.carrierDateEnd ? moment(item.carrierDateEnd).format('DD.MM.YYYY') : null,
      ownerDateStart: item.ownerDateStart ? moment(item.ownerDateStart).format('DD.MM.YYYY') : null,
      ownerDateEnd: item.ownerDateEnd ? moment(item.ownerDateEnd).format('DD.MM.YYYY') : null,
    };
  }
}
