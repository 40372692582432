import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../classes/table-component';
import {DocumentEquipment} from '../../../models/document-equipment';
import {DocumentEquipmentDatasource} from './document-equipment.datasource';
import {ColConfig} from '../../../interfaces/col-config';
import {colsConfig} from './config/cols.config';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../services/config.service';
import {DocumentEquipmentService} from '../../../services/document-equipment.service';
import {ErrorService} from '../../../services/error.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {ConfirmDialogData} from '../../../interfaces/confirm-dialog.model';
import {ConfirmPopupComponent} from '../../confirm-popup/confirm-popup.component';
import {DocumentEquipmentAddFormComponent} from '../document-equipment-add-form/document-equipment-add-form.component';
import {DocumentEquipmentEditFormComponent} from '../document-equipment-edit-form/document-equipment-edit-form.component';
import {DocumentTypes} from '../../../enums/document-types';
import {MainFormService as OrderMainFormService} from '../../../../modules/order-form/services/main-form.service';
import {MainFormService as ProposalMainFormService} from '../../../../modules/proposal-form/services/main-form.service';
import {interval} from 'rxjs';

@Component({
  selector: 'app-document-equipment',
  templateUrl: './document-equipment.component.html',
  styleUrls: ['./document-equipment.component.scss']
})
export class DocumentEquipmentComponent extends TableComponent<DocumentEquipment, DocumentEquipmentDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name', 'price', 'quantity', 'total', 'options', 'description'];
  storageKey = 'app-document-equipment' + '-' + this.orderMainFormService.clientMode + '-' + this.orderMainFormService.documentType;
  dataSource: DocumentEquipmentDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  masterEntityId: string;

  @Input() documentType: DocumentTypes;

  colsShowConfig = {
    select: 'begin',
    options: 'end'
  };

  constructor(private http: HttpClient,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: DocumentEquipmentService,
              public orderMainFormService: OrderMainFormService,
              public proposalMainFormService: ProposalMainFormService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initDataSource();

    if (this.documentType === DocumentTypes.order) {
      this.entityService.clientMode = this.orderMainFormService.clientMode;
      this.entityService.documentType = this.orderMainFormService.documentType;

      this.orderMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    } else {
      this.entityService.clientMode = this.proposalMainFormService.clientMode;
      this.entityService.documentType = this.proposalMainFormService.documentType;

      this.proposalMainFormService.getEntityItemObservable()
        .pipe(untilDestroyed(this))
        .subscribe(data => {
            if (data) {
              this.masterEntityId = data.id;
              this.dataSource.loadList(this.masterEntityId, 0, this.itemsPerPage);
            }
          }
        );
    }

    interval(30000)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => this.reload());
  }

  ngOnDestroy() {}

  get readOnly() {
    if (this.documentType === DocumentTypes.order) {
      return this.orderMainFormService.readOnly;
    } else {
      return this.proposalMainFormService.readOnly;
    }
  }

  update(refs?: string[]) {
    this.dataSource.loadList(this.masterEntityId, this.paginator.pageIndex, this.itemsPerPage);
  }

  initDataSource() {
    this.dataSource = new DocumentEquipmentDatasource(this.entityService, this.errorService, this.snackService);
  }

  openDeleteDialog(entityItem: DocumentEquipment) {
    const dialogData: ConfirmDialogData = {
      contentMsg: 'Удалить выбранный элемент ?',
      btnsArray: [
        {
          message: 'Удалить',
          action: () => {
            dialog.close();
            this.delete(entityItem.id);
          }
        },
        {
          message: 'Отмена',
          action: () => {
            dialog.close();
          }
        },
      ]
    };
    const dialog = this.dialog.open(ConfirmPopupComponent, { data: dialogData });
  }

  delete(id: string): void {
    this.entityService.delete(id).subscribe(success => {
        this.update();
        this.toastService.success('Объект удален', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(DocumentEquipmentAddFormComponent, {
      disableClose: true,
      data: {
        masterEntityId: this.masterEntityId,
        documentType: this.documentType,
        clientMode: this.entityService.clientMode,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  openEditDialog(entityItem: DocumentEquipment) {
    const dialogRef = this.dialog.open(DocumentEquipmentEditFormComponent, {
      disableClose: true,
      data: {
        masterEntityId: this.masterEntityId,
        documentType: this.documentType,
        id: entityItem.id,
        clientMode: this.entityService.clientMode,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.reload) {
        this.update();
      }
    });
  }

  reload() {
    this.update();
  }
}
