import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  get url() {
    return environment.url;
  }

  get apiUrl() {
    return environment.url + environment.apiUrl;
  }

  get dadataInnUrl() {
    return environment.dadataInnUrl;
  }

  get dadataToken() {
    return environment.dadataToken;
  }
}
