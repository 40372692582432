import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-owner-confirmation-proposal',
  templateUrl: './owner-confirmation-proposal.component.html',
  styleUrls: ['./owner-confirmation-proposal.component.scss']
})
export class OwnerConfirmationProposalComponent implements OnInit {
  pending = false;

  constructor(public dialogRef: MatDialogRef<OwnerConfirmationProposalComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  action() {
    this.dialogRef.close({ action: true });
  }
}
