import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../../../../shared/classes/table-data-source';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {EntityData} from '../../../../../shared/interfaces/entity-data';
import {FormArray, FormGroup} from '@angular/forms';
import {ProposalConfirmationCounterparties} from '../../../../../shared/models/proposal-confirmation-counterparties';
import {Organization} from '../../../../../shared/models/organization';
import {OrganizationsService} from '../../../../../shared/services/organizations.service';
import {LocalStorageService} from 'ngx-webstorage';
import {forkJoin} from 'rxjs';

export class GridCounterpartiesDatasource extends TableDataSource<ProposalConfirmationCounterparties> {
  constructor(protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              protected organizationsService: OrganizationsService,
              protected storage: LocalStorageService,) {
    super();
  }

  loadList(entityId: string, componentForm: FormGroup, currOrg: any) {
    this.setLoading(true);
    const currentUser = this.storage.retrieve('currentUser');
    forkJoin(
    this.entityService.getListCounterparties(entityId),
    this.organizationsService.getOne(currentUser.organizationId),
        ).pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe(([data, orgData]) => {
          currOrg.org = orgData.list[0];
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);

          data.list.forEach((item, index) => {
            (componentForm.get('items') as FormArray).controls[index].get('type').setValue(item.type);
            (componentForm.get('items') as FormArray).controls[index].get('name').setValue(item.name);
            (componentForm.get('items') as FormArray).controls[index].get('inn').setValue(item.inn);
            (componentForm.get('items') as FormArray).controls[index].get('address').setValue(item.address);
          });
        }, err => this.snackService.showErrorMsg(err));
  }
}
