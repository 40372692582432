import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ProposalConfirmationRoutesContacts} from '../../../../../shared/models/proposal-confirmation-routes-contacts';
import {GridRoutesContactsDatasource} from './grid-routes-contacts.datasource';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {MainFormService} from '../../../services/main-form.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, NativeDateAdapter} from '@angular/material';
import {colsConfig} from './config/cols.config';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-proposal-confirmation-grid-routes-contacts',
  templateUrl: './grid-routes-contacts.component.html',
  styleUrls: ['./grid-routes-contacts.component.scss']
})
export class GridRoutesContactsComponent extends TableComponent<(ProposalConfirmationRoutesContacts), GridRoutesContactsDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name_', 'ownerContactsPhone1', 'ownerContactsPhone2', 'ownerContactsPerson'];
  storageKey = 'app-proposal-confirmation-grid-routes-points';
  dataSource: GridRoutesContactsDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  entityId: string;

  constructor(private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.routesContactsForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.routesContactsForm = form;
  }

  getOwnerContactsPhone1(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('ownerContactsPhone1').value;
  }

  getOwnerContactsPhone2(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('ownerContactsPhone2').value;
  }

  getOwnerContactsPerson(index: number) {
    return (this.componentForm.get('items') as FormArray).controls[index].get('ownerContactsPerson').value;
  }

  ngOnInit() {
    this.initForm();

    super.ngOnInit();

    this.initDataSource();
  }

  ngOnDestroy() {}

  initForm() {
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          id: [''],
          code: [''],
          ownerContactsPhone1: [''],
          ownerContactsPhone2: [''],
          ownerContactsPerson: [''],
        }),
        this.formBuilder.group({
          id: [''],
          code: [''],
          ownerContactsPhone1: [''],
          ownerContactsPhone2: [''],
          ownerContactsPerson: [''],
        }),
      ])
    });
  }

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  initDataSource() {
    this.dataSource = new GridRoutesContactsDatasource(this.entityService, this.errorService, this.snackService);

    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }
}
