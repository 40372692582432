import { Injectable } from '@angular/core';
import {ConfigService} from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DadataService {
  dadataInnUrl = '';
  dadataToken = '';

  constructor(protected configService: ConfigService) {
    this.dadataInnUrl = this.configService.dadataInnUrl;
    this.dadataToken = this.configService.dadataToken;
    }
  
    async getOrganization(inn: any):Promise<any>{
      if (!inn) return;

      var options = {
          method: "POST",
          //mode: "cors",
          headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Token " + this.dadataToken
          },
          body: JSON.stringify({query: inn, count: 1})
      }
  
      return fetch(this.dadataInnUrl, options)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      .catch(error => {
        console.log("error", error)
      });
    }
}
