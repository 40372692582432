import {Model} from './model';

export class LoadType extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public price?: number,
  ) {
    super(id, name);
  }
}
