import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {ProposalConfirmationRoutesContacts} from '../models/proposal-confirmation-routes-contacts';

@Injectable({
  providedIn: 'root'
})
export class ProposalConfirmationsRoutesContactsAdapterService implements EntityAdapter<ProposalConfirmationRoutesContacts> {
  adaptIn(item: any): ProposalConfirmationRoutesContacts {
    if (item.initial) {
      return item
    } else {
      return new ProposalConfirmationRoutesContacts(
        item.id,
        item.code,
        item.name,
        item.ownerContactsPhone1,
        item.ownerContactsPhone2,
        item.ownerContactsPerson,
      );
    }
  }

  adaptOut(item: ProposalConfirmationRoutesContacts): any {
    return {
      ...item
    };
  }
}
