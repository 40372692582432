import {Model} from './model';

export class ProposalConfirmationEquipment extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public carrierPrice?: number,
    public carrierQuantity?: number,
    public carrierTotal?: number,
    public ownerPrice?: number,
    public ownerQuantity?: number,
    public ownerTotal?: number,
  ) {
    super(id, name);
  }
}
