import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {OrderRoute} from '../models/order-route';
import * as moment from 'moment';
import {DateParserService} from '../classes/date-parser.service';

@Injectable({
  providedIn: 'root'
})
export class OrderRoutesAdapterService implements EntityAdapter<OrderRoute> {
  constructor(private dateParserService: DateParserService) {}

  adaptIn(item: any): OrderRoute {
    return new OrderRoute(
      item.id,
      item.name,
      item.orderId,
      item.sourceName,
      item.sourceNameShort,
      +item.sourceLongitude,
      +item.sourceLatitude,
      item.destinationName,
      item.destinationNameShort,
      +item.destinationLongitude,
      +item.destinationLatitude,
      this.dateParserService.parseDate(item.dateIn),
      (item.timeInHours ? item.timeInHours : '00') + (item.timeInMinutes ? item.timeInMinutes : '00'),
      item.timeInHours ? item.timeInHours : '00',
      item.timeInMinutes ? item.timeInMinutes : '00',
      this.dateParserService.parseDate(item.dateOut),
      (item.timeOutHours ? item.timeOutHours : '00') + (item.timeOutMinutes ? item.timeOutMinutes : '00'),
      item.timeOutHours ? item.timeOutHours : '00',
      item.timeOutMinutes ? item.timeOutMinutes : '00',
      Math.round(+item.distance),
      item.contactsInPhone1,
      item.contactsInPhone2,
      item.contactsInPerson,
      item.contactsOutPhone1,
      item.contactsOutPhone2,
      item.contactsOutPerson,
    );
  }

  adaptOut(item: OrderRoute): any {
    return {
      ...item,
      dateIn: item.dateIn ? moment(item.dateIn).format('DD.MM.YYYY') : null,
      dateOut: item.dateOut ? moment(item.dateOut).format('DD.MM.YYYY') : null,
    };
  }

  adaptOuts(items: OrderRoute[]): any {
    return items.map((i) => { return {
      ...i, 
      dateIn: i.dateIn ? moment(i.dateIn).format('DD.MM.YYYY') : null,
      dateOut: i.dateOut ? moment(i.dateOut).format('DD.MM.YYYY') : null
    }});
  }
}
