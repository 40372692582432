import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ProposalConfirmationEquipment} from '../../../../../shared/models/proposal-confirmation-equipment';
import {GridEquipmentDatasource} from './grid-equipment.datasource';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {MainFormService} from '../../../services/main-form.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, NativeDateAdapter} from '@angular/material';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-proposal-confirmation-grid-equipment',
  templateUrl: './grid-equipment.component.html',
  styleUrls: ['./grid-equipment.component.scss']
})
export class GridEquipmentComponent extends TableComponent<(ProposalConfirmationEquipment), GridEquipmentDatasource> implements OnInit, OnDestroy {
  displayedColumns = [];
  storageKey = 'app-proposal-confirmation-grid-equipment';
  dataSource: GridEquipmentDatasource;
  protected colsConf: ColConfig[] = [];
  entityId: string;

  constructor(private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.equipmentForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.equipmentForm = form;
  }

  ngOnInit() {
    if (this.mainFormService.clientMode === this.mainFormService.clientModes.owner) {
      this.displayedColumns = ['name_', 'carrierQuantity', 'ownerPrice', 'ownerQuantity', 'ownerTotal'];

      this.colsConf = [
        {
          name: 'Наименование',
          show: true,
          class: 'name_',
        },
        {
          name: 'Кол-во перевозчика',
          show: true,
          class: 'carrierQuantity',
        },
        {
          name: 'Цена отправителя',
          show: true,
          class: 'ownerPrice',
        },
        {
          name: 'Кол-во отправителя',
          show: true,
          class: 'ownerQuantity',
        },
        {
          name: 'Сумма отправителя',
          show: true,
          class: 'ownerTotal',
        },
      ];
    } else if (this.mainFormService.clientMode === this.mainFormService.clientModes.carrier) {
      this.displayedColumns = ['name_', 'carrierPrice', 'carrierQuantity', 'carrierTotal', 'ownerQuantity'];

      this.colsConf = [
        {
          name: 'Наименование',
          show: true,
          class: 'name_',
        },
        {
          name: 'Цена перевозчика',
          show: true,
          class: 'carrierPrice',
        },
        {
          name: 'Кол-во перевозчика',
          show: true,
          class: 'carrierQuantity',
        },
        {
          name: 'Сумма перевозчика',
          show: true,
          class: 'carrierTotal',
        },
        {
          name: 'Кол-во отправителя',
          show: true,
          class: 'ownerQuantity',
        },
      ]
    }

    this.initForm();

    super.ngOnInit();

    this.initDataSource();
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          id: [''],
          carrierPrice: [''],
          carrierQuantity: [''],
          carrierTotal: [''],
          ownerPrice: [''],
          ownerQuantity: [''],
          ownerTotal: [''],
        }),
        this.formBuilder.group({
          id: [''],
          carrierPrice: [''],
          carrierQuantity: [''],
          carrierTotal: [''],
          ownerPrice: [''],
          ownerQuantity: [''],
          ownerTotal: [''],
        }),
        this.formBuilder.group({
          id: [''],
          carrierPrice: [''],
          carrierQuantity: [''],
          carrierTotal: [''],
          ownerPrice: [''],
          ownerQuantity: [''],
          ownerTotal: [''],
        }),
      ])
    });
  }

  ngOnDestroy() {}

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.mainFormService.clientMode, this.componentForm);
  }

  initDataSource() {
    this.dataSource = new GridEquipmentDatasource(this.entityService, this.errorService, this.snackService);

    this.dataSource.loadList(this.mainFormService.proposalId, this.mainFormService.clientMode, this.componentForm);
  }

  ownerValueChange(event, entityItem: ProposalConfirmationEquipment, index: number) {
    const ownerQuantity = event.target.value ? parseFloat(event.target.value) : 0;

    if (ownerQuantity > entityItem.carrierQuantity) {
      this.snackService.showErrorMsg('Потребность отправителя не может превышать предложение перевозчика');
      (this.componentForm.get('items') as FormArray).controls[index].get('ownerQuantity').setValue(0);
    }

    const ownerQuantityForm = (this.componentForm.get('items') as FormArray).controls[index].get('ownerQuantity').value;
    (this.componentForm.get('items') as FormArray).controls[index].get('ownerTotal').setValue(ownerQuantityForm * entityItem.ownerPrice);

    this.mainFormService.equipmentSum = this.componentForm.get('items').value.reduce((sum: number, current) => {
      return sum + current.ownerTotal;
    }, 0);

    this.mainFormService.equipmentSum = parseFloat(this.mainFormService.equipmentSum.toString());
  }
}
