export const colsConfig = [
  {
    name: 'Оператор',
    show: true,
    class: 'userName',
  },
  {
    name: 'Дата/время',
    show: true,
    class: 'actionDateTime',
  },
  {
    name: 'Изменения',
    show: true,
    class: 'changes',
  },
];
