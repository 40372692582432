import {Component, OnInit, Input} from '@angular/core';
import {ProposalsService} from '../../../../shared/services/proposals.service';
import {ProposalConfirmationsRoutesDatesAdapterService} from '../../../../shared/adapters/proposal-confirmations-routes-dates-adapter.service';
import {MainFormService} from '../../services/main-form.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonValidatorsService} from '../../../../shared/services/common-validators.service';
import {LocalStorageService} from 'ngx-webstorage';
import {OrganizationsService} from '../../../../shared/services/organizations.service';

@Component({
  selector: 'app-proposal-form-tab-confirmation',
  templateUrl: './tab-confirmation.component.html',
  styleUrls: ['./tab-confirmation.component.scss']
})
export class TabConfirmationComponent implements OnInit {
  @Input() pending = false;
  paymentDeferDaysAviable = 0;
  isPaymentDeferEnable = false;

  constructor(private proposalsService: ProposalsService,
              private mainFormService: MainFormService,
              private proposalConfirmationsRoutesDatesAdapterService: ProposalConfirmationsRoutesDatesAdapterService,
              private commonValidatorsService: CommonValidatorsService,
              private formBuilder: FormBuilder,
              protected organizationsService: OrganizationsService,
              protected storage: LocalStorageService) {}

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm = form;
  }

  get insurancePackageTypeId() {
    return this.componentForm.get('headerForm').get('insurancePackageTypeId');
  }

  get cargoTypeId() {
    return this.componentForm.get('headerForm').get('cargoTypeId');
  }

  get cargoTypeOther() {
    return this.componentForm.get('headerForm').get('cargoTypeOther');
  }

  get ownerConfirmationCargoDoc() {
    return this.componentForm.get('headerForm').get('ownerConfirmationCargoDoc');
  }

  get insuranceLimitSum() {
    return this.componentForm.get('headerForm').get('insuranceLimitSum');
  }

  get ownerSeats() {
    return this.componentForm.get('headerForm').get('ownerSeats');
  }

  ngOnInit() {
    this.initForm();

    const currentUser = this.storage.retrieve('currentUser');
    this.organizationsService.getOne(currentUser.organizationId).pipe()
      .subscribe((orgData) => {
        this.paymentDeferDaysAviable = orgData.list[0].deferedPayment;
        this.isPaymentDeferEnable = orgData.list[0].isDeferedPaymentSet;
        this.componentForm.get('headerForm').get('isPaymentDefer').setValue(this.mainFormService.entityItem.paymentDeferType === 'DEFER_100_4');
    });
  }

  ngOnChanges(changes: any) {
    if (!changes["pending"].isFirstChange()) {
      this.insuranceLimitSum && this.insuranceLimitSum.setValidators([Validators.required, this.commonValidatorsService.numberMin(this.mainFormService.settings && this.mainFormService.settings['minInsuranceSum'])]);
      this.insuranceLimitSum && this.insuranceLimitSum.updateValueAndValidity();
    }
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      commonForm: this.formBuilder.group({}),
      equipmentForm: this.formBuilder.group({}),
      routesContactsForm: this.formBuilder.group({}),
      routesDatesForm: this.formBuilder.group({}),
      routesPointsForm: this.formBuilder.group({}),
      counterpartiesForm: this.formBuilder.group({}),
      headerForm: this.formBuilder.group({
        insurancePackageTypeId: ['', Validators.required],
        ownerSeats: [''],
        ownerConfirmationCargoDoc: ['', Validators.required],
        insuranceLimitSum: ['', [Validators.required, this.commonValidatorsService.numberMin(this.mainFormService.settings && this.mainFormService.settings['minInsuranceSum'])]],
        cargoTypeId: ['', Validators.required],
        cargoTypeOther: ['', Validators.required],
        paymentDeferDays: [],
        isPaymentDefer: [],
      }),
    });
  }

  calcPriceData() {
    this.mainFormService.entityItem.insuranceLimitSum = +this.insuranceLimitSum.value;

    const totalSumInsuranceAdd = this.mainFormService.entityItem.insuranceLimitSum *
        this.mainFormService.entityItem.insuranceRate / 100;

    this.mainFormService.totalSumInsuranceAdd = totalSumInsuranceAdd;

    if (!this.componentForm.get('headerForm').get('isPaymentDefer').value) {
      this.mainFormService.deferSum = 0;
    } else {
      const settings = this.mainFormService.settings;
      const paymentDeferRate = parseFloat(settings.paymentDefer) || 0;
      const paymentDeferDays = this.componentForm.get('headerForm').get('paymentDeferDays').value;
      const deferPrice = (this.mainFormService.entityItem.winBidSum + this.mainFormService.equipmentSum) * (paymentDeferRate * paymentDeferDays / (30 * 100));

      this.mainFormService.deferSum = deferPrice;
    }
  }
}
