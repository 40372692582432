import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {DateAdapter, MatDialog, NativeDateAdapter} from '@angular/material';
import {OrderDocumentsService} from '../../../../shared/services/order-documents.service';
import {ToastrService} from 'ngx-toastr';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {finalize} from 'rxjs/operators';
import {DriversService} from '../../../../shared/services/drivers.service';
import {TrucksService} from '../../../../shared/services/trucks.service';
import {SetDriverVerifiedDialogComponent} from '../../../../shared/components/set-driver-verified-dialog/set-driver-verified-dialog.component';

@Component({
  selector: 'app-order-form-tab-check',
  templateUrl: './tab-check.component.html',
  styleUrls: ['./tab-check.component.scss']
})
export class TabCheckComponent extends EntityEditForm implements OnInit {
  pending = false;
  isDriverVerified = false;
  isDriver1Verified = false;

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,
              protected entityService: OrderDocumentsService,
              protected driversService: DriversService,
              protected trucksService: TrucksService,
              private toastService: ToastrService,
              private snackService: SnackBarService,
              private dateAdapter: DateAdapter<NativeDateAdapter>,
              private dialog: MatDialog) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  ngOnInit() {
    this.initForm();
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabCheckForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabCheckForm = form;
  }

  get driverId() {
    return this.componentForm.get('driverId');
  }

  get driver1Id() {
    return this.componentForm.get('driver1Id');
  }

  get truckId() {
    return this.componentForm.get('truckId');
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      driverId: [''],
      driver1Id: [''],
      truckId: ['', Validators.required],
    });
  }

  openVerifyDriverDialog() {
    if (!this.componentForm.valid) {
      this.componentForm.get('driverId').markAsTouched({ onlySelf: true });
      this.componentForm.get('truckId').markAsTouched({ onlySelf: true });

      return;
    }

    this.verifyDriver(this.mainFormService.orderId,
      this.componentForm.get('driverId').value,
      this.componentForm.get('truckId').value);
  }

  openVerifyDriver1Dialog() {
    if (!this.componentForm.valid) {
      this.componentForm.get('driver1Id').markAsTouched({ onlySelf: true });
      this.componentForm.get('truckId').markAsTouched({ onlySelf: true });

      return;
    }

    this.verifyDriver1(this.mainFormService.orderId,
      this.componentForm.get('driver1Id').value,
      this.componentForm.get('truckId').value);
  }

  verifyDriver(masterEntityId: string, driverId: string, truckId: string) {
    this.pending = true;

    this.entityService.verifyDriver(masterEntityId, driverId, truckId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(success => {
        this.toastService.success('Проверка успешна', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }

  verifyDriver1(masterEntityId: string, driverId: string, truckId: string) {
    this.pending = true;

    this.entityService.verifyDriver1(masterEntityId, driverId, truckId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(success => {
          this.toastService.success('Проверка успешна', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  onDriverIdChange() {
    this.pending = true;

    const driverId = this.componentForm.get('driverId').value;

    this.componentForm.controls.lastName.setValue('');
    this.componentForm.controls.regNumber.setValue('');
    this.componentForm.controls.vin.setValue('');
    this.componentForm.controls.firstName.setValue('');
    this.componentForm.controls.sts.setValue('');
    this.componentForm.controls.middleName.setValue('');
    this.componentForm.controls.phone.setValue('');
    this.componentForm.controls.birthDate.setValue('');
    this.componentForm.controls.passport.setValue('');
    this.componentForm.controls.passportDate.setValue('');
    this.componentForm.controls.licenseNumber.setValue('');
    this.componentForm.controls.licenseDate.setValue('');

    if (driverId) {
      this.driversService.getOne(driverId)
        .pipe(
          finalize(() => this.pending = false),
        )
        .subscribe(data => {
            const entityItem = data.list[0];

            this.componentForm.controls.lastName.setValue(entityItem.lastName);
            this.componentForm.controls.firstName.setValue(entityItem.firstName);
            this.componentForm.controls.middleName.setValue(entityItem.middleName);
            this.componentForm.controls.phone.setValue(entityItem.phone);
            this.componentForm.controls.birthDate.setValue(entityItem.birthDate);
            this.componentForm.controls.passport.setValue(entityItem.passport);
            this.componentForm.controls.passportDate.setValue(entityItem.passportDate);
            this.componentForm.controls.licenseNumber.setValue(entityItem.licenseNumber);
            this.componentForm.controls.licenseDate.setValue(entityItem.licenseDate);
          }, errors => {
            errors.map(error => {
              this.snackService.showErrorMsg(error);
            });
          }
        );
    }
  }

  onTruckIdChange() {
    this.pending = true;

    const truckId = this.componentForm.get('truckId').value;

    this.componentForm.controls.regNumber.setValue('');
    this.componentForm.controls.vin.setValue('');
    this.componentForm.controls.sts.setValue('');

    if (truckId) {
      this.trucksService.getOne(truckId)
        .pipe(
          finalize(() => this.pending = false),
        )
        .subscribe(data => {
            const entityItem = data.list[0];

            this.componentForm.controls.regNumber.setValue(entityItem.regNumber);
            this.componentForm.controls.vin.setValue(entityItem.vin);
            this.componentForm.controls.sts.setValue(entityItem.sts);
          }, errors => {
            errors.map(error => {
              this.snackService.showErrorMsg(error);
            });
          }
        );
    }
  }

  openDriverIsVerifiedDialog() {
    const dialogRef = this.dialog.open(SetDriverVerifiedDialogComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.action) {
        this.setDriverIsVerified(this.mainFormService.orderId);
      }
    });
  }

  openDriver1IsVerifiedDialog() {
    const dialogRef = this.dialog.open(SetDriverVerifiedDialogComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data && !!data.action) {
        this.setDriver1IsVerified(this.mainFormService.orderId);
      }
    });
  }

  setDriverIsVerified(masterEntityId: string) {
    this.pending = true;

    this.entityService.setDriverVerified(masterEntityId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(success => {
          this.isDriverVerified = true;

          this.toastService.success('Водитель проверен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }

  setDriver1IsVerified(masterEntityId: string) {
    this.pending = true;

    this.entityService.setDriver1Verified(masterEntityId)
      .pipe(
        finalize(() => this.pending = false),
      )
      .subscribe(success => {
          this.isDriver1Verified = true;

          this.toastService.success('Водитель проверен', '', {
            closeButton: true,
            timeOut: 3000,
            toastClass: 'toast custom-style',
          });
        }, errors => {
          errors.map(error => {
            this.snackService.showErrorMsg(error);
          });
        }
      );
  }
}
