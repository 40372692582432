import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormatPipe',
  pure: true,
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: Date, format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    if (!value) {
      return '';
    }

    return moment(value).format(format);
  }
}
