import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BidsService} from '../../services/bids.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-document-bid-dialog',
  templateUrl: './document-bid-dialog.component.html',
  styleUrls: ['./document-bid-dialog.component.scss']
})
export class DocumentBidDialogComponent implements OnInit {
  entityForm: FormGroup;
  entityId: string;
  pending = false;

  constructor(public dialogRef: MatDialogRef<DocumentBidDialogComponent>,
              private formBuilder: FormBuilder,
              protected entityService: BidsService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.initForm();

    if (this.data.documentType) {
      this.entityService.documentType = this.data.documentType;
    }

    if (this.data.clientMode) {
      this.entityService.clientMode = this.data.clientMode;
    }
  }

  get bidPrice() {
    return this.entityForm.get('bidPrice');
  }

  initForm() {
    this.entityForm = this.formBuilder.group({
      bidPrice: ['', Validators.required]
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  action() {
    if (!this.entityForm.valid) {
      Object.keys(this.entityForm.controls).forEach(field => {
        const control = this.entityForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      return;
    }

    this.pending = true;

    this.entityService.bid(this.data.id, this.entityForm.controls.bidPrice.value).subscribe(success => {
        this.dialogRef.close({action: true});
        this.toastService.success('Ставка сделана', '', {
          closeButton: true,
          timeOut: 3000,
          toastClass: 'toast custom-style',
        });
      }, errors => {
        errors.map(error => {
          this.pending = false;
          this.snackService.showErrorMsg(error);
        });
      }
    );
  }
}
