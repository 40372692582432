import {finalize} from 'rxjs/operators';
import {TableDataSource} from '../../classes/table-data-source';
import {DocumentEquipment} from '../../models/document-equipment';
import {DocumentEquipmentService} from '../../services/document-equipment.service';
import {ErrorService} from '../../services/error.service';
import {SnackBarService} from '../../services/snack-bar.service';
import {EntityData} from '../../interfaces/entity-data';

export class ProposalEquipmentConfirmedDatasource extends TableDataSource<DocumentEquipment> {

  constructor(protected entityService: DocumentEquipmentService,
              private errorService: ErrorService,
              private snackService: SnackBarService) {
    super();
  }

  loadList(masterEntityId: string, pageIndex: number, pageSize: number) {
    this.setLoading(true);
    this.entityService.getProposalConfirmedList(masterEntityId, pageIndex + 1, pageSize)
        .pipe(
          finalize(() => this.setLoading(false)),
        )
        .subscribe((data: EntityData<DocumentEquipment>) => {
          this.total.next(Number(data.total) || 0);
          this.source.next(data.list || []);
        }, err => this.snackService.showErrorMsg(err));
  }
}
