import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableComponent} from '../../../../../shared/classes/table-component';
import {ProposalConfirmationCommon} from '../../../../../shared/models/proposal-confirmation-common';
import {ColConfig} from '../../../../../shared/interfaces/col-config';
import {GridCommonDatasource} from './grid-common.datasource';
import {MainFormService} from '../../../services/main-form.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ConfigService} from '../../../../../shared/services/config.service';
import {ErrorService} from '../../../../../shared/services/error.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {ToastrService} from 'ngx-toastr';
import {DateAdapter, NativeDateAdapter} from '@angular/material';
import {ProposalConfirmationsService} from '../../../../../shared/services/proposal-confirmations.service';
import {colsConfig} from './config/cols.config';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

export interface GroupBy {
  initial: string;
  isGroupBy: boolean;
}

@Component({
  selector: 'app-proposal-confirmation-grid-common',
  templateUrl: './grid-common.component.html',
  styleUrls: ['./grid-common.component.scss']
})
export class GridCommonComponent extends TableComponent<(ProposalConfirmationCommon | GroupBy), GridCommonDatasource> implements OnInit, OnDestroy {
  displayedColumns = ['name_', 'carrierValue', 'ownerValue'];
  storageKey = 'app-proposal-confirmation-grid-common';
  dataSource: GridCommonDatasource;
  protected colsConf: ColConfig[] = colsConfig;
  entityId: string;

  constructor(private mainFormService: MainFormService,
              private formBuilder: FormBuilder,
              protected storage: LocalStorageService,
              private configService: ConfigService,
              protected entityService: ProposalConfirmationsService,
              private errorService: ErrorService,
              private snackService: SnackBarService,
              private toastService: ToastrService,
              private dateAdapter: DateAdapter<NativeDateAdapter>) {
    super();
    dateAdapter.setLocale('ru-RU');
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabConfirmationForm.controls.commonForm as FormGroup;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabConfirmationForm.controls.commonForm = form;
  }

  ngOnInit() {
    super.ngOnInit();

    this.initForm();

    this.initDataSource();
  }

  ngOnDestroy() {}

  initForm() {
    this.componentForm = this.formBuilder.group({
      items: this.formBuilder.array([
        {},
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        {},
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        {},
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
        this.formBuilder.group({
          code: [''],
          carrierValue: [''],
          ownerValue: ['']
        }),
      ])
    });
  }

  update() {
    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  initDataSource() {
    this.dataSource = new GridCommonDatasource(this.entityService, this.errorService, this.snackService);

    this.dataSource.loadList(this.mainFormService.proposalId, this.componentForm);
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  ownerValueChange($event, entityItem: ProposalConfirmationCommon, index: number) {
    if (entityItem.type === 'boolean') {
      if (!entityItem.carrierValue && $event.checked) {
        this.snackService.showErrorMsg('Потребность отправителя не может превышать предложение перевозчика');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerValue').setValue(false);
      } else {
        if (entityItem.code === 'ownerRequiredDocumentType1' || entityItem.code === 'ownerRequiredDocumentType2') {
          if (entityItem.code === 'ownerRequiredDocumentType1') {
            const item = this.dataSource.source.value.find(e => e instanceof ProposalConfirmationCommon && e.code === 'ownerRequiredDocumentType2') as ProposalConfirmationCommon;

            if (item && item.ownerValue && $event.checked) {
              this.snackService.showErrorMsg('Может быть выбрано или "ТТН" или "ТН и Торг 12"');
              (this.componentForm.get('items') as FormArray).controls[index].get('ownerValue').setValue(false);
            }
          } else if (entityItem.code === 'ownerRequiredDocumentType2') {
            const item = this.dataSource.source.value.find(e => e instanceof ProposalConfirmationCommon && e.code === 'ownerRequiredDocumentType1') as ProposalConfirmationCommon;

            if (item && item.ownerValue && $event.checked) {
              this.snackService.showErrorMsg('Может быть выбрано или "ТТН" или "ТН и Торг 12"');
              (this.componentForm.get('items') as FormArray).controls[index].get('ownerValue').setValue(false);
            }
          }
          entityItem.ownerValue = $event.source.checked;
        }
      }
      entityItem.ownerValue = $event.source.checked;
    } else if (entityItem.type === 'number') {
      const carrierValue = entityItem.carrierValue ? parseFloat(entityItem.carrierValue) : 0;
      const ownerValue = $event.target.value ? parseFloat($event.target.value) : 0;

      if (ownerValue > carrierValue) {
        this.snackService.showErrorMsg('Потребность отправителя не может превышать предложение перевозчика');
        (this.componentForm.get('items') as FormArray).controls[index].get('ownerValue').setValue(0);
      }
    }
  }
}
