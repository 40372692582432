import {Model} from './model';

export class ProposalConfirmationCounterparties extends Model {
  constructor(
    public type?: string,
    public description?: string,
    public name?: string,
    public inn?: string,
    public address?: string,
  ) {
    super('', name);
  }
}
