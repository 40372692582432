import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';
import {EntityData} from '../interfaces/entity-data';
import {map} from 'rxjs/operators';
import {EntityCrudService} from '../classes/entity-crud-service';
import {OrderRoute} from '../models/order-route';
import {OrderRoutesAdapterService} from '../adapters/order-routes-adapter.service';
import * as moment from 'moment';
import {ClientModes} from '../enums/client-modes';

@Injectable({
  providedIn: 'root'
})
export class OrderRoutesService extends EntityCrudService<OrderRoute> {
  baseApiPath = 'owner/order/routes';

  constructor(protected http: HttpClient,
              protected configService: ConfigService,
              protected entityAdapter: OrderRoutesAdapterService,
              protected errorService: ErrorService) {
    super(http, configService, entityAdapter, errorService);
  }

  getListClientMode(orderId, clientMode: ClientModes, page = 1, limit = 100): Observable<EntityData<OrderRoute>> {
    const body = {
      orderId: orderId ? orderId : 0,
      clientMode,
      page,
      limit
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/list`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data)),
        map(response => ({
          total: Number(response.totalCount) || 0,
          list: response.data.length
            ? response.data.map(item => this.entityAdapter.adaptIn(item))
            : []
        } as EntityData<OrderRoute>))
      );
  }

  addRoutes(entityItems: OrderRoute[], docDateEnd: Date, orderId: number) {
    const body = {
      docDateEnd: docDateEnd ? moment(docDateEnd).format('DD.MM.YYYY') : null,
      orderId: orderId,
      routes: this.entityAdapter.adaptOuts(entityItems)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/addChecks`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  addCheck(entityItem: OrderRoute, docDateEnd: Date) {
    const body = {
      docDateEnd: docDateEnd ? moment(docDateEnd).format('DD.MM.YYYY') : null,
      ...this.entityAdapter.adaptOut(entityItem)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/addCheck`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }

  updateCheck(entityItem: OrderRoute, docDateEnd: Date) {
    const body = {
      docDateEnd: docDateEnd ? moment(docDateEnd).format('DD.MM.YYYY') : null,
      ...this.entityAdapter.adaptOut(entityItem)
    };
    return this.http.post(`${this.configService.apiUrl}/${this.baseApiPath}/updateCheck`, body)
      .pipe(
        map(data => this.errorService.checkErrors(data))
      );
  }
}
