import {Model} from './model';

export class Driver extends Model {
  constructor(
    public id?: string,
    public name?: string,
    public lastName?: string,
    public firstName?: string,
    public middleName?: string,
    public phone?: string,
    public passport?: string,
    public passportDate?: Date,
    public birthDate?: Date,
    public licenseNumber?: string,
    public licenseDate?: Date,
    public organization?: string,
    public organizationId?: string,
  ) {
    super(id, name);
  }
}
