import {Injectable} from '@angular/core';
import {EntityAdapter} from '../interfaces/entity-adapter';
import {DocumentOrganization} from '../models/document-organization';

@Injectable({
  providedIn: 'root'
})
export class DocumentOrganizationsAdapterService implements EntityAdapter<DocumentOrganization> {
  adaptIn(item: any): DocumentOrganization {
    return new DocumentOrganization(
      item.id,
      item.name,
      item.masterEntityId,
      item.organizationId,
    );
  }

  adaptOut(item: DocumentOrganization): any {
    return {
      ...item
    };
  }
}
