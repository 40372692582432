import { Component, OnInit } from '@angular/core';
import {EntityEditForm} from '../../../../shared/classes/entity-edit-form';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MainFormService} from '../../services/main-form.service';

@Component({
  selector: 'app-proposal-documents-required',
  templateUrl: './tab-documents-required.component.html',
  styleUrls: ['./tab-documents-required.component.scss']
})
export class TabDocumentsRequiredComponent extends EntityEditForm implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private mainFormService: MainFormService,) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  get componentForm(): FormGroup {
    return this.mainFormService.tabRequiredDocumentsForm;
  }

  set componentForm(form: FormGroup) {
    this.mainFormService.tabRequiredDocumentsForm = form;
  }

  initForm() {
    this.componentForm = this.formBuilder.group({
      isRequiredDocumentType1: [''],
      isRequiredDocumentType2: [''],
      requiredDocumentTypeOther: [''],
      isProvidedDocumentType1: [''],
      isProvidedDocumentType3: [''],
      isProvidedDocumentType4: [''],
      isProvidedDocumentType5: [''],
      providedDocumentTypeOther: [''],
    });
  }

  swapCheckBox($event, source: string, destination: string) {
    if (this.componentForm.controls[source].value) {
      this.componentForm.controls[destination].setValue(false);
    }
  }
}
