import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule, MatPaginatorIntl, DateAdapter} from '@angular/material';
import {Paginator} from './shared/classes/paginator';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ToastrModule} from 'ngx-toastr';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {JWTInterceptor} from './shared/interceptors/jwt-interceptor';
import {MaterialModule} from './modules/material/material.module';
import {TableColFilterModule} from './shared/components/table-col-filter/table-col-filter.module';
import {OrderMainComponent} from './modules/order-form/forms/order-main/order-main.component';
import {TabGeneralComponent as OrderTabGeneralComponent} from './modules/order-form/forms/tab-general/tab-general.component';
import {TabCounterpartiesComponent as OrderTabCounterpartiesComponent} from './modules/order-form/forms/tab-counterparties/tab-counterparties.component';
import {TabParametersComponent as OrderTabParametersComponent} from './modules/order-form/forms/tab-parameters/tab-parameters.component';
import {TabRoutesComponent as OrderTabRoutesComponent} from './modules/order-form/forms/tab-routes/tab-routes.component';
import {TabCarriersComponent} from './modules/order-form/forms/tab-carriers/tab-carriers.component';
import {TabCalcComponent as OrderTabCalcComponent} from './modules/order-form/forms/tab-calc/tab-calc.component';
import {TabBidsComponent as OrderTabBidsComponent} from './modules/order-form/forms/tab-bids/tab-bids.component';
import {TabDocumentsComponent as OrderTabDocumentsComponent} from './modules/order-form/forms/tab-documents/tab-documents.component';
import {TabCheckComponent as OrderTabCheckComponent} from './modules/order-form/forms/tab-check/tab-check.component';
import {TabInsuranceComponent as OrderTabInsuranceComponent} from './modules/order-form/forms/tab-insurance/tab-insurance.component';
import {DirectivesModule} from './shared/directives/directives.module';
import {RoutesFormComponent as OrderRoutesFormComponent} from './modules/order-form/forms/tab-routes/components/routes-form/routes-form.component';
import {RoutesTrunkFormComponent as OrederRoutesTrunkFormComponent} from './modules/order-form/forms/tab-routes/components/routes-trunk-form/routes-trunk-form.component';
import {RoutesComponent as OrderRoutesComponent} from './modules/order-form/forms/tab-routes/components/routes/routes.component';
import {FileUploadComponent} from './shared/components/file-upload/file-upload.component';
import {PipesModule} from './shared/pipes/pipes.module';
import {ProposalMainComponent} from './modules/proposal-form/forms/proposal-main/proposal-main.component';
import {TabGeneralComponent as ProposalTabGeneralComponent} from './modules/proposal-form/forms/tab-general/tab-general.component';
import {TabParametersComponent as ProposalTabParametersComponent} from './modules/proposal-form/forms/tab-parameters/tab-parameters.component';
import {TabRoutesComponent as ProposalTabRoutesComponent} from './modules/proposal-form/forms/tab-routes/tab-routes.component';
import {TabOwnersComponent as ProposalTabOwnersComponent} from './modules/proposal-form/forms/tab-owners/tab-owners.component';
import {TabCalcComponent as ProposalTabCalcComponent} from './modules/proposal-form/forms/tab-calc/tab-calc.component';
import {TabBidsComponent as ProposalTabBidsComponent} from './modules/proposal-form/forms/tab-bids/tab-bids.component';
import {TabCheckComponent as ProposalTabCheckComponent} from './modules/proposal-form/forms/tab-check/tab-check.component';
import {TabInsuranceComponent as ProposalTabInsuranceComponent} from './modules/proposal-form/forms/tab-insurance/tab-insurance.component';
import {RoutesComponent as ProposalRoutesComponent} from './modules/proposal-form/forms/tab-routes/components/routes/routes.component';
import {ComponentsModule} from './shared/components/components.module';
import {RoutesFormComponent as ProposalRoutesFormComponent} from './modules/proposal-form/forms/tab-routes/components/routes-form/routes-form.component';
import {TabDocumentsRequiredComponent as OrderTabDocumentsRequiredComponent} from './modules/order-form/forms/tab-documents-required/tab-documents-required.component';
import {TabDocumentsRequiredComponent as ProposalTabDocumentsRequiredComponent} from './modules/proposal-form/forms/tab-documents-required/tab-documents-required.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import { TabConfirmationComponent } from './modules/proposal-form/forms/tab-confirmation/tab-confirmation.component';
import { GridCommonComponent } from './modules/proposal-form/forms/tab-confirmation/grid-common/grid-common.component';
import { GridEquipmentComponent } from './modules/proposal-form/forms/tab-confirmation/grid-equipment/grid-equipment.component';
import { GridRoutesPointsComponent } from './modules/proposal-form/forms/tab-confirmation/grid-routes-points/grid-routes-points.component';
import { GridRoutesDatesComponent } from './modules/proposal-form/forms/tab-confirmation/grid-routes-dates/grid-routes-dates.component';
import { GridRoutesContactsComponent } from './modules/proposal-form/forms/tab-confirmation/grid-routes-contacts/grid-routes-contacts.component';
import { OwnerRoutePointEditFormComponent } from './modules/proposal-form/forms/tab-confirmation/grid-routes-points/components/owner-route-point-edit-form/owner-route-point-edit-form.component';
import {ErrDialogComponent} from './shared/components/err-dialog/err-dialog.component';
import {CustomDateAdapter} from './shared/classes/custom-date-adapter';
import { TabDocumentsConfirmationComponent } from './modules/proposal-form/forms/tab-documents-confirmation/tab-documents-confirmation.component';
import { GridCounterpartiesComponent } from './modules/proposal-form/forms/tab-confirmation/grid-counterparties/grid-counterparties.component';
import { MatButtonModule } from '@angular/material';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {OrganizationsRegFormComponent} from './modules/admin/organizations/components/organizations-reg-form/organizations-reg-form.component';
import {TabDocumentsOtherComponent} from './modules/order-form/forms/tab-documents-other/tab-documents-other.component';
import {TabOperatorsComponent as TabOrderOperatorsComponent} from './modules/order-form/forms/tab-operators/tab-operators.component';
import {TabOperatorsComponent as TabProposalOperatorsComponent} from './modules/proposal-form/forms/tab-operators/tab-operators.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    OrderMainComponent,
    OrderTabGeneralComponent,
    OrderTabCounterpartiesComponent,
    OrderTabParametersComponent,
    OrderTabRoutesComponent,
    TabCarriersComponent,
    OrderTabCalcComponent,
    OrderTabBidsComponent,
    OrderTabDocumentsComponent,
    OrganizationsRegFormComponent,
    OrderTabCheckComponent,
    OrderTabInsuranceComponent,
    OrderTabDocumentsRequiredComponent,
    OrderRoutesFormComponent,
    OrederRoutesTrunkFormComponent,
    ProposalRoutesFormComponent,
    ProposalTabDocumentsRequiredComponent,
    OrderRoutesComponent,
    FileUploadComponent,
    ProposalMainComponent,
    ProposalTabGeneralComponent,
    ProposalTabParametersComponent,
    ProposalTabRoutesComponent,
    ProposalTabOwnersComponent,
    ProposalTabCalcComponent,
    ProposalTabBidsComponent,
    ProposalTabCheckComponent,
    ProposalTabInsuranceComponent,
    ProposalRoutesComponent,
    TabConfirmationComponent,
    GridCommonComponent,
    GridEquipmentComponent,
    GridRoutesPointsComponent,
    GridRoutesDatesComponent,
    GridRoutesContactsComponent,
    OwnerRoutePointEditFormComponent,
    ErrDialogComponent,
    TabDocumentsConfirmationComponent,
    GridCounterpartiesComponent,
    TabDocumentsOtherComponent,
    TabOrderOperatorsComponent,
    TabProposalOperatorsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxWebstorageModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    NgxMaterialTimepickerModule.setLocale('ru-RU'),
    MaterialModule,
    TableColFilterModule,
    DirectivesModule,
    PipesModule,
    ComponentsModule,
    MatButtonModule,
    MatFileUploadModule,
  ],
  providers: [
    {provide: MatPaginatorIntl, useClass: Paginator},
    {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true},
    {provide: DateAdapter, useClass: CustomDateAdapter},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    OrderMainComponent,
    OrderRoutesFormComponent,
    OrederRoutesTrunkFormComponent,
    ProposalMainComponent,
    ProposalRoutesFormComponent,
    OwnerRoutePointEditFormComponent,
    ErrDialogComponent,
    OrganizationsRegFormComponent,
  ]
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('ru-RU');
  }
}
